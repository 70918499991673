import { SQLBoundariesResponse } from "../../../api/request/Lead/Model/Response/SQLBoundariesResponse";

export interface MultiproductProviderI {
  children: React.ReactNode;
}

export interface BasicFormI {
  email?: string;
  birthDate?: string;
  capital?: number;
  phone?: string;
  referenceName?: string;
}

export interface GuaranteeProperties {
  active: boolean;
  capital: number;
}

export interface GuaranteeFormI {
  readonly DEATH: Readonly<{ active: true } & GuaranteeProperties>;
  DISABILITY: GuaranteeProperties;
  TEMPORARY_DISABILITY: GuaranteeProperties;
  SERIOUS_ILLNESS: GuaranteeProperties;
  ACCIDENT_DISABILITY: GuaranteeProperties;
  TRAFFIC_ACCIDENT_DISABILITY: GuaranteeProperties;
  ACCIDENT_DEATH: GuaranteeProperties;
  TRAFFIC_ACCIDENT_DEATH: GuaranteeProperties;
}

export type BasicFormErrorsI = {
  [key in keyof BasicFormI]?: string;
};

export type BasicFieldsModifiedI = {
  [key in keyof BasicFormI]?: boolean;
};

export interface BasicBoundariesI extends Partial<SQLBoundariesResponse> {}

export type EditBasicFormT = <K extends keyof BasicFormI>(
  field: K,
  value: BasicFormI[K],
  callback?: (data: BasicFormI) => Promise<void>
) => void;

export type ToggleGuaranteeActiveT = (key: keyof GuaranteeFormI) => void;

export type UpdateGuaranteeCapitalT = (
  key: keyof GuaranteeFormI,
  capital: number,
  callback?: boolean
) => void;

export type ToggleBasicFieldModifiedT = <K extends keyof BasicFormI>(
  field: K
) => void;

export const basicFormTemplate = {
  email: undefined,
  birthDate: undefined,
  capital: undefined,
  phone: undefined,
  referenceName: undefined,
};

export const guaranteeFormTemplate = {
  DEATH: {
    active: true,
    capital: 0,
  },
  DISABILITY: {
    active: false,
    capital: 0,
  },
  TEMPORARY_DISABILITY: {
    active: false,
    capital: 0,
  },
  SERIOUS_ILLNESS: {
    active: false,
    capital: 0,
  },
  ACCIDENT_DISABILITY: {
    active: false,
    capital: 0,
  },
  TRAFFIC_ACCIDENT_DISABILITY: {
    active: false,
    capital: 0,
  },
  ACCIDENT_DEATH: {
    active: false,
    capital: 0,
  },
  TRAFFIC_ACCIDENT_DEATH: {
    active: false,
    capital: 0,
  },
};

export const basicBoundariesTemplate = {
  min: undefined,
  max: undefined,
  available: false,
};
