import Dashboard from "./Dashboard";
import DashboardMultiproduct from "./multiproduct/DashboardMultiproduct";
import { MultiproductProvider } from "./multiproduct/MultiproductContext";

const DashboardMiddleware = () => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  if (DOMAIN === "sql_es") {
    return (
      <MultiproductProvider>
        <DashboardMultiproduct />
      </MultiproductProvider>
    );
  }
  return <Dashboard />;
};

export default DashboardMiddleware;
