import React from "react";
import InputGetLife from "../components/inputGetLife/InputGetLife";
import Checkbox from "../components/checkbox/Checkbox";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import Pencil from "../../assets/img/Pencil_icon.svg";

import "./registerMain.scss";

export default function RegisterMain(props) {
  const {
    acceptTerms,
    authorizeNumber,
    brokerageName,
    dni,
    email,
    handleAcceptTerms,
    handleAuthorizeNumber,
    handleBrokerageName,
    handleDni,
    handleEmail,
    handleHaveAuthorization,
    handleHaveRcSecure,
    handleLastName,
    handleName,
    handlePersonalPhone,
    handleSubmit,
    handleWorkPhone,
    haveAuthorization,
    haveRcSecure,
    lastName,
    name,
    personalPhone,
    translate,
    workPhone,
  } = props;

  const allStates = [
    acceptTerms,
    authorizeNumber,
    brokerageName,
    dni,
    email,
    haveAuthorization,
    haveRcSecure,
    lastName,
    name,
    personalPhone,
  ];

  const isButtonDisabled = () => {
    let filtered = false;
    for (let i = 0; i <= allStates.length; i++) {
      if (allStates[i] === false || allStates[i] === "") {
        filtered = true;
      }
    }
    return filtered;
  };

  const textAcceptTerms = () => {
    return (
      <div className="form-labelCheckbox">
        <p>
          {translate("register.accept.text1")}
          <a
            href={translate("register.accept.link1")}
            target="_blank"
            rel="noreferrer"
            className="register--anchor"
          >
            <b>{translate("register.accept.text2")}</b>
          </a>
          {translate("register.accept.text3")}
          <a
            href={translate("register.accept.link2")}
            target="_blank"
            rel="noreferrer"
            className="register--anchor"
          >
            <b>{translate("register.accept.text4")}</b>
          </a>
        </p>
      </div>
    );
  };

  return (
    <main className="main">
      <div className="main--container">
        <div className="title">
          <div className="title--container">
            <img
              src={Pencil}
              alt="Símbolo de un lápiz"
              className="img--title"
            />
            <h1 className="title--title">{translate("register.title")}</h1>
          </div>

          <h3 className="title--text">
            {translate("register.subtitle")}
          </h3>
        </div>
        <div className="form">
          <div className="form-input1">
            <InputGetLife
              id={"brokerageName"}
              name={"brokerageName"}
              label={translate("register.label.brokerageName")}
              contentType={"text"}
              handleValues={handleBrokerageName}
              answer={brokerageName !== false ? brokerageName : ""}
              // error={showErrors('brokerageName') === true ? true : false}
            />
          </div>
          <div className="form-input2">
            <InputGetLife
              id={"name"}
              name={"name"}
              label={translate("register.label.name")}
              contentType={"text"}
              handleValues={handleName}
              // error={showErrors('name') === true ? true : false}
              answer={name !== false ? name : ""}
            />
          </div>
          <div className="form-input3">
            <InputGetLife
              id={"lastName"}
              name={"lastName"}
              label={translate("register.label.lastName")}
              contentType={"text"}
              handleValues={handleLastName}
              answer={lastName !== false ? lastName : ""}
              // error={showErrors('lastName') === true ? true : false}
            />
          </div>
          <div className="form-input4">
            <InputGetLife
              id={"phone"}
              name={"phone"}
              label={translate("register.label.phone")}
              contentType={"tel"}
              handleValues={handlePersonalPhone}
              answer={personalPhone !== false ? personalPhone : ""}
              // error={showErrors('phone') === true ? true : false}
            />
          </div>
          <p className="form--text">
            {translate("register.subtitle.phone")}
          </p>
          <div className="form-input5">
            <InputGetLife
              id={"phoneWork"}
              name={"phoneWork"}
              label={translate("register.label.phoneWork")}
              contentType={"tel"}
              handleValues={handleWorkPhone}
              answer={workPhone !== false ? workPhone : ""}
              // error={showErrors('workPhone') === true ? true : false}
            />
          </div>
          <div className="form-input6">
            <InputGetLife
              id={"email"}
              name={"email"}
              label={translate("register.label.email")}
              contentType={"email"}
              handleValues={handleEmail}
              answer={email !== false ? email : ""}
              // error={showErrors('email') === true ? true : false}
            />
          </div>
          <p className="form--text form--text2">
            {translate("register.subtitle.email")}
          </p>
          <div className="form-input7">
            <InputGetLife
              id={"authorizeNumber"}
              name={"authorizeNumber"}
              label={translate("register.label.authorizeNumber")}
              contentType={"text"}
              handleValues={handleAuthorizeNumber}
              answer={authorizeNumber !== false ? authorizeNumber : ""}
              // error={showErrors('authorizeNumber') === true ? true : false}
            />
          </div>
          <div className="form-input8">
            <InputGetLife
              id={"idNumbe"}
              name={"idNumbe"}
              label={translate("register.label.idNumbe")}
              contentType={"text"}
              handleValues={handleDni}
              answer={dni !== false ? dni : ""}
              // error={showErrors('dni') === true ? true : false}
            />
          </div>
          <div className="form--divCheckbox">
            <Checkbox
              id={"insurance"}
              type={"checkbox"}
              label={translate("register.label.insurance")}
              name={"options"}
              handleValues={handleHaveRcSecure}
              answer={haveRcSecure}
            />
            <Checkbox
              id={"authorization"}
              type={"checkbox"}
              label={translate("register.label.authorization")}
              name={"options"}
              handleValues={handleHaveAuthorization}
              answer={haveAuthorization}
            />
            <Checkbox
              id={"termsConditions"}
              type={"checkbox"}
              label={textAcceptTerms()}
              name={"options"}
              handleValues={handleAcceptTerms}
              answer={acceptTerms}
            />
          </div>
          <div className="form--button">
            <ButtonPrimary
              content={translate("register.button")}
              disabled={isButtonDisabled()}
              onClick={handleSubmit}
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
