const getAllValues = (refs) => {
  let values = "";
  refs.current.forEach((element) => {
    values += element.current.value;
  });
  return values;
};

const handleChangePosition = (type, inputRefs, position) => {
  if (type === "next") {
    return inputRefs.current[position + 1]?.current.focus();
  }
  return inputRefs.current[position - 1]?.current.focus();
};

const handleChangeAction = (e, inputRefs, position, setError, setValue) => {
  const value = inputRefs.current[position]?.current.value;
  if (value !== "") {
    e.preventDefault();
    handleChangePosition("next", inputRefs, position);
  }

  if (getAllValues(inputRefs).length === 6) {
    setValue(getAllValues(inputRefs));
    setError(false);
  } else {
    setValue(getAllValues(inputRefs));
    setError(true);
  }
};

const handlePasteAction = (e, inputRefs, setError, setValue) => {
  e.preventDefault();
  const data = e.clipboardData.getData("Text");
  const data_splitted = [...data];
  if (data_splitted.length === 6) {
    inputRefs.current.forEach((element, i) => {
      element.current.value = data_splitted[i];
    });
  }
  const data_joined = data_splitted.join("");
  setValue(data_joined);
  setError(false);
};

const handleKeyDownAction = (e, position, inputRefs) => {
  const length = inputRefs.current[position]?.current.value.length;

  if (e.keyCode === 8 && length === 0) {
    e.preventDefault();
    handleChangePosition("prev", inputRefs, position);
  }
};

const InputOtpComponentHandlers = (inputRefs, setError, setValue) => ({
  handleChange: (e, position) =>
    handleChangeAction(e, inputRefs, position, setError, setValue),
  handlePaste: (e) => handlePasteAction(e, inputRefs, setError, setValue),
  handleKeyDown: (e, position) => handleKeyDownAction(e, position, inputRefs),
});

export default InputOtpComponentHandlers;
