import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import HttpTrackingRepository from "./api/request/Tracking/tracking.service";
import { ContextGetlife } from "./contexts/ContextGetlife";

const DebugHistory = () => {
  const { brokerId, token } = useContext(ContextGetlife);
  const location = useLocation();
  const trackingRepository = new HttpTrackingRepository(token);
  useEffect(() => {
    const eventDetails = [
      {
        targetUrl: location.pathname,
        brokerEmployeeId: brokerId,
        token: token,
      },
    ];

    trackingRepository.add({ eventType: "NAVIGATE_TO", eventDetails });
  }, [location.pathname]);

  return null;
};

export default DebugHistory;
