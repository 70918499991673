// Constants
import { ErrorsLogin } from "../../constants/login-otp-errors.constants";
import constants from "../../constants/login-otp.constants";

const handleClickSubmitAction = async (
  brokerRepository,
  contactValue,
  inputType,
  setError,
  setErrorMessage,
  setRequested
) => {
  try {
    validateContact(contactValue, inputType);
    await brokerRepository.loginOtp(contactValue, inputType);
    setRequested(true);
  } catch (e) {
    setError(true);
    setErrorMessage(constants[inputType].error);
    setErrorMessage(ErrorsLogin[e.message]);
  }
};

const validateContact = (contact, inputType) => {
  let contactFormatted = contact;

  if (inputType === constants.types.TEL) {
    if (contact.startsWith(constants.prefix_1)) {
      contactFormatted = contact.replace(constants.prefix_1, "");
    }
  }

  return constants[`${inputType}_regex`].test(contactFormatted);
};

const handleKeyDownAction = (
  e,
  brokerRepository,
  contactValue,
  inputType,
  setError,
  setErrorMessage,
  setRequested
) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    handleClickSubmitAction(
      brokerRepository,
      contactValue,
      inputType,
      setError,
      setErrorMessage,
      setRequested
    );
  }
};
const LoginOtpRequestViewHandlers = ({
  brokerRepository,
  contactValue,
  inputType,
  setError,
  setErrorMessage,
  setRequested,
}) => ({
  handleClickSubmit: () =>
    handleClickSubmitAction(
      brokerRepository,
      contactValue,
      inputType,
      setError,
      setErrorMessage,
      setRequested
    ),
  handleKeyDown: (e) =>
    handleKeyDownAction(
      e,
      brokerRepository,
      contactValue,
      inputType,
      setError,
      setErrorMessage,
      setRequested
    ),
});

export default LoginOtpRequestViewHandlers;
