import { useContext } from "react";
import CardsBroker from "../../CardsBroker/CardsBroker";
import "../dashboardMain.scss";
import PricingSummary from "./PricingSummary";
import { I18nContext } from "../../../contexts/i18n.context";
import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";
import BasicCalculator from "./BasicCalculator";
import ComplementaryGuarantees from "./ComplementaryGuarantees";
import { MultiproductContext } from "./MultiproductContext";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";

const DashboardMultiproduct = () => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { pricing, loading, resetForm } = useContext(MultiproductContext);

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 z-[100] backdrop-blur-[2px] bg-[#0000004D] w-full h-full flex items-center justify-center">
          <Spinner width={64} height={64} />
        </div>
      )}
      <div className="newDashboard-grid">
        <div className="newDashboard-calculator gap-[24px]">
          <p className="BodyL font-bold flex flex-row items-center gap-[16px] text-[#424242]">
            <GlobalIcon iconName="HeartIcon" size="XS" color="currentColor" />
            <span className="mr-auto">
              {translate("dashboard.calculator.mainTitle")}
            </span>
            <ButtonDS
              label={translate("dashboard.calculator.restart")}
              buttonType="secondary"
              leftIcon="RotateIcon"
              size="32"
              onClick={resetForm}
            />
          </p>
          <BasicCalculator />
          {pricing && <ComplementaryGuarantees />}
          {/* {pricing && <AdditionalAccidentGuarantees />} */}
        </div>
        <CardsBroker
          share={pricing ? false : true}
          clientDoubts={pricing ? false : true}
          brokerDoubts={false}
          className={"h-fit sticky top-[24px]"}
        >
          {pricing && <PricingSummary />}
        </CardsBroker>
      </div>
    </>
  );
};

export const Accordion = ({
  children,
  open,
  height,
}: {
  children: React.ReactNode;
  open: boolean;
  height: number;
}) => {
  return (
    <div
      // className="overflow-hidden transition-all"
      style={{
        // maxHeight: open ? height : 0,
        display: open ? "block" : "none",
      }}
    >
      {children}
    </div>
  );
};

export default DashboardMultiproduct;
