import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Modal.styles.scss";
import { appendDOM, removeDOM } from "../../../../utils/manipulateDOM";

interface ModalProps {
  className?: string;
  classNameBody?: string;
  classNameContent?: string;
  classNameFooter?: string;
  classNameHeader?: string;
  closeIcon?: boolean;
  ModalActions?: JSX.Element | string;
  ModalBody: JSX.Element | string;
  ModalTitle?: JSX.Element | string;
  setShowModal: (value: boolean) => void;
  showModal: boolean | undefined;
}

const modalRoot: HTMLElement | null = document.getElementById("root");
const domNode: Element = document.createElement("div");

const Modal: React.FC<ModalProps> = ({
  className = "",
  classNameBody = "",
  classNameContent = "",
  classNameFooter = "",
  classNameHeader = "",
  closeIcon = true,
  ModalActions,
  ModalBody,
  ModalTitle,
  setShowModal,
  showModal
}): JSX.Element => {
  React.useEffect(() => {
    appendDOM(modalRoot, domNode);
    return () => {
      if (modalRoot && modalRoot?.children.length < 1) {
        removeDOM(modalRoot, domNode);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={showModal}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className={`modal ${className}`}>
        <div className={`modal-content ${classNameContent}`}>
          <div className={`modal-header ${classNameHeader}`}>
            {ModalTitle}
            {closeIcon && (
              <div className="modal-close">
                <CancelIcon
                  style={{ fontSize: "xx-large" }}
                  onClick={() => setShowModal(false)}
                  className="closeIcon"
                />
              </div>
            )}
          </div>
          <div className={`modal-body ${classNameBody}`}>{ModalBody}</div>
          {ModalActions && (
            <div className={`modal-footer ${classNameFooter}`}>
              {ModalActions}
            </div>
          )}
        </div>
      </div>
    </CSSTransition>,
    domNode
  );
};

export default Modal;
