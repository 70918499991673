import { useState } from 'react';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import '../css/_paymentMethod.scss';
import { ButtonDS, GlobalIcon } from 'get-life-storybook-ts';

const PaymentMethod = (props) => {
  const { handlePaymentCard, handlePaymentSepa, billingSelect, translate } = props;
  const [openCard, setOpenCard] = useState(false);
  const [openSepa, setOpenSepa] = useState(false);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const handleOpenCard = () => {
    setOpenCard(!openCard);
  };

  const handleOpenSepa = () => {
    setOpenSepa(!openSepa);
  };

  return (
    <div className="paymentMethod bg-[#F5F7FA] py-8 px-6 rounded-xl flex flex-col gap-6">
      <div className="flex gap-4">
        <GlobalIcon
          iconName="ReportMoneyIcon"
          size="XS"
          color="var(--primary-color)"
        />
        <span className="BodyL font-bold text-[#424242]">
          {translate("payment.paymentMethod.title")}
        </span>
      </div>
      <div className="paymentMethod--section px-6 py-4 gap-6">
        <div className="paymentMethod--sectionSelect">
          <div>
            <p className="paymentMethod--sectionSelect__title">
              {translate("payment.paymentMethod.card")}
            </p>
            <p
              className="BodyS font-bold text-[#8E8E8E] underline pointer"
              onClick={handleOpenCard}
            >
              {!openCard ? translate("payment.paymentMethod.moreInfo") : translate("payment.paymentMethod.minusInfo")}
            </p>
          </div>
          <ButtonDS 
            label={translate("payment.paymentMethod.buttonSelect")}
            onClick={() => handlePaymentCard()}
            disabled={billingSelect !== '' ? false : true}
            rightIcon='ArrowRightIcon'
            size="32"
          />
        </div>
        {openCard && (
          <div>
            <p className="text">{translate("payment.paymentMethod.moreInfo.text1")}</p>
            <p className="text bold">{translate("payment.paymentMethod.moreInfo.text2")}</p>
          </div>
        )}
      </div>
      <div className="paymentMethod--section px-6 py-4 gap-6">
        <div className="paymentMethod--sectionSelect">
          <div>
            <p className="paymentMethod--sectionSelect__title">
              {translate("payment.paymentMethod.sepa")}
            </p>
            <p
              className="BodyS font-bold text-[#8E8E8E] underline pointer"
              onClick={handleOpenSepa}
            >
              {!openSepa ? translate("payment.paymentMethod.moreInfo") : translate("payment.paymentMethod.minusInfo")}
            </p>
          </div>
          <ButtonDS 
            label={translate("payment.paymentMethod.buttonSelect")}
            onClick={() => handlePaymentSepa()}
            disabled={billingSelect !== '' ? false : true}
            rightIcon='ArrowRightIcon'
            size="32"
          />
        </div>
        {openSepa && (
          <div>
            <p className="text">
              {translate("payment.paymentMethod.moreInfoSepa.text1")}
            </p>
            <p className="text bold1">
              {translate("payment.paymentMethod.moreInfoSepa.text2")}{' '}
            </p>
            <p className="text bold2">{translate("payment.paymentMethod.moreInfoSepa.text3")}</p>
            <p className="text bold">
              {translate("payment.paymentMethod.moreInfoSepa.text4")}
            </p>
          </div>
        )}
      </div>

    </div>
  );
};

export default PaymentMethod;
