import React from "react";
import "./Button.styles.scss";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: "primary" | "secondary" | "text";
}

const Button = ({
  buttonType = "primary",
  children,
  className,
  disabled,
  id,
  onClick,
  type,
}: ButtonProps) => {
  const disabledStyle =
    disabled === true
      ? `button-disabled button-${buttonType}-disabled`
      : `button-${buttonType}`;

  return (
    <>
      <button
        className={`button ${disabledStyle} ${className}`}
        type={type}
        disabled={disabled ? disabled : false}
        onClick={onClick}
        id={id}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
