import { post } from "../../core/HTTPService.service";
import IssueRequest from "./Model/Request/IssueRequest.model";

export default class HttpIssueRepository {
  private readonly API_URL_Ticket:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/issue`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  createIssue = async (data: IssueRequest): Promise<void> => {
    let dataResponse;
    try {
      const response = await post(`${this.API_URL_Ticket}/create`, data, this.header);
      dataResponse = response;
    } catch (e) {
      throw e;
    }
    return dataResponse;
  };
}
