import { ROLESTABLESELECT } from "../../utils/roles";
import { I18nContext } from "../../contexts/i18n.context";
import { useContext } from "react";
import { ButtonDS, InputDS, ModalDS, SelectDS } from "get-life-storybook-ts";

const EditEmployee = ({
  values = {},
  setValue,
  showModal,
  setShowModal,
  handleSubmit,
  brokerUlidActive,
  formError,
}) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <ModalDS
      open={showModal}
      onClose={() => setShowModal(false)}
      icon="PencilIcon"
      title={translate("editEmployee.modal.title")}
      content={
        <>
          <div className="flex flex-col gap-6">
            <div className="employees-input">
              <SelectDS
                label={translate("editEmployee.modal.rol")}
                options={ROLESTABLESELECT(translate)}
                value={values["rol"]}
                onChange={(newValue) =>
                  setValue({ ...values, rol: newValue.target.value })
                }
                placeholder={translate("editEmployee.modal.rol")}
              />
            </div>
            <div className="employees-input gap-6 grid grid-cols-2">
              <InputDS
                label={translate("editEmployee.modal.name")}
                value={values["name"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, name: newValue.target.value })
                }
                className="w-full"
              />
              <InputDS
                label={translate("editEmployee.modal.lastName")}
                value={values["lastname"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, lastname: newValue.target.value })
                }
                className="w-full"
              />
            </div>
            <div className="employees-input">
              <InputDS
                label={translate("editEmployee.modal.email")}
                value={values["email"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, email: newValue.target.value })
                }
                error={formError.email}
                className="w-full"
                icon="MailIcon"
                helper={translate("editEmployee.modal.helper.email")}
              />
            </div>
            <div className="employees-input">
              <InputDS
                label={translate("editEmployee.modal.phone")}
                value={values["phone"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, phone: newValue.target.value })
                }
                error={formError.phone}
                className="w-full"
                icon="DeviceMobile"
                helper={translate("editEmployee.modal.helper.phone")}
              />
            </div>
            <div className="employees-input">
              <InputDS
                label={translate("editEmployee.modal.contactEmail")}
                value={values["contactEmail"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, contactEmail: newValue.target.value })
                }
                error={formError.contactEmail}
                className="w-full"
                icon="MailIcon"
                helper={translate("editEmployee.modal.helper.contactEmail")}
              />
            </div>
            <div className="employees-input">
              <InputDS
                label={translate("editEmployee.modal.contactPhone")}
                value={values["contactPhone"] || ""}
                onChange={(newValue) =>
                  setValue({ ...values, contactPhone: newValue.target.value })
                }
                error={formError.contactPhone}
                className="w-full"
                icon="DeviceMobile"
                helper={translate("editEmployee.modal.helper.contactPhone")}
              />
            </div>
          </div>
        </>
      }
      buttons={
        <>
          <ButtonDS
            onClick={() => setShowModal(false)}
            label={translate("editEmployee.modal.cancel")}
            className="max-w-[320px] ml-auto"
            buttonType="secondary"
          />
          <ButtonDS
            onClick={() => handleSubmit(brokerUlidActive)}
            label={translate("editEmployee.modal.button")}
            className="max-w-[320px] ml-auto"
            rightIcon="ArrowRightIcon"
          />
        </>
      }
    />
  );
};

export default EditEmployee;
