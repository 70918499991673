// @ts-nocheck
import * as React from "react";
import HttpBrokerRepository from "../../../../../api/request/Broker/broker.service";
import { ContextGetlife } from "../../../../../contexts/ContextGetlife";
import { I18nContext } from "../../../../../contexts/i18n.context";
import { ROLESTABLE } from "../../../../../utils/roles";
import jwtDecode from "jwt-decode";
import REGEX from "../../../../../utils/regExp";

interface WelcomeEmployeeFormI {
  role: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  contactPhone: string;
  contactEmail: string;
}

export type formErrorsI = {
  [key in keyof WelcomeEmployeeFormI]?: string;
};

const defaultForm = {
  role: "",
  name: "",
  lastname: "",
  email: "",
  phone: "",
  contactPhone: "",
  contactEmail: "",
};

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE!;

const EmployeeFormController = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { brokerId, token, setStatusEmployee, statusEmployee } =
    React.useContext(ContextGetlife);

  const [form, setForm] = React.useState<WelcomeEmployeeFormI>(
    structuredClone(defaultForm)
  );
  const [formFieldsModified, setFormFieldsModified] =
    React.useState<WelcomeEmployeeFormI>({});
  const [formError, setFormError] = React.useState<formErrorsI>({});

  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [registerCompleted, setRegisterCompleted] =
    React.useState<boolean>(false);

  const roleOptions = Object.keys(ROLESTABLE).map((k) => ({
    label: translate((ROLESTABLE as { [key: string]: string })[k]),
    value: k,
  }));

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const updateForm = (field: keyof WelcomeEmployeeFormI, value: string) => {
    setForm({ ...form, [field]: value });
  };

  const toggleFormModified: ToggleFormModifiedT = (field) => {
    if (!formFieldsModified[field]) {
      setFormFieldsModified({ ...formFieldsModified, [field]: true });
    }
  };

  const validateForm = (forceValidation = false) => {
    const _error: any = {};
    const { phone, contactPhone, contactEmail } = form;

    if (formFieldsModified["contactEmail"] || forceValidation) {
      if (contactEmail) {
        const isValidEmail = contactEmail.match(REGEX["EMAIL"]);
        if (!isValidEmail) {
          _error["contactEmail"] = translate(
            "dashboard.multiproduct.validate.wrongEmail"
          );
        }
      }
    }

    if (formFieldsModified["phone"] || forceValidation) {
      if (phone) {
        let isValidPhone = true;
        if (DOMAIN === "fr") {
          isValidPhone = phone.match(REGEX["PHONEFR"]);
        } else if (DOMAIN === "it") {
          isValidPhone = phone.match(REGEX["PHONEIT"]);
        } else {
          isValidPhone = phone.match(REGEX["PHONE"]);
        }
        if (!isValidPhone) {
          _error["phone"] = translate(
            "dashboard.multiproduct.validate.wrongPhone"
          );
        }
      }
    }

    if (formFieldsModified["contactPhone"] || forceValidation) {
      if (contactPhone) {
        let isValidPhone = true;
        if (DOMAIN === "fr") {
          isValidPhone = contactPhone.match(REGEX["PHONEFR"]);
        } else if (DOMAIN === "it") {
          isValidPhone = contactPhone.match(REGEX["PHONEIT"]);
        } else {
          isValidPhone = contactPhone.match(REGEX["PHONE"]);
        }
        if (!isValidPhone) {
          _error["contactPhone"] = translate(
            "dashboard.multiproduct.validate.wrongPhone"
          );
        }
      }
    }

    setFormError(_error);
    return _error;
  };

  React.useEffect(() => {
    validateForm();
  }, [form, formFieldsModified]);

  React.useEffect(() => {
    if (open) {
      setFormFieldsModified({});
      setFormError({});
      setLoading(false);
      setRegisterCompleted(false);
      (async () => {
        const data = await brokerRepository.getEmployeePreRegisterInfo(
          brokerId
        );
        const user = jwtDecode(token);
        const role = user.roles[0] || "";
        const _defaultForm = { ...defaultForm, ...data, role };
        setForm(_defaultForm);
      })();
    }
  }, [open]);

  React.useEffect(() => {
    if (statusEmployee === "guested") {
      setOpen(true);
    }
  }, [statusEmployee]);

  const fetch = async () => {
    const errors = validateForm(true);
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) return;
    setLoading(true);
    try {
      await brokerRepository.completeRegistration({ ...form });
      const _broker = await brokerRepository.getBroker(token);
      setStatusEmployee(_broker.brokerEmployeeStatus);
      setRegisterCompleted(true);
      setLoading(false);
    } catch (e) {
      const error = JSON.stringify(e);
      if (error.includes("PHONE")) {
        setFormError({
          phone: translate("errors.employees.employeeBrokerPhoneAlreadyExist"),
        });
      }
      setLoading(false);
    }
  };

  return {
    open,
    setOpen,
    roleOptions,
    form,
    updateForm,
    toggleFormModified,
    formError,
    translate,
    fetch,
    loading,
    registerCompleted,
  };
};

export default EmployeeFormController;
