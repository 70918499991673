import { ReactNode } from "react";
import Label from "../../Label/Label.component";
import "./RadioInput.styles.scss";

export interface RadioInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  classNameLabel?: string;
  label?: string | ReactNode;
}

const RadioInput: React.FC<RadioInputProps> = ({
  checked,
  className,
  classNameLabel,
  disabled,
  id,
  label,
  name,
  onChange,
  value,
}): JSX.Element => {
  return (
    <div
      className={`form--checkboxContainer ${checked ? "checkedContainer" : ""}`}
      onChange={onChange}
    >
      <Label
        label={label}
        htmlFor={id}
        className={`form--labelCheckbox ${
          checked ? "checked" : ""
        } ${classNameLabel}`}
      />
      <input
        id={id}
        className={`form--checkbox ${className}`}
        type="radio"
        value={value}
        disabled={disabled}
        name={name}
        checked={checked}
      />
    </div>
  );
};

export default RadioInput;
