import { useContext, useState } from "react";
import HttpCouponsRepository from "../../../../../api/request/Coupons/Coupons.service";
import { ContextGetlife } from "../../../../../contexts/ContextGetlife";
import useForm from "../../../../../hooks/useForm.hook";
import { CouponFetchI } from "../../../pages/Checkpoint/CheckPoint.controller";
import { GoogleContext } from "../../../../../contexts/GoogleTagManager.context";

export interface CouponModalControllerI {
  couponCode?: string;
  fetchPromo: (value: CouponFetchI) => void;
}

const CouponModalController = ({
  couponCode,
  fetchPromo,
}: CouponModalControllerI) => {
  const { leadId, setLoading, token } = useContext(ContextGetlife);
  const { handleTrackerQuestion } = useContext(GoogleContext);
  const [disabled, setDisabled] = useState<boolean>(!!couponCode);

  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const { values, setValue, handleSubmit, error } = useForm({
    fetch: (values) => {
      fetchPromo({
        code: values.couponCode.target.value,
      });

      handleTrackerQuestion({
        type: "FS",
        event: "appliedCoupon",
        data: {
          promotionalCode: values.couponCode,
          leadId: leadId,
          market: process.env.REACT_APP_DOMAIN_LOCALE
        },
      });
    },

    setLoading,
    requiredFields: [],
    initialValues: { couponCode: couponCode },
  });

  return {
    disabled,
    error,
    handleSubmit,
    setDisabled,
    setValue,
    values,
  };
};

export default CouponModalController;
