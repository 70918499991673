import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import selectGetlifeStyle from "./SelectGetlifeStyle.js";
import numberFormat from "../../../utils/numberFormat";
import "./css/selectGetLife.scss";
import SelectRangeStyle from "./SelectRangeStyle.js";

export default function SelectGetlife(props) {
  const [capitalSelect, setCapitalSelect] = useState(null);

  let capitalRangueMin, capitalRangueMax, capitalRangueStep;

  if (props.capitalLimits !== false && props.capitalLimits !== undefined) {
    capitalRangueMin = props.capitalLimits.capitalRangueMin;

    capitalRangueMax = props.capitalLimits.capitalRangueMax;

    capitalRangueStep = props.capitalLimits.capitalRangueStep;
  }

  useEffect(() => {
    setCapitalSelect(formatCapital());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.capitalLimits]);

  const formatCapital = () => {
    let values = [];
    values.push({
      value: String(capitalRangueMax),

      label: numberFormat(capitalRangueMax, "capital"),
    });
    do {
      capitalRangueMax -= capitalRangueStep;

      values.push({
        value: String(capitalRangueMax),

        label: numberFormat(capitalRangueMax, "capital"),
      });
    } while (capitalRangueMax > capitalRangueMin);

    return values;
  };

  const isMultiple = () => {
    if (
      props.type === "select_multiple" ||
      props.type === "select_search" ||
      props.type === "checkbox" || 
      props.type === "checkbox_optional" || 
      props.type === "checkbox_mandatory"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const nbValues = getValue().length;
    if (nbValues < 2) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        {`${nbValues} seleccionadas`}
      </components.ValueContainer>
    );
  };

  const selectValue = () => {
    if (!props.toMap) {
      return "";
    }
    let newValue = props.toMap.find((o) => o.value === props.default);
    if (typeof props.default === "string") {
      const newDefault = props.default ? props.default.split(",") : [];
      newValue = props.toMap.filter((o) => newDefault.includes(o.value));
    }
    return newValue;
  };

  return (
    <div className="selectGetLife-container">
      {props.label && props.label !== "" && (
        <div style={{ paddingBottom: "8px" }}>
          <label
            style={props.disabled ? { color: "grey" } : {}}
            className="selectGetLife-label"
          >
            {props.label}
          </label>
        </div>
      )}

      {props.type === "range" && (
        <Select
          style={{ width: props.width ? props.width : "" }}
          className="SelectGetlifeRange"
          options={props.toMap}
          isMulti={isMultiple()}
          isClearable={false}
          isSearchable
          isDisabled={props.disabled}
          value={
            props.toMap
              ? props.toMap.find((o) => o.value === props.default)
              : ""
          }
          styles={SelectRangeStyle}
          hideSelectedOptions={false}
          components={props.disabled ? { ValueContainer } : { ValueContainer }}
          onChange={(e) => props.handleValues(e)}
          placeholder={props.placeholder ? props.placeholder : ""}
        />
      )}

      {props.type !== "capital" && props.type !== "range" && (
        <Select
          style={{ width: props.width ? props.width : "" }}
          options={props.toMap}
          isMulti={isMultiple()}
          isClearable={false}
          isSearchable
          isDisabled={props.disabled}
          value={selectValue()}
          styles={selectGetlifeStyle}
          hideSelectedOptions={false}
          components={{ ValueContainer }}
          onChange={(e) => props.handleValues(e)}
          placeholder={props.placeholder ? props.placeholder : ""}
          isOptionDisabled={props.isOptionDisabled}
        />
      )}

      {props.type === "capital" && capitalSelect !== null && (
        <Select
          style={{ width: props.width ? props.width : "" }}
          className="SelectGetlife2"
          isDisabled={props.disabled}
          options={capitalSelect}
          isMulti={isMultiple()}
          isClearable={false}
          isSearchable
          value={capitalSelect.find((o) => o.value === props.default)}
          styles={selectGetlifeStyle}
          hideSelectedOptions={false}
          onChange={(e) => props.handleValues(e.value)}
          components={{ ValueContainer }}
          placeholder={props.placeholder}
          defaultInputValue={props.defaultInputValue && props.defaultInputValue}
        />
      )}
    </div>
  );
}