import { createOptionsRange } from "../Landing/CalculatorModule/createoptionsRangeCapital";

export interface CapitalsI {
  min: number;
  max: number;
  available: boolean;
}

export type PrepareCapitalsSQLProps = {
  capitals: CapitalsI;
};

const prepareCapitalsSQL = ({ capitals }: PrepareCapitalsSQLProps) => {
  const stepper = capitals.max === 50000 ? 5000 : 50000;
  const options = createOptionsRange({
    maxCapital: capitals.max,
    minCapital: capitals.min,
    step: stepper,
  });

  const indexPreSelected =
    capitals.max === 50000
      ? Math.round(options.length / 2) - 2
      : Math.round(options.length / 2) - 1;

  return { options, indexPreSelected };
};

export default prepareCapitalsSQL;
