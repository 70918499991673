import { ReactNode } from "react";
import "./InfoMessage.styles.scss";

export interface InfoMessageProps {
  className?: string;
  classNameText?: string;
  icon?: ReactNode;
  message: string | ReactNode;
  type: "warning" | "error" | "info" | "landing";
}

const InfoMessage = ({
  className,
  classNameText,
  icon,
  message,
  type,
}: InfoMessageProps): JSX.Element => {
  return (
    <div className={`message-container ${className}`}>
      {icon}
      <p className={`message-text ${type} ${classNameText}`}>{message}</p>
    </div>
  );
};

export default InfoMessage;
