function selectRangeStyle() {
  const styles = {
    control: (styles, state) => ({
      ...styles,
      border: "solid 2px #104944",

      borderRadius: "8px",
      height: "46px",
      marginTop: "7px",
      boxShadow: state.menuIsOpen ? "0 0 0 5px #E7E7FE" : null,
      ":hover": {
        border: state.menuIsOpen ? "solid 2px #104944" : "solid 2px #b8b6b0",
      },
      ":active": {
        border: "solid 2px #104944",
        boxShadow: "0 0 0 5px #E7E7FE",
      },
      cursor: "pointer",
    }),

    option: (styles, { isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f2f1ed" : null,
        color: "#2c2302",
        margin: "16px",
        width: "calc(100% - 32px)",
        padding: "10px 18px",
        borderRadius: "8px",
        ":hover": {
          cursor: "pointer",
          backgroundColor: "#c6e4c9",
        },
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: "16px",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "#2c2302",
      ":hover": {
        backgroundColor: "white",
        color: "#ff0000",
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: "white",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      borderRadius: "50%",
      backgroundColor: "#423ef4",
      padding: "2px",
      color: "white",
      marginRight: "8px",
    }),
    placeholder: (styles) => ({
      ...styles,

      color: "black",
      fontWeight: "600",
    }),
  };
  return styles;
}

export default selectRangeStyle();
