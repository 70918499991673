import React from "react";
import Label from "../../Label/Label.component";
import { RadioInputProps } from "../RadioInput/RadioInput.component";
import "../RadioInput/RadioInput.styles.scss";

export interface CheckBoxProps extends RadioInputProps {
  classNameInput?: string;
  classNameLabel?: string;
  checkmark?: boolean;
}

const Checkbox: React.FC<CheckBoxProps> = ({
  id,
  label,
  name,
  checked,
  checkmark,
  className,
  classNameInput,
  classNameLabel,
  value,
  onChange,
}): JSX.Element => {
  return (
    <div className={`form--checkboxContainer ${className}`} onChange={onChange}>
      <Label
        label={label}
        htmlFor={id}
        className={`form--labelCheckbox ${
          checked ? "checked" : ""
        } ${classNameLabel}`}
      />
      <input
        id={id}
        className={`form--checkbox ${classNameInput}`}
        type="checkbox"
        value={value}
        name={name}
        checked={checked}
      />
      {checkmark && <span id={id} className="checkmark"></span>}
    </div>
  );
};
export default Checkbox;
