import "./personalization.scss";
import numberFormat, {
  numberFormatPayment,
} from "../../../../utils/numberFormat";
import { useEffect, useState } from "react";
import ButtonPrimary from "../../../components/buttons/ButtonPrimary";
import Guarantee from "../Guarantee/Guarantee";
import NumberFormat from "react-number-format";
import Card from "../../../Rebranding/molecules/Card/Card.component";
import AlertIcon from "../../../Rebranding/atoms/Icons/AlertIcon.component";
import Typography from "../../../Rebranding/atoms/Typography/Typography.component";
import IconButton from "../../../Rebranding/atoms/Buttons/IconButton/IconButton.component";
import TicketIcon from "../../../Rebranding/atoms/Icons/TicketIcon";
import CrossIcon from "../../../Rebranding/atoms/Icons/CrossIcon";
import { PriceSlider } from "get-life-storybook-ts";

export default function Personalization(props) {
  const [capital, setCapital] = useState(props.capital);
  const [ipa, setIpa] = useState(props.insuranceType);
  const capitalRangueMin = props.boundaries.minCapital;
  const capitalRangueMax = props.boundaries.maxCapital;

  const notShowIpaOptions = process.env.REACT_APP_DOMAIN_LOCALE !== "fr" && process.env.REACT_APP_DOMAIN_LOCALE !== "it" && props.insuranceType !== "eg";

  useEffect(() => {
    setCapital(props.capital);
    setIpa(props.insuranceType);
  }, [props]);

  const handleIpaChange = () => {
    if (ipa === "premium") {
      setIpa("basic");
      handleCustomCapitalChange("basic");
    } else {
      setIpa("premium");
      handleCustomCapitalChange("premium");
    }
  };

  const handleCustomCapitalChange = (newIpa) => {
    if (typeof newIpa === "object") {
      newIpa = ipa;
    }
    let newCapital = parseInt(
      document.getElementById("upsell-input-format").value.replaceAll(".", "")
    );

    if (newCapital <= capitalRangueMin) {
      newCapital = capitalRangueMin;
    }

    if (newCapital >= capitalRangueMax) {
      newCapital = capitalRangueMax;
    }
    setCapital(newCapital);
    props.handleCapital(newCapital, newIpa);
  };

  const handleSliderChange = (newValue) => {
    if (newValue <= capitalRangueMin) {
      newValue = capitalRangueMin;
    }

    if (newValue >= capitalRangueMax) {
      newValue = capitalRangueMax;
    }
    document.getElementById("upsell-input-format").value = parseFloat(newValue);
    setCapital(newValue);
    props.handleCapital(newValue, ipa);
  };

  let boldText = "";
  if (capitalRangueMax && capitalRangueMin) {
    boldText = `${numberFormat(capitalRangueMin, "capital")} - ${numberFormat(
      capitalRangueMax,
      "capital"
    )}`;
  }

  return (
    <div className={"upsell-personalization"}>
      <div className={"upsell-inner-personalization-header"}>
        <p className={"upsell-personalization-subtitle"}>{props.translate("checkpoint.capital.title")}</p>
      </div>
      <div className={"upsell-inner-personalization"}>
        <div className={"upsell-inner-personalization-content"}>
          <div className={"upsell-personalization-first-row"}>
            <PriceSlider
              inital={capital}
              capitalFormat={numberFormatPayment}
              max={capitalRangueMax}
              min={capitalRangueMin}
              onValueChange={(value) => setCapital(value)}
              currency="€"
              step={5000}
              onAfterChange={(e) => {
                handleSliderChange(e);
              }}
            />
          </div>
          <div className={"upsell-personalization-second-row"}>
            <div className={"upsell-personalization-second-row-left"}>
              <div className={"upsell-personalization-custom-capital-title"}>
                {props.translate("checkpoint.capital.label")}
              </div>
              <div
                className={
                  "upsell-personalization-custom-capital-input-container"
                }
              >
                <NumberFormat
                  id={"upsell-input-format"}
                  className={"upsell-personalization-custom-capital-input"}
                  value={capital}
                  suffix={" €"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  label="Capital"
                  onBlur={handleCustomCapitalChange}
                />
                <ButtonPrimary
                  content={props.translate("checkpoint.button.apply")}
                  id={"addButton1"}
                  className={"upsell-personalization-add-button"}
                />
              </div>
              <div className="upsell-personalization-second-row__coupon">
                  <IconButton
                    icon={
                      props.promotion ? (
                        <CrossIcon color="#423EF4" />
                      ) : (
                        <TicketIcon />
                      )
                    }
                    className="upsell-personalization-second-row__coupon--buttonText"
                    label={props.translate(
                      `checkpoint.personalization.coupon.${
                        props.promotion ? "delete" : "apply"
                      }`
                    )}
                    buttonType="text"
                    onClick={() => props.handleClickIcon()}
                  />
              </div>
            </div>
          </div>
        </div>
        <div className="upsell-container-guarantees">
          {
            notShowIpaOptions &&    
            <Guarantee
              ipa={ipa}
              handle={handleIpaChange}
              translate={props.translate}
              leadId={props.leadId}
              premiumAllowed={props.premiumAllowed}
            />
          }
          {(!props.premiumAllowed && props.insuranceType !== "eg")&& (
            <Card
              classNameContainer="upsell-personalization-card"
              image={<AlertIcon color="#423EF4" />}
              title={props.translate("checkpoint.card.titleIPA")}
              variantTitle="bold"
              textBody={props.translate("checkpoint.card.textIPA")}
            />
          )}
          {capitalRangueMax !== undefined && capitalRangueMax !== 500000 && notShowIpaOptions && (
            <Card
              classNameContainer="upsell-personalization-card"
              image={<AlertIcon color="#423EF4" />}
              title={props.translate("checkpoint.card.title")}
              variantTitle="bold"
              textBody={
                <>
                  {props.translate("checkpoint.card.text")}
                  <Typography
                    variant="bold"
                    text={boldText}
                    className="upsell-personalization-card-body"
                  />
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
