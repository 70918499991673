import React from "react";
import numberFormat from "../../../../../utils/numberFormat";
import Button from "../../../atoms/Buttons/ButtonBase/Button.component";
import Typography from "../../../atoms/Typography/Typography.component";
import Card from "../../../molecules/Card/Card.component";
import Modal from "../../Modal/Modal.component";
import FormBankEntity from "../FormBankEntity.component";
import FormLegalPerson from "../FormLegalPerson.component";
import FormPhysicalPerson from "../FormPhysicalPerson.component";
import { FormProps } from "../FromInterface.model";
import FormOtherBeneficiariesController from "./FormOtherBeneficiaries.controller";
import "./FormOtherBeneficiaries.styles.scss";

const FormOtherBeneficiaries: React.FC<FormProps> = ({
  values,
  setValue,
  translate,
  disabled,
}) => {
  const {
    currentCapital,
    error,
    formType,
    handles,
    normalizeShowInformation,
    setShowModal,
    setValueBeneficiaries,
    showModal,
    type,
    valuesBeneficiaries,
  } = FormOtherBeneficiariesController({ values, setValue, translate });

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const Forms: any = {
    legalPerson: (
      <FormLegalPerson
        translate={translate}
        values={valuesBeneficiaries}
        setValue={setValueBeneficiaries}
        error={error}
      />
    ),
    physicalPerson: (
      <div className="otherBeneficiaries--form">
        <FormPhysicalPerson
          translate={translate}
          values={valuesBeneficiaries}
          setValue={setValueBeneficiaries}
          handleCheck={handles.handleCheck}
          error={error}
        />
      </div>
    ),
    bankingEntity: (
      <FormBankEntity
        translate={translate}
        values={valuesBeneficiaries}
        setValue={setValueBeneficiaries}
        error={error}
      />
    ),
  };

  const buttonDisabled =
    disabled || valuesBeneficiaries.length >= 20 || !currentCapital;

  return (
    <div className="beneficiaries--other">
      <Typography
        variant="bold"
        text={translate("postStack.title.beneficiaries")}
      />
      <div className="beneficiaries--buttons-other">
        <Button
          disabled={buttonDisabled}
          buttonType="secondary"
          onClick={() => handles.handleOpen("physicalPerson")}
        >
          {translate("postStack.buttons.physicalPerson")}
        </Button>
        <Button
          disabled={buttonDisabled}
          buttonType="secondary"
          onClick={() => handles.handleOpen("legalPerson")}
        >
          {translate("postStack.buttons.legalPerson")}
        </Button>
        {
          (DOMAIN !== "fr") && (
            <Button
              disabled={buttonDisabled}
              buttonType="secondary"
              onClick={() => handles.handleOpen("bankingEntity")}
            >
              {translate("postStack.buttons.bankEntity")}
            </Button>
          )
        }

      </div>
      <div className="beneficiaries--text-other">
        <Typography
          variant="italic"
          text={translate("postStack.subtitle.capital").replace(
            "$",
            `${numberFormat(currentCapital, "capital")}/${numberFormat(
              values.capital,
              "capital"
            )}`
          )}
        />
        <Typography
          variant="paragraph"
          text={translate("postStack.text.capital")}
        />
      </div>

      <div className="beneficiaries--cards">
        {!!values.beneficiariesList.length &&
          normalizeShowInformation().map(
            ({ idNumber, capital, name, title, type, id }) => (
              <Card
                title={title}
                variantTitle="h3"
                classNameContainer="beneficiaries--card"
                handleClose={() => !disabled && handles.handleDelete(id)}
                classNameTitle={`beneficiaries--card-title ${type}`}
                classNameBody="beneficiaries--card-body"
              >
                <div>
                  <Typography variant="paragraph" text={name} />
                  <Typography variant="paragraph" text={idNumber} />
                </div>
                <div className="beneficiaries--card-actions">
                  <Typography
                    variant="paragraph"
                    text={translate("postStack.subtitle.capital").replace(
                      "$",
                      `${numberFormat(capital, "capital")}`
                    )}
                  />
                  <Button
                    disabled={disabled}
                    buttonType="text"
                    onClick={() => handles.handleEdit(id)}
                  >
                    {translate("postStack.buttons.change")}
                  </Button>
                </div>
              </Card>
            )
          )}
      </div>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        closeIcon
        ModalTitle={<h1>{translate(`postStack.title.${type}`)}</h1>}
        ModalBody={Forms[type]}
        ModalActions={
          <div className="beneficiaries--modal-buttons">
            <Button onClick={() => handles.handleSubmit()}>
              {translate(`postStack.buttons.${formType}`)}
            </Button>
            <Button
              buttonType="secondary"
              onClick={() => handles.handleClose()}
            >
              {translate("postStack.buttons.cancel")}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default FormOtherBeneficiaries;
