import React from 'react';
import { Dialog, DialogTitle, DialogContent, Slide } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import numberFormat from '../../../utils/numberFormat';
import './css/upgradeCapitalModal.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpgradeCapitalModal(props) {

    const { upGradedOpen, handleUpgradedClose, capitalUpgrade } = props;

    return (
        <div>
            <Dialog
                fullWidth={false}
                maxWidth={'lg'}
                open={upGradedOpen}
                onClose={handleUpgradedClose}
                TransitionComponent={Transition}
            >
                <DialogTitle
                    disableTypography
                    className={'questions-downgraded-dialog-close'}
                >
                    <IconButton onClick={() => handleUpgradedClose()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    className={'questions-downgraded-dialog-content'}
                >
                    <p>
                        Debido a los datos que nos has facilitado, debemos
                        aumentarte el capital a <br />
                        <span>
                            {numberFormat(
                                capitalUpgrade,
                                'capital'
                            )}
                        </span>
                    </p>
                    <p>Ese es el capital mínimo para poder asegurarte</p>
                </DialogContent>
            </Dialog>
        </div>
    );
}














