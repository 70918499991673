import * as React from "react";
import { IconProps } from "./IconProps.interface";

const InfoIcon: React.FC<IconProps> = ({
  width = "24",
  height = "24",
  color = "#423EF4",
  className,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4853 3.51469C18.2188 1.24823 15.2053 0 12 0C8.79469 0 5.78123 1.24823 3.51469 3.51469C1.24823 5.78123 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM12 3.28125C13.4216 3.28125 14.5781 4.4378 14.5781 5.85938C14.5781 7.28095 13.4216 8.4375 12 8.4375C10.5784 8.4375 9.42188 7.28095 9.42188 5.85938C9.42188 4.4378 10.5784 3.28125 12 3.28125ZM15.2812 19.6875H8.71875V18.2812H10.125V11.25H8.71875V9.84375H13.875V18.2812H15.2812V19.6875Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
