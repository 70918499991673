interface stageI {
  title: string;
  value: string;
}

export const Stages = (translate: (key: string) => string): stageI[] => [
  {
    title: translate("projects.stage.pendingIssued"),
    value:
      "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,telesubscription,upsell_pending,nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending,paymentmethodpending_pending,payment_pending,payment_validation_pending,idcard_pending,legalnote_pending,signature_pending,docs_pending,ops_review,it_review,insurer_review,csv_pending,",
  },
  {
    title: translate("projects.stage.issued"),
    value:
      "complete",
  },
  {
    title: translate("projects.stage.rejectedCanceled"),
    value: "canceled,rejected,claim",
  },
];

export interface StageFilterI {
  label: string;
  icon: string;
  value: string;
  active: boolean;
}

export const StagesFilter: StageFilterI[] = [
  {
    label: "projects.filter.popup.questionnaire",
    icon: "ZoomQuestionIcon",
    value:
      "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,telesubscription",
    active: false,
  },
  {
    label: "projects.filter.popup.priceScreen",
    icon: "FileEuroIcon",
    value: "upsell_pending",
    active: false,
  },
  {
    label: "projects.filter.popup.personalData",
    icon: "SimpleUserIcon",
    value:
      "nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending",
    active: false,
  },
  {
    label: "projects.filter.popup.pendingPaymentMethod",
    icon: "CreditCardOff",
    value:
      "paymentmethodpending_pending,payment_pending,payment_validation_pending",
    active: false,
  },
  {
    label: "projects.stage.documentation",
    icon: "FileXIcon",
    value: "idcard_pending",
    active: false,
  },
  {
    label: "projects.filter.popup.legalNote",
    icon: "FileCheckIcon",
    value: "legalnote_pending",
    active: false,
  },
  {
    label: "projects.filter.popup.pendingSignature",
    icon: "WritingSignIcon",
    value: "signature_pending",
    active: false,
  },
  {
    label: "projects.stage.process",
    icon: "ArrowRightLinesIcon",
    value: "docs_pending,ops_review,it_review,insurer_review,csv_pending",
    active: false,
  },
  {
    label: "projects.filter.popup.activePolicy",
    icon: "ShieldCheckIcon",
    value: "complete",
    active: false,
  },
  {
    label: "projects.filter.popup.cancelled",
    icon: "FileDislikeIcon",
    value: "canceled",
    active: false,
  },
  {
    label: "projects.stage.claim",
    icon: "AlertTriangleIcon",
    value: "claim",
    active: false,
  },
];

export const BiometryFilter: StageFilterI[] = [
  {
    label: "projects.stage.biometryPending",
    icon: "FileAlertIcon",
    value:
      "id_pending",
    active: false,
  },
  {
    label: "projects.stage.biometryError",
    icon: "FileXIcon",
    value: "id_error",
    active: false,
  },
  {
    label: "projects.stage.biometryReview",
    icon: "FileSearchIcon",
    value:
      "id_review",
    active: false,
  },
];