
export const ErrorsLogin = {
    "METHOD_NOT_VALID": "login.error.METHOD_NOT_VALID",
    "TARGET_NOT_VALID": "login.error.TARGET_NOT_VALID",
	"EMPLOYEE_NOT_FOUND": "login.error.EMPLOYEE_NOT_FOUND",
    "UNDEFINED_USER": "login.error.UNDEFINED_USER",
    "INTERNAL_ERROR": "login.error.INTERNAL_ERROR",
    "DELIVERY_ERROR": "login.error.DELIVERY_ERROR",
    "CODE_FORMAT_NOT_VALID": "login.error.CODE_FORMAT_NOT_VALID",
    "CODE_NOT_VALID": "login.error.CODE_NOT_VALID",
    "NO_USER": "login.error.NO_USER",
    "ERROR_GENERATING_TOKEN": "login.error.ERROR_GENERATING_TOKEN"
};
