import React from "react";
import "./welcome.scss";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../components/buttons/ButtonPrimary";

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <main className="welcomeMain">
      <section className="welcomeMain--container">
        <h1 className="welcomeMain--container__title">
          ¡Bienvenido a Life5!
        </h1>
        <p className="welcomeMain--container__text">
          Estamos orgullosos de tenerte a bordo.
        </p>
        <p className="welcomeMain--container__text">
          ¡Vamos a por tu primer cliente!
        </p>
      </section>
      <section className="welcomeMain--container2">
        <div className="welcomeMain--container2__anchor">
          <ButtonPrimary
            content="Cotizar mi primer proyecto"
            onClick={() => navigate("/dashboard/calculator")}
          />
        </div>
      </section>
    </main>
  );
}
