import React from "react";
import {
  DropDown,
  Input,
  TextWithInfoIcon,
  Typography,
  GlobalIcon,
  CheckboxAtom,
  BirthDateInput,
  ButtonDS,
  AnimatedAlert,
  ModalDS,
} from "get-life-storybook-ts";
import CalculatorSQLController, {
  StrapiDataCalculator,
} from "./CalculatorSQLController";
import numberFormat from "../../../../utils/numberFormat";
import DownloadableFiles from "../../../components/DownloadableFiles/DownloadableFiles";

interface OverlayI {
  isMobile?: boolean;
  translate: (key: string, lang?: string) => string;
  lang: string;
}

const OverlayCapital: React.FC<OverlayI> = ({ isMobile, translate, lang }) => {
  return (
    <div className="flex flex-col gap-4">
      <GlobalIcon iconName="CalculatorIcon" size={isMobile ? "M" : "L"} />
      <Typography
        variant="Body"
        text={translate("landing.calculator.infoCapitalBody1", lang)}
      />
      <Typography
        variant="Body"
        text={translate("landing.calculator.infoCapitalBody2", lang)}
      />
    </div>
  );
};

const OverlayData: React.FC<OverlayI> = ({ isMobile, translate, lang }) => {
  return (
    <div className="flex flex-col gap-4">
      <Typography
        variant="Body"
        text={translate("landing.calculator.infoDataBody1", lang)}
      />
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="EmailIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text={translate("landing.calculator.infoDataBody2", lang)}
            color="purple"
          />
          <Typography
            variant="Body"
            text={translate("landing.calculator.infoDataBody3", lang)}
          />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <GlobalIcon iconName="CallIcon" size={isMobile ? "M" : "L"} />

        <div className="flex flex-col gap-1">
          <Typography
            variant="Body"
            className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
            weight="700"
            text={translate("landing.calculator.infoDataBody4", lang)}
            color="purple"
          />
          <Typography
            variant="Body"
            text={translate("landing.calculator.infoDataBody5", lang)}
          />
        </div>
      </div>
    </div>
  );
};

const CalculatorSQL: React.FC<StrapiDataCalculator> = ({
  data,
  brokerId,
  translate,
  lang,
}) => {
  const {
    actualStep,
    capitalOptions,
    downloadableFiles,
    display,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    handleFocus,
    isMobile,
    loading,
    setActualStep,
    setDisplay,
    setFormDataFirst,
    setFormDataSecond,
    leadData,
    alertRef,
    downloadables,
    downloadablesOpen,
    setDownloadablesOpen,
  } = CalculatorSQLController({ data, brokerId, translate, lang });

  const scrollToContact = () => {
    const element = document.getElementById("landing-contact");
    window.scrollTo({
      behavior: "smooth",
      top:
        element?.getBoundingClientRect().top! -
        document.body.getBoundingClientRect().top -
        80,
    });
  };

  const checkBoxQuery = (
    <>
      <div className="flex flex-col">
        <Typography
          variant="BodyMedium"
          text={translate("landing.calculator.checkbox1", lang)}
          color="ligth-gray"
        />
        <Typography
          variant="LinkMedium"
          text={translate("landing.calculator.checkbox1TextLink", lang)}
          color="ligth-gray"
          target="_blank"
          href={translate("landing.calculator.checkbox1Link", lang)}
        />
      </div>
    </>
  );

  const checkBoxQuery2 = (
    <>
      <div className="flex flex-col">
        <Typography
          variant="BodyMedium"
          text={translate("landing.calculator.checkbox2", lang)}
          color="ligth-gray"
        />
        <Typography
          variant="LinkMedium"
          text={translate("landing.calculator.checkbox2TextLink", lang)}
          color="ligth-gray"
          target="_blank"
          href={translate("landing.calculator.checkbox2Link", lang)}
        />
      </div>
    </>
  );

  return (
    <>
      <div
        className={`LandingCalculator-calculator`}
        style={{
          backgroundColor:
            (data && data[0] && data[0].backgroundColor) ??
            "var(--theme-quaternary)",
        }}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <div className="flex flex-row gap-4 w-full">
            <Typography
              variant="BodySmall"
              text={translate("landing.calculator.step1", lang)}
              style={{
                color:
                  actualStep === 1 ? "var(--theme-secondary)" : "ligth-gray",
              }}
              onClick={() => {
                if (actualStep === 2) {
                  setActualStep(1);
                }
              }}
              className={`${
                actualStep === 2 ? "cursor-pointer" : "cursor-not-allowed"
              }`}
            />
            <Typography variant="BodySmall" text=">" color="ligth-gray" />
            <Typography
              variant="BodySmall"
              text={translate("landing.calculator.step2", lang)}
              color={actualStep === 2 ? "purple" : "ligth-gray"}
              className={`${
                actualStep === 1 ? "cursor-pointer" : "cursor-not-allowed"
              }`}
              onClick={() => {
                if (actualStep === 1) {
                  setActualStep(2);
                }
              }}
            />
            <Typography variant="BodySmall" text=">" color="ligth-gray" />
            <Typography
              variant="BodySmall"
              text={translate("landing.calculator.step3", lang)}
              color="ligth-gray"
            />
          </div>
          {actualStep === 1 && (
            <>
              {!display && (
                <>
                  <BirthDateInput
                    label={translate("landing.calculator.birthdate", lang)}
                    initialValue={formDataFirst.birthDate}
                    handleFocus={handleFocus}
                    setter={(birthDate: string) =>
                      setFormDataFirst({
                        ...formDataFirst,
                        birthDate,
                      })
                    }
                    birthDateError={formError?.birthDate}
                    placeholderDay={translate(
                      "landing.calculator.placeholderDay",
                      lang
                    )}
                    placeholderMonth={translate(
                      "landing.calculator.placeholderMonth",
                      lang
                    )}
                    placeholderYear={translate(
                      "landing.calculator.placeholderYear",
                      lang
                    )}
                  />
                </>
              )}

              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text={translate("landing.calculator.capital", lang)}
                  color="gray-text"
                />
                <div className="flex flex-col gap-4">
                  <DropDown
                    isSimple
                    bgWhite
                    initialValue={(formDataFirst.capital || "") as string}
                    handleDisplay={(displayItem: boolean) =>
                      setDisplay(displayItem)
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataFirst,
                        value: e,
                        field: "capital",
                        formData: formDataFirst,
                      })
                    }
                    options={capitalOptions}
                    disabled={capitalOptions.length === 0}
                    className="w-full"
                    placeholder={translate(
                      "landing.calculator.placeholderCapital",
                      lang
                    )}
                    initialError={formError?.capital}
                  />
                </div>
                {capitalOptions.length > 0 && (
                  <TextWithInfoIcon
                    content={translate(
                      "landing.calculator.textInfoCapital",
                      lang
                    )}
                    overlayPrice={
                      <OverlayCapital
                        isMobile={isMobile}
                        translate={translate}
                        lang={lang}
                      />
                    }
                    overlayTitle={
                      <Typography
                        variant="Body"
                        weight="700"
                        text={translate(
                          "landing.calculator.titleInfoCapital",
                          lang
                        )}
                        className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
                      />
                    }
                    overlayWidth={false}
                    infoIcon
                    position="left"
                    color="ligth-gray"
                    weight="400"
                    className="mt-2"
                  />
                )}
              </div>
              <div className="flex flex-col w-full">
                <ButtonDS
                  label={translate("landing.calculator.buttonContinue", lang)}
                  onClick={() => handleButton()}
                  disabled={loading}
                  rightIcon="ArrowRightIcon"
                  className="w-full"
                />
              </div>
            </>
          )}

          {actualStep === 2 && (
            <>
              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text={translate("landing.calculator.email", lang)}
                  color="gray-text"
                />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.email}
                    inputMode="email"
                    autoFocus
                    onBlur={() =>
                      handleBlur({ field: "email", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "email",
                        formData: formDataSecond,
                      })
                    }
                    placeholder={translate(
                      "landing.calculator.emailPlaceholder",
                      lang
                    )}
                    initialError={formError?.email}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <Typography
                  variant="Body"
                  text={translate("landing.calculator.phone", lang)}
                  color="gray-text"
                />
                <div className="flex flex-row gap-4">
                  <Input
                    initialValue={formDataSecond.phone}
                    inputMode="number"
                    pattern="\d*"
                    onBlur={() =>
                      handleBlur({ field: "phone", formData: formDataSecond })
                    }
                    onValueChange={(e: any) =>
                      handleChange({
                        setValue: setFormDataSecond,
                        value: e,
                        field: "phone",
                        formData: formDataSecond,
                      })
                    }
                    placeholder={translate(
                      "landing.calculator.phonePlaceholder",
                      lang
                    )}
                    initialError={formError?.phone}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full">
                <TextWithInfoIcon
                  content={translate("landing.calculator.textInfoData", lang)}
                  overlayPrice={
                    <OverlayData
                      isMobile={isMobile}
                      translate={translate}
                      lang={lang}
                    />
                  }
                  overlayTitle={
                    <Typography
                      variant="Body"
                      weight="700"
                      text={translate("landing.calculator.titleInfoData", lang)}
                      className="text-[18px] md:text-[22px] leading-[120%] tracking-[-0.02em]"
                    />
                  }
                  overlayWidth={false}
                  infoIcon
                  position="left"
                  color="ligth-gray"
                  weight="400"
                  className="mt-2"
                />
              </div>
              <div className="flex flex-col w-full">
                <CheckboxAtom
                  label={checkBoxQuery}
                  onValueChange={(checkedConditions: boolean) =>
                    handleChange({
                      setValue: setFormDataSecond,
                      value: checkedConditions,
                      field: "checkedConditions",
                      formData: formDataSecond,
                    })
                  }
                  isLegal
                  errorCheck={formError?.checkedConditions}
                  className="!p-0"
                />
              </div>
              <div className="flex flex-col w-full">
                <CheckboxAtom
                  label={checkBoxQuery2}
                  onValueChange={(checkedConditions2: boolean) =>
                    handleChange({
                      setValue: setFormDataSecond,
                      value: checkedConditions2,
                      field: "checkedConditions2",
                      formData: formDataSecond,
                    })
                  }
                  isLegal
                  errorCheck={formError?.checkedConditions2}
                  className="!p-0"
                />
              </div>
              <div className="flex flex-col w-full">
                <ButtonDS
                  label={translate("landing.calculator.buttonContinue", lang)}
                  onClick={() => handleButton()}
                  disabled={loading}
                  rightIcon="ArrowRightIcon"
                  className="w-full"
                />
              </div>
            </>
          )}

          {actualStep === 3 && (
            <>
              <div className="w-full flex flex-col gap-[16px]">
                <p className="H1 font-medium text-[var(--theme-primary)]">
                  {leadData?.monthlyPromotedPrice}
                  <span className="BodyM align-top">
                    {translate("landing.calculator.monthlyCurrency", lang)}
                  </span>
                </p>
                <div className="flex flex-row gap-[8px] BodyM">
                  <GlobalIcon
                    iconName="CashIcon"
                    size="XS"
                    color="var(--theme-primary)"
                  />
                  <span className="flex-1 text-[#424242] font-medium">
                    {translate("landing.calculator.assuredCapital", lang)}
                  </span>
                  <span className="text-[var(--theme-primary)] font-bold">
                    {numberFormat(leadData?.capital, "capital")}
                  </span>
                </div>
                <div className="flex flex-col gap-[8px]">
                  <span className="BodyM text-[#555555]">
                    {translate("landing.calculator.assuredGuarantees", lang)}
                  </span>
                  <div className="flex flex-row gap-[8px] BodyM">
                    <GlobalIcon
                      iconName="Cross2Icon"
                      size="XS"
                      color="var(--theme-primary)"
                    />
                    <span className="flex-1 text-[#424242] font-medium">
                      {translate("landing.calculator.death", lang)}
                    </span>
                  </div>
                  {leadData?.insuranceType === "premium" && (
                    <div className="flex flex-row gap-[8px] BodyM">
                      <GlobalIcon
                        iconName="DisabledIcon"
                        size="XS"
                        color="var(--theme-primary)"
                      />
                      <span className="flex-1 text-[#424242] font-medium">
                        {translate("landing.calculator.ipa", lang)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex flex-row gap-[8px] BodyS text-[#8E8E8E]">
                  <GlobalIcon
                    iconName="InfoIcon"
                    size="XXS"
                    color="currentColor"
                  />
                  <span className="flex-1">
                    Tu asesor se pondrá en contacto contigo próximamente para
                    guiarte en los próximos pasos. Si lo prefieres, también
                    puedes comunicarte con él directamente pinchando 
                    <button onClick={scrollToContact} className="underline">
                      aquí
                    </button>
                    .
                  </span>
                </div>
                <div className="flex flex-col w-full">
                  <ButtonDS
                    label={"Descargar documentos"}
                    leftIcon="FileDownload"
                    onClick={() => downloadableFiles()}
                    className="w-full mt-[16px]"
                    buttonType="secondary"
                  />
                </div>
              </div>
            </>
          )}
          <AnimatedAlert
            icon={"InfoIcon"}
            title={translate("landing.calculator.contactAlertTitle", lang)}
            description={translate(
              "landing.calculator.contactAlertDescripton",
              lang
            )}
            defaultState={"closed"}
            ref={alertRef}
            float={true}
            className="top-[124px] md:!max-w-[623px]"
            autoCloseIn={12000}
          />
        </div>
      </div>
      <ModalDS
        onClose={() => setDownloadablesOpen(false)}
        open={downloadablesOpen}
        icon="DownloadCloudIcon"
        title={translate("downloadableFiles.modal.title", lang)}
        content={
          <div>
            {downloadables.length > 0 ? (
              <DownloadableFiles downloadablePdfs={downloadables} lang={lang} />
            ) : null}
          </div>
        }
      />
    </>
  );
};

export default CalculatorSQL;
