import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import LoginOtpController from './controllers/login-otp-request.controller';
import constants from '../constants/login-otp.constants';
import './login-otp-request.view.scss';
import InputGetLife from '../../components/inputGetLife/InputGetLife';

export const LoginOtpRequestView = () => {
	const { contactValue, error, errorMessage, handleClickSubmit, handleKeyDown, inputType, setContactValue, translate } =
		LoginOtpController();

	return (
		<>
			<div className="login-otp__form__body">
				<label>{translate(constants[inputType].input.label)}</label>
				<div className="login-otp__form__body__input">
					<div className="login-otp__form__body__input__field">
						<InputGetLife
							placeholder={translate(constants[inputType].input.placeholder)}
							handleValues={setContactValue}
							contentType={inputType}
							answer={contactValue}
							error={error ? true : false}
							errorMessage={error ? error : null}
							onKeyDown={handleKeyDown}
						/>
					</div>
					<div className="login-otp__form__body__input__button">
						<ButtonPrimary content={translate(constants.request_button_text)} onClick={handleClickSubmit} />
					</div>
				</div>
				{error && (
					<div className="login-otp__form__body__input__error">
						<span>{translate(errorMessage)}</span>
					</div>
				)}
			</div>
		</>
	);
};
