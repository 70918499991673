import * as React from "react";
import keys from "../../../../utils/keyboardKeys";

export interface InputBaseControllerProps {
  editValue?: string;
  max?: string | number;
  min?: string | number;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setter?: (value: string) => void;
  setterOnBlur?: (value: string) => void;
}

export const InputBaseController = ({
  editValue,
  max,
  min,
  onChange,
  onBlur,
  setter,
  setterOnBlur,
}: InputBaseControllerProps) => {
  const [value, setValue] = React.useState<string | undefined>(editValue);

  React.useEffect(() => {
    setValue(editValue);
  }, [editValue]);

  const limits = (newValue: string, limit?: string | number, type?: string) => {
    const newLimit = typeof limit === "string" ? parseInt(limit) : limit;
    if (newLimit && type === "max" && parseInt(newValue) > newLimit) {
      return newLimit;
    } else if (newLimit && type === "min" && parseInt(newValue) < newLimit) {
      return newLimit;
    }

    return newValue;
  };

  const limitValue = (value: string): string => {
    const newValue = limits(value, max, "max");
    const newValueMin = limits(value, min, "min");
    if (newValue !== value) {
      return newValue as string;
    } else if (newValueMin !== value) {
      return newValueMin as string;
    }
    return value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newValue = limitValue(value).toString();
    setValue(newValue);
    if (setter) {
      setter(newValue.trim());
    }
    if (onChange) {
      onChange(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    if (setterOnBlur) {
      setterOnBlur(value?.trim() as string);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (value !== editValue && setter) {
      setter(value?.trim() as string);
    }
  };

  const isAllowedKeys = (keyCode: string | number) => {
    const allowedKeys: any[] = [];
    Object.values(keys).forEach((value) => allowedKeys.push(value));

    let isDigit: boolean = !!keyCode;

    if (typeof keyCode === "string") {
      isDigit = keyCode.includes("Digit") || keyCode.includes("Numpad");
    } else {
      isDigit =
        (keyCode >= 8 && keyCode <= 13) ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105);
    }

    return isDigit || allowedKeys.includes(keyCode);
  };

  return {
    handleBlur,
    handleChange,
    handleKeyDown,
    isAllowedKeys,
    value,
  };
};
