import * as React from "react";
import {
  BankEntityData,
  LegalPersonData,
  OtherBeneficiariesTypes,
  PhysicalPersonData,
} from "../../../../../api/request/Lead/Model/Request/PostStackRequest.model";
import useForm from "../../../../../hooks/useForm.hook";
import { requiredFieldsBeneficiaries } from "../../../../../utils/staticData";
import { FormProps } from "../FromInterface.model";

export const initialValuesOtherBeneficiaries: any = {
  physicalPerson: {
    name: "",
    lastName: "",
    lastName2: "",
    idNumber: "",
    checked: false,
    maidenName: "",
    birthdate: "",
    birthCity: ""
  },
  legalPerson: { businessName: "", fiscalNumber: "" },
  bankingEntity: { bankName: "", loanMortgageNumber: "" },
};

const compareObjects = (first: any, second: any) => {
  return Object.keys(first)
    .map((key: string) => first[key] === second[key])
    .every((value) => !!value);
};

interface CardData {
  idNumber: string;
  name: string;
  capital: number;
  type: OtherBeneficiariesTypes;
  title: string;
  id: number;
}

export interface PhysicalPersonValues extends PhysicalPersonData {
  id?: string;
  checked?: boolean;
}

export interface LegalPersonValues extends LegalPersonData {
  id?: string;
}

export interface BankEntityValues extends BankEntityData {
  id?: string;
}

const reducer = (accumulator: number, curr: number) => accumulator + curr;

export const getSummation: (summatoryList: any[]) => number = (
  summatoryList
): number =>
  summatoryList
    .map((beneficiary: any) => parseInt(beneficiary.capital))
    .reduce(reducer);

const FormOtherBeneficiariesController = ({
  setValue,
  values,
  translate,
}: FormProps) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [showModalEdit, setShowModalEdit] = React.useState<boolean>(false);

  const [type, setType] =
    React.useState<OtherBeneficiariesTypes>("physicalPerson");

  const [formType, setFormType] = React.useState<"initial" | "edit">("initial");

  const [currentCapital, setCurrentCapital] = React.useState<number>(
    values.capital
  );

  const [requiredFields, setRequiredFields] = React.useState<any>([]);

  React.useEffect(() => {
    const summation = !!values.beneficiariesList.length
      ? getSummation(values.beneficiariesList)
      : 0;
    setCurrentCapital(values.capital - summation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editData = (
    valuesEdit: PhysicalPersonValues | LegalPersonValues | BankEntityValues
  ) => {
    const id = !!valuesEdit.id ? valuesEdit.id : 0;
    delete valuesEdit.id;
    if (!compareObjects(values.beneficiariesList[id], { valuesEdit })) {
      values.beneficiariesList.splice(id, 1, valuesEdit);
      setValue("beneficiariesList", values.beneficiariesList);
      setCurrentCapital(
        values.capital - getSummation(values.beneficiariesList)
      );
    }
    setShowModalEdit(false);
  };
  const submitData = (
    valuesEdit: PhysicalPersonValues | LegalPersonValues | BankEntityValues
  ) => {
    const data = [...values.beneficiariesList, { ...valuesEdit }];
    setValue("beneficiariesList", data);
    setCurrentCapital(currentCapital - valuesEdit.capital);
    setShowModal(false);
  };

  const form = useForm({
    fetch: (
      valuesEdit: PhysicalPersonValues | LegalPersonValues | BankEntityValues
    ) => submitData(valuesEdit),
    requiredFields: requiredFields,
  });
  const formEdit = useForm({
    fetch: (
      valuesEdit: PhysicalPersonValues | LegalPersonValues | BankEntityValues
    ) => editData(valuesEdit),
    requiredFields: requiredFields,
  });

  const getRequiredFields = (
    capital: number,
    typeForm: OtherBeneficiariesTypes
  ) => {
    const fields: any = requiredFieldsBeneficiaries[typeForm];
    const requiredCapital = {
      name: "capital",
      type: "number",
      max: capital,
      min: 1000,
    };
    const indexCapital: number = fields.findIndex(
      (field: any) => field.name === "capital"
    );
    if (indexCapital === -1) {
      fields.push(requiredCapital);
    } else {
      fields.splice(indexCapital, 1, requiredCapital);
    }
    setRequiredFields(fields);
  };

  const forms = {
    initial: form,
    edit: formEdit,
  };

  const modals = {
    initial: { setShowModal, showModal },
    edit: { setShowModal: setShowModalEdit, showModal: showModalEdit },
  };

  const handleClose = () => {
    modals[formType].setShowModal(false);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked && type === "physicalPerson") {
      forms[formType].values = {
        ...forms[formType].values,
        checked,
        idNumber: "N/A",
      };
    } else {
      forms[formType].values = {
        ...forms[formType].values,
        checked,
        idNumber: "",
      };
    }
    forms[formType].setValues(forms[formType].values);
  };

  const handleOpen = (entity: OtherBeneficiariesTypes) => {
    form.setValues({
      ...initialValuesOtherBeneficiaries[entity],
      capital: currentCapital,
      type: entity,
    });
    getRequiredFields(currentCapital, entity);
    setShowModal(true);
    setFormType("initial");
    setType(entity);
  };

  const handleDelete = (id: number) => {
    const actualValue = values.beneficiariesList[id];
    values.beneficiariesList.splice(id, 1);
    setCurrentCapital(currentCapital + parseInt(actualValue.capital));
    setValue("beneficiariesList", values.beneficiariesList);
  };

  const handleEdit = (id: number) => {
    const actualValue = values.beneficiariesList[id];
    const newCapital: number = currentCapital + parseInt(actualValue.capital);
    formEdit.setValues({
      ...actualValue,
      id,
    });
    getRequiredFields(newCapital, actualValue.type);
    setFormType("edit");
    setType(actualValue.type);
    setShowModalEdit(true);
  };

  const getDataCard = ({ name, type, idNumber }: any) => ({
    name,
    idNumber,
    type,
    title: translate(`postStack.card.${type}`).toLocaleUpperCase(),
  });

  const normalizeShowInformation = (): CardData[] => {
    const beneficiaries: CardData[] = values.beneficiariesList.map(
      (beneficiary: any, index: number) => {
        let data = {
          capital: beneficiary.capital,
          id: index,
        };
        if (beneficiary.type === "physicalPerson" || !!beneficiary.name) {
          const { idNumber, lastName, lastName2, name } = beneficiary;
          data = {
            ...data,
            ...getDataCard({
              idNumber,
              type: "physicalPerson",
              name: `${name} ${lastName} ${lastName2}`,
            }),
          };
        } else if (
          beneficiary.type === "legalPerson" ||
          !!beneficiary.businessName
        ) {
          const { businessName, fiscalNumber } = beneficiary;
          data = {
            ...data,
            ...getDataCard({
              idNumber: fiscalNumber,
              type: "legalPerson",
              name: businessName,
            }),
          };
        } else {
          const { bankName, loanMortgageNumber } = beneficiary;
          data = {
            ...data,
            ...getDataCard({
              idNumber: bankName,
              type: "bankingEntity",
              name: loanMortgageNumber,
            }),
          };
        }
        return data;
      }
    );

    return beneficiaries;
  };

  const handles = {
    handleCheck,
    handleClose,
    handleDelete,
    handleEdit,
    handleOpen,
    handleSubmit: forms[formType].handleSubmit,
  };

  return {
    currentCapital,
    error: forms[formType].error,
    formType,
    handles,
    normalizeShowInformation,
    setShowModal: modals[formType].setShowModal,
    setValueBeneficiaries: forms[formType].setValue,
    showModal: modals[formType].showModal,
    type,
    valuesBeneficiaries: forms[formType].values,
  };
};

export default FormOtherBeneficiariesController;
