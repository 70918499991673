import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputGetLife from "../../components/inputGetLife/InputGetLife";
import ButtonPrimary from "../../components/buttons/ButtonPrimary";

import Logo from "./../../../assets/img/logo.png";
import FormControl from "@material-ui/core/FormControl";

import "./recoverPassword.scss";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../api/request/Broker/broker.service";

export default function RecoverPassword() {
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const { setShowNotification } = useContext(ContextGetlife);
  const brokerRepository = new HttpBrokerRepository();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const email = ev.target[0].value;
    try {
      await brokerRepository.resetEmail(email);
      navigate("/resetdone");
    } catch (e) {
      setShowNotification({
        message: e.message,
        status: true,
        type: "error",
      });
    }
  };
  const handleEmail = (e) => setUserEmail(e);

  return (
    <div className="reset">
      <main className="reset--main">
        <img
          src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
          alt="Logo de Life5"
          title="Logo de Life5"
          className="reset--main__img"
        />
        <h6 className="reset--main__title">Recuperar mi contraseña</h6>
        <form className="reset--main__form" onSubmit={handleSubmit}>
          <FormControl className="reset--form__input">
            <InputGetLife placeholder="E-mail" handleValues={handleEmail} />
          </FormControl>
          <FormControl className="reset--form__btn">
            <ButtonPrimary
              type="submit"
              content="Enviar"
              disabled={userEmail === ""}
            />
          </FormControl>
        </form>
      </main>
    </div>
  );
}
