import { ButtonDS, GlobalIcon, Typography } from "get-life-storybook-ts";
import secure from "../../assets/img/secure.svg";

import "./Confirmation.scss";
import { I18nContext } from "../../contexts/i18n.context";
import React from "react";

const ConfirmationPage = () => {
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  return (
    <main className="confirmationPage">
      <GlobalIcon iconName="PendingContract" size="L" />
      <div className="confirmationPage-content">
        <Typography
          variant="H2"
          text={translate("confirmationPage.title")}
          weight="700"
          className="confirmationPage-title"
        />
        <Typography
          variant="BodyLarge"
          weight="500"
          text=""
          className="confirmationPage-description"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: translate("confirmationPage.description"),
            }}
          />
        </Typography>
        <Typography
          variant="BodyLarge"
          weight="500"
          text=""
          className="confirmationPage-email"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: translate("confirmationPage.email"),
            }}
          />
        </Typography>
      </div>
      <ButtonDS
        label={translate("confirmationPage.button")}
        href="/projects"
        className="confirmationPage-button"
      />
      <div className="confirmationPage-footer">
        <Typography
          variant="BodyXSmall"
          weight="400"
          text=""
          className="confirmationPage-secureEncryption"
        >
          <img
            src={secure}
            alt="aplicación segura"
            className="confirmationPage-secureIcon"
          />{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: translate("confirmationPage.secureEncryption"),
            }}
          />
        </Typography>
        <Typography
          variant="BodyXSmall"
          weight="500"
          text={translate("confirmationPage.footer")}
        />
      </div>
    </main>
  );
};

export default ConfirmationPage;
