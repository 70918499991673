import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";
import { I18nContext } from "../../contexts/i18n.context";
import { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../api/request/Broker/broker.service";

const DeleteEmployeeModal = ({
  open,
  onClose,
  fetchEmployees,
}: {
  open: string;
  onClose: () => void;
  // eslint-disable-next-line no-empty-pattern
  fetchEmployees: ({}) => void;
}) => {
  const [employeeDeleted, setEmployeeDeleted] = useState(false);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { brokerId, token } = useContext(ContextGetlife);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const deleteEmployee = async () => {
    const employeeId = open;
    if (open) {
      try {
        const response = await brokerRepository.deleteEmployee(employeeId);
        fetchEmployees({});
        setEmployeeDeleted(true);
      } catch {}
    }
  };

  useEffect(() => {
    if (open !== "") {
      setEmployeeDeleted(false);
    }
  }, [open]);

  return (
    <ModalDS
      open={open !== ""}
      onClose={onClose}
      title={
        employeeDeleted
          ? translate("employee.delete.modal.deleteEmployee")
          : translate("employee.delete.modal.title")
      }
      icon="AlertTriangleIcon"
      content={
        employeeDeleted ? (
          <div className="BodyM text-[#424242]">
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            {translate("employee.delete.modal.deleteCompleted")}
          </div>
        ) : (
          <div
            className="BodyM text-[#424242]"
            dangerouslySetInnerHTML={{
              __html: translate("employee.delete.modal.content"),
            }}
          />
        )
      }
      buttons={
        employeeDeleted ? undefined : (
          <>
            <ButtonDS
              label={translate("employee.delete.modal.cancel")}
              buttonType="primary"
              ghost
              onClick={onClose}
            />
            <ButtonDS
              label={translate("employee.delete.modal.confirm")}
              onClick={deleteEmployee}
            />
          </>
        )
      }
      buttonsWrap={false}
      autoClose={employeeDeleted}
    />
  );
};

export default DeleteEmployeeModal;
