import { useContext } from "react";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../../contexts/i18n.context";
import InviteEmployeeModalController, {
  InviteEmployeeModalI,
} from "./InviteEmployeeModal.controller";
import { onEnterBlur } from "../../../../utils/onEnterBlur";

const InviteEmployeeModal = ({
  open,
  onClose,
  fetchEmployees,
}: InviteEmployeeModalI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    form,
    roleOptions,
    updateForm,
    toggleFormModified,
    formError,
    sendInvite,
    invitationSent,
  } = InviteEmployeeModalController({ open, onClose, fetchEmployees });

  return (
    <ModalDS
      open={open}
      onClose={onClose}
      title={translate("employee.invite.modal.title")}
      icon="UserPlusIcon"
      content={
        invitationSent ? (
          <div>
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            <p className="BodyM text-[#424242] mt-[16px]">
              {translate("employee.invite.modal.invitation.send")}
            </p>
          </div>
        ) : (
          <div className="flex flex-col gap-[24px]">
            <SelectDS
              label={`${translate("employee.invite.modal.role.name")}*`}
              placeholder={translate("employee.invite.modal.role.placeholder")}
              options={roleOptions}
              value={form.role}
              onChange={(e) => updateForm("role", e.target.value)}
              onBlur={() => toggleFormModified("role")}
              error={formError["role"]}
            />
            <div className="grid grid-cols-2 gap-[24px] flex-1">
              <InputDS
                label={translate("employee.invite.modal.name.name")}
                placeholder={translate(
                  "employee.invite.modal.name.placeholder"
                )}
                value={form.name}
                onBlur={() => toggleFormModified("name")}
                onChange={(e) => updateForm("name", e.target.value)}
                onKeyDownCapture={onEnterBlur}
              />
              <InputDS
                label={translate("employee.invite.modal.surname.name")}
                placeholder={translate(
                  "employee.invite.modal.surname.placeholder"
                )}
                value={form.lastname}
                onBlur={() => toggleFormModified("lastname")}
                onChange={(e) => updateForm("lastname", e.target.value)}
                onKeyDownCapture={onEnterBlur}
              />
            </div>
            <InputDS
              label={`${translate("employee.invite.modal.email.name")}*`}
              placeholder={translate("employee.invite.modal.email.placeholder")}
              helper={translate("employee.invite.modal.email.helper")}
              value={form.email}
              onBlur={() => toggleFormModified("email")}
              onChange={(e) => updateForm("email", e.target.value)}
              type="email"
              error={formError["email"]}
              onKeyDownCapture={onEnterBlur}
            />
          </div>
        )
      }
      buttons={
        invitationSent ? null : (
          <ButtonDS
            label={translate("employee.invite.modal.send")}
            rightIcon="ArrowRightIcon"
            className="w-[291px] max-w-full ml-auto"
            onClick={sendInvite}
          />
        )
      }
      buttonsWrap={true}
      autoClose={invitationSent}
    />
  );
};

export default InviteEmployeeModal;
