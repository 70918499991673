import React from "react";
import { IconProps } from "./IconProps.interface";
import "./Icon.styles.scss";

interface MinusIconProps extends IconProps {
  colorRect?: string;
}

const MinusIcon: React.FC<MinusIconProps> = ({
  width = "25",
  height = "25",
  color = "white",
  colorRect = "#424242",
  className,
  disabled,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      className={`${disabled && "disabled"} ${className}`}
      onClick={onClick}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="24.5017" rx="6" fill={colorRect} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8314 11.0264C13.3397 11.0264 13.125 11.0275 12.0538 11.0262H5.88882C5.39712 11.0262 5 11.4155 5 11.8973C5 12.3792 5.39712 12.7684 5.88882 12.7684H12.0538C11.5622 12.7684 13.125 12.7684 12.0538 12.7684C12.5455 12.7684 9.375 12.7684 13.8314 12.7684H19.9964C20.4881 12.7684 20.8853 12.3792 20.8853 11.8973C20.8853 11.4155 20.4881 11.0262 19.9964 11.0262L13.8314 11.0264C13.125 11.0264 14.3105 11.0264 13.8314 11.0264Z"
        fill={color}
      />
    </svg>
  );
};

export default MinusIcon;
