import { ListEmployeeReference } from "../../../api/request/Lists/Model/Response/ListResponse.model";
import {
  dateTimeToDate,
  validateEmailToTables,
} from "../../../utils/staticData";
import BrokerResponse from "../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { formatPhone } from "./Utils/formatPhone";
import { columnsEmployee } from "./constants/columns";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  SelectDS,
  TooltipHover,
} from "get-life-storybook-ts";
import { renderActions } from "./Utils/renderActions";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { useContext, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import {
  pagination_buttons_left,
  pagination_buttons_right,
} from "./TableLife5Employees.controller";
import { Employee } from "../../../api/request/Lists/Model/Request/ListRequest.model";
import { I18nContext } from "../../../contexts/i18n.context";
import InviteEmployeeModal from "./InviteEmployeeModal/InviteEmployeeModal";
import { ROLESTABLE } from "../../../utils/roles";
interface table {
  employees: Employee[];
  broker: BrokerResponse | undefined;
  omniValue: string;
  setOmniValue: (e: string) => void;
  fetchEmployees: ({
    omniSearch,
    newPage,
    newPageSize,
  }: {
    omniSearch?: string;
    newPage?: number;
    newPageSize?: number;
  }) => Promise<void>;
  totalItems: number;
  totalPages: number;
  indexPage: number;
  pageSize: number;
  handlePageSize: (value: string) => void;
  handlePagination: (value: string) => void;
  inviteEmployee: () => void;
  showEditEmployee: (brokerUlid: string) => void;
  setShowDeleteModal: (value: string) => void;
}

export const TableLife5Employees = ({
  employees,
  omniValue,
  setOmniValue,
  fetchEmployees,
  totalItems,
  totalPages,
  indexPage,
  pageSize,
  handlePageSize,
  handlePagination,
  showEditEmployee,
  setShowDeleteModal,
}: table) => {
  const [inviteModal, setInviteModal] = useState(false);
  const { token } = useContext(ContextGetlife);
  const leadRepository = new HttpLeadRepository(token);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const renderTableHeader = () => {
    return columnsEmployee.map((column, key) => {
      return (
        <>
          <div className="flex-1" style={{ maxWidth: column.width }}>
            <div
              key={key}
              className="tableHeader flex BodyS font-bold uppercase whitespace-nowrap"
            >
              {" "}
              {translate(column.name)}
            </div>
            {employees &&
              employees.map((employee, key) => {
                return (
                  <div
                    className={`tableBodyContainer w-full flex ${
                      key % 2 == 0 ? "rowOdd" : "rowEven"
                    }`}
                  >
                    <div key={key} className={`tableBodyRow flex`}>
                      {
                        <div className="tableBody flex">
                          {parseData(employee, column.reference)}
                        </div>
                      }
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      );
    });
  };

  const buildTotals = () => {
    return `Pág. ${indexPage} - Pág. ${totalPages}`;
  };

  const parseData = (employee: Employee, ref: ListEmployeeReference) => {
    if (ref === "phone") {
      return formatPhone(employee.phone);
    }

    if (ref === "email") {
      return (
        <span className="containerText">
          {validateEmailToTables(translate, employee.email)}
        </span>
      );
    }

    if (ref === "createdAt") {
      return dateTimeToDate(employee.createdAt);
    }

    if (ref === "actions") {
      return renderActions(
        translate,
        showEditEmployee,
        employee,
        setShowDeleteModal
      );
    }

    if (ref === "fullName") {
      return <span className="containerText">{employee.fullName}</span>;
    }

    if (ref === "roles") {
      return (
        <TooltipHover
          offsetY={0}
          content={translate(`employees.table.${employee.roles[0]}`)}
          direction="bottom center"
        >
          <span className="containerText">
            {translate(ROLESTABLE[employee.roles[0]])}
          </span>
        </TooltipHover>
      );
    }
  };

  const optionsPage = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "20",
      value: "20",
    },
  ];

  const openInviteEmployee = () => {
    setInviteModal(true);
  };

  const closeInviteEmployee = () => {
    setInviteModal(false);
  };

  return (
    <>
      <div className="w-full">
        <div className="w-full gap-4">
          <div className="pb-6">
            <p className="H3 font-bold text-dark-gray-100">
              {translate("employees.header.title")}
            </p>
            <p className="BodyM text-dark-gray-100">{`${totalItems} ${translate(
              "employees.header.subtitle"
            )}`}</p>
          </div>

          <div className=" flex flex-col gap-10">
            <div className="flex justify-between pb-6">
              <div className="w-[447px]">
                <InputDS
                  value={omniValue}
                  name="omniSearch"
                  label={translate("projects.search.label")}
                  icon="SearchIcon"
                  placeholder={translate("employees.search.placeholder")}
                  onChange={(e) => setOmniValue(e.target.value)}
                  clearInputCallback={() =>
                    fetchEmployees({ omniSearch: "", newPage: 1 })
                  }
                  type="search"
                  className="w-[447px]"
                />
              </div>
              <div className="flex gap-4 items-end">
                <ButtonDS
                  onClick={() => openInviteEmployee()}
                  label={translate("employees.noData.buttonText")}
                  buttonType={"primary"}
                  className="h-fit w-fit"
                  leftIcon="UserPlusIcon"
                />
              </div>
            </div>
          </div>

          <div className="tableProjectsContainer">
            <div className="tableHeaderContainer w-full flex">
              {renderTableHeader()}
            </div>
          </div>
          <div className="tableFooterContainer w-full">
            <div className="custom-table__footer">
              <div className="custom-table__rowsper"></div>
              <div className="custom-table__pagination">
                <div className="custom-table__pagination__totals BodyM font-medium text-[#555]">
                  {buildTotals()}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_left.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
                <div className="custom-table__pagination__buttons">
                  {pagination_buttons_right.map((element, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        handlePagination(element.value);
                      }}
                    >
                      <GlobalIcon
                        iconName={element.icon}
                        size="XS"
                        color="currentColor"
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="containerPage">
            <SelectDS
              label={translate("tables.footer.filter")}
              options={optionsPage}
              value={pageSize}
              onChange={(e) => handlePageSize(e.target.value)}
            />
          </div>
        </div>
      </div>
      <InviteEmployeeModal
        open={inviteModal}
        onClose={closeInviteEmployee}
        fetchEmployees={fetchEmployees}
      />
    </>
  );
};
