import { createContext, useContext, useState } from 'react';

export const LoginOtpContext = createContext();

export const LoginOtpProvider = ({ children }) => {
	const [requested, setRequested] = useState(false);
	const [inputType, setInputType] = useState('email');
	const [contactValue, setContactValue] = useState('');

	return (
		<LoginOtpContext.Provider
			value={{ contactValue, inputType, requested, setContactValue, setInputType, setRequested }}
		>
			{children}
		</LoginOtpContext.Provider>
	);
};

export const useLoginOtpContext = () => useContext(LoginOtpContext);

export const withLoginOtpProvider = (WrappedComponent) => (props) =>
	(
		<LoginOtpProvider>
			<WrappedComponent {...props} />
		</LoginOtpProvider>
	);
