import { useContext } from "react";
import {
  ButtonDS,
  ModalDS,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import numberFormat from "../../../utils/numberFormat";

interface ModalPropsI {
  capital: number | null
}

const MaxCapitalModal = ({ capital }: ModalPropsI) => {
  const { state: { translate } } = useContext(I18nContext)
  const { maxCapitalModal, setMaxCapitalModal } = useContext(ContextGetlife)

  return (
    <ModalDS
      open={maxCapitalModal}
      onClose={() => setMaxCapitalModal(false)}
      icon="AlertCircleIcon"
      title={translate("maxCapital.modal.title")}
      content={<>
        <span>
          {translate("maxCapital.modal.body").replace(
            "$",
            numberFormat(capital, "capital").toString()
          )}
        </span>
      </>
      }

      buttons={
        <ButtonDS
          onClick={() => {
            setMaxCapitalModal(false);
          }}
          label={translate("maxCapital.modal.button")}
          className="max-w-[320px] ml-auto"
          rightIcon="ArrowRightIcon"
        />
      }
    />
  )
};

export default MaxCapitalModal;
