import { DateTime } from "luxon";
import REGEX from "./regExp";
import apiLeadValidateZipCodeFr from "../api/request/apiLeadValidateZipCodeFr";
import { GlobalCitizenship } from "./InternationlChanges";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export const validateRegex = (regex, field) => {
  let domainRegex = regex;
  if (regex === "PHONE" && DOMAIN === "fr") {
    domainRegex = "PHONEFR";
  }
  if (regex === "PHONE" && DOMAIN === "it") {
    domainRegex = "PHONEIT";
  }
  const correctField = field.match(REGEX[domainRegex]);

  return !correctField;
};

const validateMaxMin = (field, max, min, name) => {
  let error;
  if (field > max) {
    error = {
      message: `Este campo supera el importe máximo ${max}`,
      field: name,
    };
  } else if (field < min) {
    error = {
      message: `Este campo es inferior al mínimo disponible ${min}`,
      field: name,
    };
  }
  return error;
};

const validCountries = GlobalCitizenship[DOMAIN];

const validateCitizen = (field) => {
  if (typeof field === "string") {
    return validCountries.includes(field);
  }
  return validCountries.includes(field.value);
};

const defaultMinimumYear = 18;
const minimumYear = defaultMinimumYear;
const defaultMaximumYear = 74;
const maximumYear = {
  es: defaultMaximumYear,
  fr: 65,
  it: 75
}[DOMAIN] || defaultMaximumYear

const validateDate = (field) => {
  const date = DateTime.fromISO(field);
  const now = DateTime.now();
  const years = now.diff(date, 'years').years;
  
  return !(years >= minimumYear && years <= maximumYear);
};

const isInvalidField = async ({
  type,
  field,
  name,
  optional,
  max,
  min,
  translate,
}) => {
  let error;

  if (!field && !optional) {
    error = { message: translate("validator.error.request"), field: name };
  } else if (field && type === "number") {
    error = validateMaxMin(field, max, min, name);
  } else if (field && type === "zipCode") {
    if (
      (DOMAIN === "es" || DOMAIN === "pt") &&
      validateRegex("ZIPCODE", field)
    ) {
      error = {
        message: translate("validator.error.zipCode"),
        field: name,
      };
    }

    if (DOMAIN === "fr") {
      await apiLeadValidateZipCodeFr(field).then((res) => {
        if (res.isValidPostalCodeFR === false) {
          error = {
            message: translate("validator.error.zipCode"),
            field: name,
          };
        }
      });
    }

    if (DOMAIN === "it") {
      if (field.length !== 5) {
        error = {
          message: translate("validator.error.zipCode"),
          field: name,
        };
      }
    }
  } else if (field && type === "phone" && validateRegex("PHONE", field)) {
    error = {
      message: translate("validator.error.phone"),
      field: name,
    };
  } else if (field && type === "email" && validateRegex("EMAIL", field)) {
    error = {
      message: translate("validator.error.email"),
      field: name,
    };
  } else if (field && type === "citizenship" && !validateCitizen(field)) {
    error = {
      message: translate("validator.error.citizenship"),
      field: name,
    };
  } else if (field && type === "date" && validateDate(field)) {
    error = {
      message: translate("errors.validate.date"),
      field: name,
    };
  } else if (field && name === "idNumber" && true) {
    if (DOMAIN === "it") {
      let formatCodice = field?.replace(/\s/g, "");
      if (field) {
        await fetch(
          process.env.REACT_APP_CUSTOMERS_APP_URL +
          `lead/identity/validate/${formatCodice}`
        )
          .then((response) => response.json())
          .then((res) => {
            if (res.status === false) {
              error = { field: name, message: translate("validator.error.idNumber") };
            }
          })
          .catch((e) => {
            error = { field: name, message: translate("validator.error.idNumber") };
          });
      }
    }
  }
  return error;
};

const isInvalidFieldDashboard = ({ values, name, translate }) => {
  if (!values.email && !values.referenceName) {
    return {
      message: translate("calculator.error.requestField"),
      field: name,
    };
  }
};

const isInvalidFieldDashboardES = ({ values, name, translate }) => {
  if (!values.email && !values.referenceName && !values.phone) {
    return {
      message: translate("calculator.error.requestField"),
      field: name,
    };
  }
};

const validateForm = async ({ values, requiredFields, dashBoard, translate }) => {
  const invalidFields = await Promise.all(requiredFields.map(async (field) => {
    const { name, type, optional, max, min } = field;
    const fieldValue = values[name] || "";
    const answer = isInvalidField({
      type,
      field: fieldValue,
      name,
      optional,
      max,
      min,
      translate,
    });
    return answer;
  }));

  const invalidFielsDasboard = dashBoard
    ? [
      ...invalidFields,
      isInvalidFieldDashboard({ values, name: "email", translate }),
      isInvalidFieldDashboard({ values, name: "referenceName", translate }),
    ]
    : invalidFields;
  const validate = invalidFielsDasboard.every((field) => !field);
  if (!validate) {
    const error = invalidFielsDasboard.map((e) => {
      if (e) {
        const { field, message } = e;
        return {
          field: field,
          message: message,
        };
      }
      return {
        field: "",
        message: "",
      };
    });
    throw new Error(JSON.stringify(error));
  }
};

export default validateForm;

export const validateFormES = async ({ values, requiredFields, dashBoard, translate }) => {
  const invalidFields = await Promise.all(requiredFields.map(async (field) => {
    const { name, type, optional, max, min } = field;
    const fieldValue = values[name] || "";
    const answer = isInvalidField({
      type,
      field: fieldValue,
      name,
      optional,
      max,
      min,
      translate,
    });
    return answer;
  }));
  const invalidFielsDasboard = dashBoard
    ? [
      ...invalidFields,
      isInvalidFieldDashboardES({ values, name: "email", translate }),
      isInvalidFieldDashboardES({ values, name: "referenceName", translate }),
      isInvalidFieldDashboardES({ values, name: "phone", translate }),
    ]
    : invalidFields;
  const validate = invalidFielsDasboard.every((field) => !field);
  if (!validate) {
    const error = invalidFielsDasboard.map((e) => {
      if (e) {
        const { field, message } = e;
        return {
          field: field,
          message: message,
        };
      }
      return {
        field: "",
        message: "",
      };
    });
    return error;
  }
};

export const validateFormIT = async ({ values, requiredFields, dashBoard, translate }) => {
  const invalidFields = await Promise.all(requiredFields.map(async (field) => {
    const { name, type, optional, max, min } = field;
    const fieldValue = values[name] || "";
    const answer = isInvalidField({
      type,
      field: fieldValue,
      name,
      optional,
      max,
      min,
      translate,
    });
    return answer;
  }));
  const invalidFielsDasboard = dashBoard
    ? [...invalidFields]
    : invalidFields;
  const validate = invalidFielsDasboard.every((field) => !field);
  if (!validate) {
    const error = invalidFielsDasboard.map((e) => {
      if (e) {
        const { field, message } = e;
        return {
          field: field,
          message: message,
        };
      }
      return {
        field: "",
        message: "",
      };
    });
    return error;
  }
};

export const validateFormFR = async ({ values, requiredFields, dashBoard, translate }) => {
  const invalidFields = await Promise.all(requiredFields.map(async (field) => {
    const { name, type, optional, max, min } = field;
    const fieldValue = values[name] || "";
    const answer = await isInvalidField({
      type,
      field: fieldValue,
      name,
      optional,
      max,
      min,
      translate,
    });
    return answer;
  }));
  const invalidFielsDasboard = dashBoard
    ? [...invalidFields]
    : invalidFields;
  const validate = invalidFielsDasboard.every((field) => !field);
  if (!validate) {
    const error = invalidFielsDasboard.map((e) => {
      if (e) {
        const { field, message } = e;
        return {
          field: field,
          message: message,
        };
      }
      return {
        field: "",
        message: "",
      };
    });
    return error;
  }
};

export const dateInPast = ({
  value,
  message,
}) => {
  const valueDate = DateTime.fromISO(value);
  const birthdayCuttoff = DateTime.now();
  if (valueDate >= birthdayCuttoff) {
    return {
      error: true,
      message: message || "",
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

export const dateInFuture = ({
  value,
  message,
}) => {
  const valueDate = DateTime.fromISO(value);
  const birthdayCuttoff = DateTime.now();
  if (valueDate <= birthdayCuttoff) {
    return {
      error: true,
      message: message || "",
    };
  } else {
    return {
      error: false,
      message: "",
    };
  }
};
