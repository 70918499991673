import React from "react";
import { errorMessage } from "../../../../utils/error";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { FormProps } from "./FromInterface.model";

const FormLegalPerson: React.FC<FormProps> = ({
  error,
  setValue,
  translate,
  values,
}): JSX.Element => {
  return (
    <>
      <InputGeneral
        editValue={values["businessName"]}
        label={translate("postStack.legalPersonForm.name")}
        setter={(newValue: string) => setValue("businessName", newValue)}
        name="businessName"
        placeholder={translate("beneficiaries.placeholder.businessName")}
        error={errorMessage(error, "businessName")}
      />
      <InputGeneral
        editValue={values["fiscalNumber"]}
        label={translate("postStack.legalPersonForm.idNumber")}
        setter={(newValue: string) => setValue("fiscalNumber", newValue)}
        name="fiscalNumber"
        placeholder={translate("beneficiaries.placeholder.fiscalNumber")}
        error={errorMessage(error, "fiscalNumber")}
      />
      <InputGeneral
        editValue={values["capital"]}
        label={translate("global.capital")}
        type="number"
        setter={(newValue: string) => setValue("capital", newValue)}
        name="capital"
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "capital")}
      />
    </>
  );
};

export default FormLegalPerson;
