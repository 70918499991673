import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../../contexts/i18n.context";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import { GoogleContext } from "../../../../contexts/GoogleTagManager.context";


const SkipBiometryModal = ({
  open,
  setOpen,
  leadId,
  fetchProjects
}: {
  open: boolean;
  setOpen: (value?: boolean) => void;
  leadId: string;
  fetchProjects: ({ stage, startD, omniSearch }: { stage?: string, startD?: string, omniSearch?: string }) => Promise<void>;
}) => {
  const [skipCompleted, setSkipCompleted] = useState(false);

  const { handleTrackerQuestion } = useContext(GoogleContext);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { token, broker } = useContext(ContextGetlife);

  const leadRepository = new HttpLeadRepository(token);

  const skipBiometry = async () => {
    if (open) {
      try {
        const response = await leadRepository.skipBiometry(leadId);
        setSkipCompleted(true);
        handleTrackerQuestion({
          type: "FS",
          event: "BA_skipbiometry",
          data: {
            leadId: leadId,
            broker: broker?.brokerageId
          },
        });
  
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "BA_skipbiometry",
            leadId: leadId,
            broker: broker?.brokerageId
          },
        });
        fetchProjects({});
      } catch {}
    }
  };

  return (
    <ModalDS
      open={open}
      onClose={() => {setOpen(false); setSkipCompleted(false)}}
      title={
        !skipCompleted
          ? translate("skipBiometry.modal.title")
          : translate("skipBiometry.modal.title.complete")
      }
      icon={skipCompleted ? "ArrowRightLinesIcon" : "AlertTriangleIcon"}
      content={
        skipCompleted ? (
          <div className="BodyM text-[#424242]">
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            <div
            className="BodyM text-[#424242]"
            dangerouslySetInnerHTML={{
              __html: translate("skipBiometry.modal.contentComplete"),
            }}
          />
          </div>
        ) : (
          <div
            className="BodyM text-[#424242]"
            dangerouslySetInnerHTML={{
              __html: translate("skipBiometry.modal.content"),
            }}
          />
        )
      }
      buttons={
        skipCompleted ? undefined : (
          <>
            <ButtonDS
              label={translate("skipBiometry.modal.confirm")}
              onClick={skipBiometry}
            />
          </>
        )
      }
      buttonsWrap={false}
    />
  );
};

export default SkipBiometryModal;
