import { post } from "../../core/HTTPService.service";
import TrackingRequest from "./Model/Request/TrackingRequest.model";

export default class HttpTrackingRepository {
  private readonly API_URL_TRACKING:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/tracking`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  add = async (data: TrackingRequest): Promise<void> => {
    if (process.env.REACT_APP_ENVIROMENT === "PRD") {
      try {
        await post(`${this.API_URL_TRACKING}/add`, data, this.header);
      } catch (error) {
        throw error;
      }
    }
  };
}
