import { GlobalIcon } from "get-life-storybook-ts";
import "./DownloadableFiles.scss";
import { useContext } from "react";
import { I18nContext } from "../../../contexts/i18n.context";

interface DownloadableFilesProps {
  downloadablePdfs:
  |
  {
    template: string;
    url: string;
  }[]

  | undefined;
  lang?: string;
}

const DownloadableFiles = ({
  downloadablePdfs,
  lang
}: DownloadableFilesProps): JSX.Element => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <div className="downloadableFiles">
      {downloadablePdfs &&
        downloadablePdfs.map((pdf) => (
          <a
            className="downloadableFiles__file"
            href={pdf.url}
            key={pdf.template}
            target="__blank"
          >
            <GlobalIcon iconName="DownloadIcon" size="XS" color="#423EF4" />
            <span>{translate(`document.download.${pdf.template}`, lang)}</span>
          </a>
        ))}
    </div>
  );
};

export default DownloadableFiles;
