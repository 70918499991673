import React from "react";
import { useNavigate } from "react-router-dom";
import InputGetLife from "../components/inputGetLife/InputGetLife";
import ButtonPrimary from "../components/buttons/ButtonPrimary";
import { FormControl } from "@mui/material";
import Logo from "./../../assets/img/logo.png";
import Key from "./../../assets/img/key.png";

import "./css/loginPage.scss";

export default function LoginMain(props) {
  const {
    userEmail,
    userPassword,
    handleEmail,
    handlePassword,
    handleSubmit,
    error,
    translate
  } = props;

  const navigate = useNavigate();

  const navigateRecoverPassword = () => {
    navigate("/resetpassword");
  };

  return (
    <div className="loginPage">
      <main className="loginPage--main">
        <img
          src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
          alt="Logo de Life5"
          title="Logo de Life5"
          className="loginPage--main__img"
        />
        {error && (
          <h6 className="loginPage--main__title_error">
            Ha habido un error. <br /> Comprueba tu email y/o contraseña
          </h6>
        )}
        {!error && (
          <h6 className="loginPage--main__title">¡Bienvenido de vuelta!</h6>
        )}
        <form className="loginPage--main__form" onSubmit={handleSubmit}>
          <FormControl className="form--input">
            <InputGetLife placeholder="E-mail" handleValues={handleEmail} />
          </FormControl>
          <InputGetLife
            placeholder="Contraseña"
            handleValues={handlePassword}
            contentType="password"
          />
          <FormControl className="form--btn" style={{ marginTop: "30px" }}>
            <ButtonPrimary
              type="submit"
              content={translate("postStack.buttons.continue")}
              disabled={
                userEmail === "" ? true : userPassword === "" ? true : false
              }
            />
          </FormControl>
        </form>
      </main>
      <footer className="loginPage--footer">
        <button
          className="loginPage--footer__title"
          onClick={navigateRecoverPassword}
        >
          ¿Has olvidado tu contraseña?
        </button>
      </footer>
    </div>
  );
}
