import { ButtonDS, InputDS, ModalDS } from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import { MultiproductContext } from "./MultiproductContext";

const ChangeCapitalModal = ({
  open,
  currentCapital,
  onClose,
  onSave,
}: {
  open: boolean;
  currentCapital: number | undefined;
  onClose: () => void;
  onSave: () => void;
}) => {
  const [capital, setCapital] = useState(currentCapital);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { basicBoundaries, basicFormError, updateDeathCapital } =
    useContext(MultiproductContext);

  useEffect(() => {
    setCapital(currentCapital);
  }, [currentCapital]);

  return (
    <ModalDS
      title={translate("dashboard.multiproduct.changeCapital.title")}
      icon="InfoIcon"
      content={
        <div>
          <p className="mb-[16px]">
            {translate("dashboard.multiproduct.changeCapital.content")}
          </p>
          <InputDS
            type="number"
            label={translate("dashboard.multiproduct.capital.label")}
            placeholder={translate(
              "dashboard.multiproduct.capital.placeholder"
            )}
            name="basicCapital"
            icon="CurrencyEuroIcon"
            onKeyDown={(e) => {
              if (e.key === "," || e.key === ".") e.preventDefault();
            }}
            helper={
              basicBoundaries.max && basicBoundaries.min
                ? `${translate(
                    "dashboard.multiproduct.minimum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(basicBoundaries.min)}, ${translate(
                    "dashboard.multiproduct.maximum"
                  )} ${new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  }).format(basicBoundaries.max)}`
                : undefined
            }
            step={5000}
            value={capital}
            onChange={(e) => {
              const capital = parseInt(e.target.value);
              setCapital(capital);
            }}
            max={basicBoundaries.max!}
            min={basicBoundaries.min!}
            onBlur={() => {
              let value = capital;
              if (value! > basicBoundaries.max!) {
                value = basicBoundaries.max!;
              }
              if (value! < basicBoundaries.min!) {
                value = basicBoundaries.min!;
              }
              if (!value) value = basicBoundaries.min!;
              setCapital(value);
            }}
            error={basicFormError["capital"]}
          />
        </div>
      }
      buttons={
        <>
          <ButtonDS
            label={translate("dashboard.multiproduct.cancel")}
            ghost={true}
            onClick={onClose}
          />
          <ButtonDS
            label={translate("dashboard.multiproduct.saveChanges")}
            onClick={async () => {
              await updateDeathCapital(capital!);
              onSave();
              onClose();
            }}
          />
        </>
      }
      onClose={onClose}
      open={open}
    />
  );
};

export default ChangeCapitalModal;
