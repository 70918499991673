import React from "react";
import ButtonPrimary from "../components/ButtonPrimary/ButtonPrimary";
import secure from "../../assets/img/secure.svg";
import correct from "../../assets/img/correctPolicy.png";
import "./waitingSignature.scss";
import { useNavigate } from "react-router-dom";
import { I18nContext } from "../../contexts/i18n.context";

export default function WaitingSignature() {
  const navigate = useNavigate();
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className={"waitingSignature-outerContainer"}>
      {}
      <div className="waitingSignature-container">
        <img src={correct} alt="poliza emitida correctamente" />
        <h1>{translate("confirmation.title")} </h1>
        <h1>
          <strong>{translate("confirmation.subtitle")}</strong>
        </h1>
        <h2>
          {translate("confirmation.paragraph1")}
          <strong>{translate("confirmation.paragraph2")}</strong> {translate("confirmation.paragraph3")}
        </h2>
        <h2>
          {translate("confirmation.sendText")}
          <a href={`mailto:${translate("confirmation.email")}`}>{translate("confirmation.email")}</a>
        </h2>
        <div className="waitingSignature-buttonContainer">
          <ButtonPrimary
            content={translate("confirmation.button.back")}
            onClick={() => navigate("/dashboard/calculator")}
          />
        </div>
        <div className="waitingSignature-footerContainer">
          <div className="waitingSignature-footer-secure">
            <img src={secure} alt="aplicación segura" /> <strong>{translate("confirmation.footer.ssl1")}</strong>
            {translate("confirmation.footer.ssl2")}
          </div>
          <p className="waitingSignature-footer">
            {translate("confirmation.footer.text")}
          </p>
        </div>
      </div>
    </div>
  );
}
