import React from 'react';

import './checkbox.scss';

export default function Checkbox(props) {
	const { answer, handleValues } = props;
	return (
		<div className='form--checkboxContainer'>
			<input
				id={props.id}
				type={props.type}
				name={props.name}
				value={answer}
				className='form--checkbox'
				onChange={() => handleValues()}
			/>
			<label for={props.id} className='form--labelCheckbox'>
				{props.label}
			</label>
		</div>
	);
}
