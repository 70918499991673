// Components
import { CustomTable } from "../components/CustomTable/custom-table.component";
import { TableLife5Employees } from "../components/TableLife5Employees/TableLife5Employees";
// Controller
import EmployeesController from "./controllers/Employees.controller";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
import EditEmployee from "./EditEmployee.component";
import "./Employees.scss";

const Employees = () => {
  const {
    employees,
    omniValue,
    setOmniValue,
    fetchEmployees,
    totalItems,
    totalPages,
    indexPage,
    pageSize,
    showEditEmployee,
    showEditModal,
    setShowEditModal,
    saveUpdateEmployee,
    values,
    setValues,
    brokerUlidActive,
    showDeleteModal,
    setShowDeleteModal,
    formError,
    handlePageSize,
    handlePagination
  } = EmployeesController();

  return (
    <div className="project__container">
      <TableLife5Employees
        employees={employees}
        omniValue={omniValue}
        setOmniValue={setOmniValue}
        fetchEmployees={fetchEmployees}
        totalItems={totalItems}
        totalPages={totalPages}
        indexPage={indexPage}
        pageSize={pageSize}
        showEditEmployee={showEditEmployee}
        setShowDeleteModal={setShowDeleteModal}
        handlePageSize={handlePageSize}
        handlePagination={handlePagination}
      />

      <EditEmployee
        id="employee-page"
        values={values}
        formError={formError}
        showModal={showEditModal}
        setValue={setValues}
        setShowModal={setShowEditModal}
        handleSubmit={saveUpdateEmployee}
        brokerUlidActive={brokerUlidActive}
      />
      <DeleteEmployeeModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal("")}
        fetchEmployees={fetchEmployees}
      />
    </div>
  );
};

export default Employees;
