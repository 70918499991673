// Vendors
// Components
import { LoginOtpRequestView } from "./views/login-otp-request.view";
import { LoginOtpSubmitView } from "./views/login-otp-submit.view";
import ButtonPrimary from "../components/ButtonPrimary/ButtonPrimary";
// Controllers
import LoginOtpController from "./controllers/login-otp.container.controllers";
// Styles
import "./login-otp.container.scss";
// Constants
import constants from "./constants/login-otp.constants";
// Context
import { withLoginOtpProvider } from "./context/login-otp.context";
import { useContext } from "react";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import Button from "../Rebranding/atoms/Buttons/ButtonBase/Button.component";

const LoginOtp = () => {
  const { brokerId, setBroker, setBrokerId, setStatus, setToken, token } =
    useContext(ContextGetlife);
  const {
    handleClickChooseBetter,
    handleClickNoAccount,
    handleClickNoCode,
    inputType,
    requested,
    translate
  } = LoginOtpController(brokerId, setBroker, token, setStatus);
  return (
    <div className="login-otp__wrapper">
      <div className="login-otp__content">
        <div className="login-otp__form">
          <div className="login-otp__form__header">
            <div
              className={`login-otp__form__header__logo ${
                requested && "extra-margin"
              }`}
            >
              <img src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg" alt="GetLife" className="login-otp__form__header__logo__svg" />
            </div>
          </div>
          {!requested && (
            <div className="login-otp__form__title">
              <h1>{translate("login.title")}</h1>
            </div>
          )}
          <>
            {!requested ? (
              <LoginOtpRequestView />
            ) : (
              <LoginOtpSubmitView
                handleBroker={setBroker}
                handleBrokerId={setBrokerId}
                handleToken={setToken}
                setStatus={setStatus}
              />
            )}
          </>
          <div className="login-otp__form__footer">
            {requested && (
              <Button
                onClick={() => {
                  handleClickNoCode();
                }}
                buttonType="text"
              >
                {translate(constants.no_code_text)}
              </Button>
            )}

            <Button
              buttonType="text"
              className="login-otp__form__footer_button"
              onClick={() => handleClickChooseBetter(inputType)}
            >
              {translate(constants[inputType].button_choose_better_text)}
            </Button>
            <p>{translate(constants[inputType].p)}</p>
          </div>
        </div>
        {!requested && (
          <div className="login-otp__account">
            <div className="login-otp__account__body">
              <div className="login-otp__account__body">
                <div className="login-otp__account__body__field">
                  <p>{translate(constants.no_account_text)}</p>
                </div>
                <div className="login-otp__account__body__button">
                  <ButtonPrimary
                    content={translate("login.register")}
                    onClick={handleClickNoAccount}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withLoginOtpProvider(LoginOtp);
