async function apiConfigOfCustomer(token, leadId) {
	const bearer = 'Bearer ' + token;
	const response = await fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/config-of-customer/${leadId}`,
		{
			headers: {
				Authorization: bearer,
			},
		}
	);
	return await response.json();
}

export default apiConfigOfCustomer;