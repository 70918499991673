import React, { useContext } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import "./Loader.styles.scss";

const Loader = () => {
  const { loading } = useContext(ContextGetlife);
  return (
    loading && (
      <div className="loading style_loader">
        <div className="loading-wheel"></div>
      </div>
    )
  );
};

export default Loader;
