import PropTypes from "prop-types";
import React from "react";
import { CheckboxButton } from "get-life-storybook-ts";

interface Props {
  label: string;
  value: string;
  handleValue: (value: string) => void;
  totalStatus: string;
}

export const UniqueStatus = ({
  label,
  value,
  handleValue,
  totalStatus,
}: Props): JSX.Element => {
  return (
    <CheckboxButton
      label={label}
      value={value}
      onChange={(e) => handleValue(e.target.value)}
      checked={totalStatus.includes(value)}
      size="S"
      variant="Check"
    />
  );
};

UniqueStatus.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};
