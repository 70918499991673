import * as React from "react";
import { useLocation, useParams } from "react-router";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import BrokerResponse from "../../../../api/request/Broker/Model/Response/BrokerResponse.model";
import { I18nContext } from "../../../../contexts/i18n.context";
import { THEME_COLORS } from "get-life-storybook-ts/lib/constants/GetlifeStyles";

const LandingSQLController = () => {
  const { slug } = useParams();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const qsLang = urlParams.get("lang");

  const calculatorData = [
    {
      Url: "https://app.life5.es/calculator/4",
      backgroundColor: "#F5F7FA",
      HasPhoneValidation: false,
    },
    {
      Url: "https://app.life5.es/login",
      backgroundColor: "#F5F7FA",
      HasPhoneValidation: false,
    },
  ];

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const [brokerId, setBrokerId] = React.useState<string>("");
  const [broker, setBroker] = React.useState<BrokerResponse>();

  const brokerRepository = new HttpBrokerRepository();

  const fetchBroker = async () => {
    const response: BrokerResponse = await brokerRepository.getBrokerBySlug(
      slug as string,
      search
    );
    setBroker(response);
    setBrokerId(response.id);
  };

  React.useEffect(() => {
    if (broker?.themeColor) {
      const colors = THEME_COLORS[broker.themeColor];
      if (colors) {
        const elements = document.getElementsByClassName("LandingPage");
        const element = elements[0] as HTMLElement | undefined;

        if (element) {
          element.style.setProperty("--theme-primary", colors.primary);
          element.style.setProperty("--theme-secondary", colors.secondary);
          element.style.setProperty("--theme-tertiary", colors.tertiary);
          element.style.setProperty("--theme-quaternary", colors.quaternary);
          element.style.setProperty("--theme-fill", colors.fill);
        }
      }
    }
  }, [broker?.themeColor]);

  React.useEffect(() => {
    (async () => await fetchBroker())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    broker,
    brokerId,
    calculatorData,
    translate,
    qsLang,
  };
};

export default LandingSQLController;
