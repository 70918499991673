export default function apiVelogicaResult(token, tokenVelogica) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/velogica/result?parameters=${tokenVelogica}`,
		{
			headers: {
				Authorization: bearer,
			},
		}
	).then((response) => response.json());
}
