import React from "react";
import { errorMessage } from "../../../../utils/error";
import Checkbox from "../../atoms/Input/CheckBox/Checkbox.component";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { FormPhysicalPersonProps } from "./FromInterface.model";
import { DateInput } from "get-life-storybook-ts";

const FormPhysicalPersonES = ({ translate, values, setValue, error, handleCheck }: FormPhysicalPersonProps) => {
  return (
    <>
      <InputGeneral
        editValue={values["name"]}
        label={translate("global.name")}
        setter={(newValue: string) => setValue("name", newValue)}
        name="name"
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "name")}
      />
      <InputGeneral
        editValue={values["lastName"]}
        label={translate("global.lastName1")}
        setter={(newValue: string) => setValue("lastName", newValue)}
        name="lastName"
        placeholder={translate("beneficiaries.placeholder.lastName")}
        error={errorMessage(error, "lastName")}
      />
      <InputGeneral
        editValue={values["lastName2"]}
        label={translate("global.lastName2")}
        setter={(newValue: string) => setValue("lastName2", newValue)}
        name="lastName2"
        placeholder={translate("beneficiaries.placeholder.lastName2")}
        error={errorMessage(error, "lastName2")}
      />
      <div>
        <InputGeneral
          editValue={values["idNumber"]}
          label={translate("global.idCard")}
          setter={(newValue: string) => setValue("idNumber", newValue)}
          name="idNumber"
          placeholder={translate("beneficiaries.placeholder.idNumber")}
          error={errorMessage(error, "idNumber")}
          disabled={values["checked"]}
        />
        <Checkbox
          label={translate("postStack.checkBox.idNumber")}
          value={values["checked"]}
          checked={values["checked"]}
          id="checknox-physicalPerson"
          onChange={handleCheck}
        />
      </div>
      <InputGeneral
        editValue={values["capital"]}
        label={translate("global.capital")}
        setter={(newValue: string) => setValue("capital", newValue)}
        name="capital"
        type="number"
        placeholder={translate("beneficiaries.placeholder.capital")}
        error={errorMessage(error, "capital")}
      />
    </>
  )
}

const FormPhysicalPersonFR = ({ translate, values, setValue, error, handleCheck }: FormPhysicalPersonProps) => {
  return (
    <>
      <InputGeneral
        editValue={values["name"]}
        label={translate("global.name")}
        setter={(newValue: string) => setValue("name", newValue)}
        name="name"
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "name")}
      />
      <InputGeneral
        editValue={values["lastName"]}
        label={translate("global.lastName1")}
        setter={(newValue: string) => setValue("lastName", newValue)}
        name="lastName"
        placeholder={translate("beneficiaries.placeholder.lastName")}
        error={errorMessage(error, "lastName")}
      />
      <InputGeneral
        editValue={values["lastName2"]}
        label={translate("global.lastName2")}
        setter={(newValue: string) => setValue("lastName2", newValue)}
        name="lastName2"
        placeholder={translate("beneficiaries.placeholder.lastName2")}
        error={errorMessage(error, "lastName2")}
      />
      <div className="inputGetLife-container">
        <InputGeneral
          editValue={values["maidenName"]}
          label={translate("global.maidenName")}
          setter={(newValue: string) => setValue("maidenName", newValue)}
          name="maidenName"
          placeholder={translate("global.placeholder.maidenName")}
          error={errorMessage(error, "maidenName")}
        />
        </div>
        <div className="inputGetLife-container">
        <DateInput
            initialValue={values["birthdate"]}
            onValueChange={(newValue: string) => setValue("birthdate", newValue)}
            query={translate("calculator.label.birthday")}
            textDay={translate("calculator.placeholder.day")}
            textMonth={translate("calculator.placeholder.month")}
            textYear={translate("calculator.placeholder.year")}
            className="items-start dateInput-broker"
          />
        </div>
        <div className="inputGetLife-container">
        <InputGeneral
          editValue={values["birthCity"]}
          label={translate("global.birthCity")}
          setter={(newValue: string) => setValue("birthCity", newValue)}
          name="birthCity"
          placeholder={translate("global.placeholder.birthCity")}
          error={errorMessage(error, "birthCity")}
        />
      </div>
      <div>
        <InputGeneral
          editValue={values["idNumber"]}
          label={translate("global.idCard")}
          setter={(newValue: string) => setValue("idNumber", newValue)}
          name="idNumber"
          placeholder={translate("beneficiaries.placeholder.idNumber")}
          error={errorMessage(error, "idNumber")}
          disabled={values["checked"]}
        />
        <Checkbox
          label={translate("postStack.checkBox.idNumber")}
          value={values["checked"]}
          checked={values["checked"]}
          id="checknox-physicalPerson"
          onChange={handleCheck}
        />
      </div>
      <InputGeneral
        editValue={values["capital"]}
        label={translate("global.capital")}
        setter={(newValue: string) => setValue("capital", newValue)}
        name="capital"
        type="number"
        placeholder={translate("beneficiaries.placeholder.capital")}
        error={errorMessage(error, "capital")}
      />
    </>
  )
}

const FormPhysicalPerson: React.FC<FormPhysicalPersonProps> = ({
  error,
  setValue,
  translate,
  values,
  handleCheck,
}): JSX.Element => {
  if (process.env.REACT_APP_DOMAIN_LOCALE === "es") {
    return  <FormPhysicalPersonES error={error} setValue={setValue} translate={translate} values={values} handleCheck={handleCheck} />
  } else {
    return <FormPhysicalPersonFR error={error} setValue={setValue} translate={translate} values={values} handleCheck={handleCheck} />
  }
  
};

export default FormPhysicalPerson;
