export default function apiLeadQuestionPost(
	brokerId,
	leadId,
	questionId,
	answer,
	token,
) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/question`,

		{
			method: 'POST',
			headers: {
				Authorization: bearer,
			},
			body: JSON.stringify({
				brokerId: brokerId,
				leadId: leadId,
				questionId: questionId,
				answer: answer,
			}),
		}
	).then((response) => response.json());
}
