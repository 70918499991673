import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../../contexts/i18n.context";
import { ROLESTABLE } from "../../../../utils/roles";
import REGEX from "../../../../utils/regExp";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";

export interface InviteEmployeeModalI {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-empty-pattern
  fetchEmployees: ({}) => void;
}

interface InviteEmployeeFormI {
  role: string;
  name: string;
  lastname: string;
  email: string;
}

type ToggleFormModifiedT = <K extends keyof InviteEmployeeFormI>(
  field: K
) => void;

type FormFieldsModifiedI = {
  [key in keyof InviteEmployeeFormI]?: boolean;
};

type formErrorsI = {
  [key in keyof InviteEmployeeFormI]?: string;
};

const defaultForm = {
  role: "",
  name: "",
  lastname: "",
  email: "",
};

const InviteEmployeeModalController = ({
  open,
  onClose,
  fetchEmployees,
}: InviteEmployeeModalI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { brokerId, token } = useContext(ContextGetlife);

  const [form, setForm] = useState<InviteEmployeeFormI>(
    structuredClone(defaultForm)
  );
  const [formFieldsModified, setFormFieldsModified] =
    useState<FormFieldsModifiedI>({});
  const [formError, setFormError] = useState<formErrorsI>({});
  const [invitationSent, setInvitationSent] = useState<boolean>(false);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const roleOptions = Object.keys(ROLESTABLE).map((k) => ({
    label: translate((ROLESTABLE as { [key: string]: string })[k]),
    value: k,
  }));

  const updateForm = (field: keyof InviteEmployeeFormI, value: string) => {
    setForm({ ...form, [field]: value });
  };

  const toggleFormModified: ToggleFormModifiedT = (field) => {
    if (!formFieldsModified[field]) {
      setFormFieldsModified({ ...formFieldsModified, [field]: true });
    }
  };

  const validateForm = (forceValidation = false) => {
    const _error: any = {};
    const { role, email } = form;
    if (formFieldsModified["role"] || forceValidation)
      if (!role) {
        _error["role"] = translate(
          "dashboard.multiproduct.validate.isMandatory"
        );
      }

    if (formFieldsModified["email"] || forceValidation) {
      if (email) {
        const isValidEmail = email.match(REGEX["EMAIL"]);
        if (!isValidEmail) {
          _error["email"] = translate(
            "dashboard.multiproduct.validate.wrongEmail"
          );
        }
      } else {
        _error["email"] = translate(
          "dashboard.multiproduct.validate.isMandatory"
        );
      }
    }

    setFormError(_error);
    return _error;
  };

  const sendInvite = async () => {
    const errors = validateForm(true);
    const hasErrors = Object.keys(errors).length > 0;
    if (!hasErrors) {
      try {
        await brokerRepository.postEmployeeSendInvitation({
          rol: form.role,
          email: form.email,
          name: form.name,
          lastName: form.lastname,
        });
        fetchEmployees({});
        setInvitationSent(true);
      } catch (e) {
        const error = JSON.stringify(e);
        if (error.includes("EMAIL")) {
          setFormError({
            email: translate(
              "errors.employees.employeeBrokerEmailAlreadyExist"
            ),
          });
        }
      }
      // @to-do preparar el catch
    }
  };

  useEffect(() => {
    validateForm();
  }, [form, formFieldsModified]);

  useEffect(() => {
    if (open) {
      setForm(structuredClone(defaultForm));
      setFormFieldsModified({});
      setFormError({});
      setInvitationSent(false);
    }
  }, [open]);

  return {
    form,
    roleOptions,
    updateForm,
    toggleFormModified,
    formError,
    sendInvite,
    invitationSent,
  };
};

export default InviteEmployeeModalController;
