// eslint-disable-next-line import/no-anonymous-default-export
export default {
	types: { TEL: 'tel', EMAIL: 'email' },
	prefix_1: '+34',
	prefix_2: '0034',
	tel_regex: /^\d{9,9}$/,
	email_regex: /^[^@]+@[^@]+\.[^@]+$/,
	request_button_text: 'login.send_code',
	submit_button_text: 'login.enter',
	submit_p_text: 'login.submit_p_text',
	no_code_text: 'login.no_code_text',
	no_account_text: 'login.no_account_text',

	tel: {
		input: {
			label: 'login.label.mobile',
			placeholder: 'login.placeholder.mobile',
		},
		button_choose_better_text: 'login.phone.button_choose_better_text',
		p: 'login.phone.paragraph',
		error: 'PHONE NOT VALID',
	},
	email: {
		input: {
			label: 'login.label.email',
			placeholder: 'login.placeholder.email',
		},
		button_choose_better_text: 'login.email.button_choose_better_text',
		p: 'login.email.paragraph',
		error: 'EMAIL NOT VALID',
	},
	otp: {
		label: 'login.code'
	},
	logo_fill: '#104944',
};
