const REGEX = {
  PHONE: /^[ -]*(6|7|8|9)[ -]*([0-9][ -]*){8}$/,
  PHONEFR: /^0[1-7]\d{8}$/,
  PHONEIT: /^\+?(39|0039)?3\d{9}$/,
  PHONECOMPLETE: /\d{13}/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ZIPCODE: /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/,
};
export default REGEX;
