import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
} from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";

const CouponSelectorModal = ({
  open,
  onClose,
  validateCoupon,
  fetchPromo,
  promoLoading,
  selector = false,
}: any) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const [coupon, setCoupon] = useState<string>("");
  const [displaySelect, setDisplaySelect] = useState<boolean>(selector);

  const added = validateCoupon === "validate";

  useEffect(() => {
    if (open === true) {
      setCoupon("");
      setDisplaySelect(selector);
    }
  }, [open]);

  return (
    <ModalDS
      title={translate("dashboard.calculator.addCoupon")}
      icon="Tag2Icon"
      content={
        added ? (
          <div>
            <span className="p-[8px] bg-[#ECECFE] block rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                color="var(--primary-color)"
                size="XS"
              />
            </span>
            <p
              className="BodyM text-[#424242]"
              dangerouslySetInnerHTML={{
                __html: translate("dashboard.multiproduct.appliedCoupon"),
              }}
            />
          </div>
        ) : (
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: translate("coupon.form.text"),
              }}
              className="BodyM text-[#424242] mb-[16px]"
            />
            {displaySelect ? (
              <SelectDS
                label=""
                placeholder={translate("dashboard.multiproduct.selectACoupon")}
                options={JSON.parse(
                  translate("dashboard.multiproduct.availableCoupons")
                )}
                value={coupon}
                onChange={(e) => {
                  if (e.target.value !== "custom") {
                    setCoupon(e.target.value);
                    fetchPromo(e.target.value);
                  } else {
                    setDisplaySelect(false);
                  }
                }}
                disabled={promoLoading}
                error={
                  validateCoupon === "invalidate"
                    ? translate("dashboard.calculator.promoInvalidate")
                    : undefined
                }
              />
            ) : (
              <InputDS
                value={coupon}
                name="promoCode"
                icon="Tag2Icon"
                label={translate("dashboard.multiproduct.discountCoupon")}
                placeholder={translate(
                  "dashboard.multiproduct.discountCouponPlacerHolder"
                )}
                onChange={(e) => setCoupon(e.target.value)}
                error={
                  validateCoupon === "invalidate"
                    ? translate("dashboard.calculator.promoInvalidate")
                    : undefined
                }
                disabled={promoLoading}
              />
            )}
          </div>
        )
      }
      buttons={
        added ? undefined : (
          <ButtonDS
            label={translate("dashboard.multiproduct.applyCoupon")}
            buttonType="primary"
            className="w-fit ml-auto"
            onClick={() => {
              console.log("coupon", coupon);
              fetchPromo(coupon);
            }}
            disabled={promoLoading}
          />
        )
      }
      buttonsWrap={true}
      onClose={onClose}
      open={open}
    />
  );
};
export default CouponSelectorModal;
