import { useState, useContext } from "react";
import { IbanElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router";
import HttpTrackingRepository from "../../../api/request/Tracking/tracking.service";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { GUARANTEE, formatPriceTwoDecimals } from "../../../utils/staticData";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";
import apiCreateProfile from "../../../api/request/apiCreateProfile";
import apiConfigOfCustomer from "../../../api/request/apiConfigOfCustomer";
import apiFirstSyncProfile from "../../../api/request/apiFirstSyncProfile";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { ButtonDS, GlobalIcon } from "get-life-storybook-ts";

const SepaPayment = (props) => {
  const {
    leadId,
    token,
    brokerId,
    productCard,
    broker,
    translate,
    undoSelectedPaymentMethod
  } = props;

  const textButton = translate("payment.button.pay");
  const [loading, setLoading] = useState(false);
  const [ibanElementStatus, setIbanElementStatus] = useState({complete: false});
  const [ibanIncomplete, setIbanIncomplete] = useState(null);
  const [ibanError, setIbanError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const { handleTrackerQuestion } = useContext(GoogleContext);
  const navigate = useNavigate();

  const leadRepository = new HttpLeadRepository(token);
  const trackingRepository = new HttpTrackingRepository(token);

  const handleSubmit = async () => {
    await apiCreateProfile(token, leadId).then(async () => {
      await apiConfigOfCustomer(token, leadId).then(async (response) => {
        await confirmStripe(response);
        await apiFirstSyncProfile(token, leadId).then(async () => {
          if(response === "error") {
            setIbanError(true);
            return;
          }
          navigate("/confirmation");
        })
      });
    });
  };

  const confirmStripe = async (setupIntent) => {
    let eventDetails = [
      {
        leadId: leadId,
        paymentMethod: "SEPA",
        token: token,
        brokerEmployeeId: brokerId,
      },
    ];

    trackingRepository.add({ eventType: "PAYMENT_TRY", eventDetails });

    if (!stripe || !elements) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "SEPA",
          errorMessage: "INTENT_NOT_LOADED",
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const result = await stripe.confirmSepaDebitSetup(setupIntent.clientSecret, {
      payment_method: {
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: setupIntent.name + " " + setupIntent.lastName,
          email: setupIntent.email,
        },
      },
    });
    if (result.error) {
      eventDetails = [
        {
          leadId: leadId,
          paymentMethod: "SEPA",
          errorMessage: result.error,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({ eventType: "PAYMENT_ERROR", eventDetails });
      setLoading(false);
    } else {
      eventDetails = [
        {
          leadId: leadId,
          token: token,
          brokerEmployeeId: brokerId,
        },
      ];
      trackingRepository.add({
        eventType: "PAYMENT_SUCCESS",
        eventDetails,
      });

      await leadRepository.getPostStackData(leadId).then((async (leadResponse) => {
        const utms = await getTrackingUtms(leadId);
        const eventData = {
          page: window.location.href,
          leadId: leadId,
          calculatedCapital: parseInt(productCard.capital),
          coverage: GUARANTEE[productCard.insuranceType],
          calculatedPremium: parseFloat(productCard.monthlyPrice),
          intent: productCard.intention,
          paymentMethod: "sepa",
          brokerEmployeeId: brokerId,
          brokerId: broker.brokerageId,
          platform: "Broker",
          discountedPremium: productCard.promotion ? parseFloat(formatPriceTwoDecimals(productCard.promotion.price)) : parseFloat(formatPriceTwoDecimals(productCard.monthlyPrice)),
          paymentPeriod: productCard.period ? productCard.period : "monthly",
          policyId: leadId,
          ...utms
        }

        handleTrackerQuestion({
          type: "FS",
          event: "paymentMethodSet",
          data: {
            birthDate: leadResponse.lead.birthdate,
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "paymentMethodSet",
            birthDate: leadResponse.lead.birthdate,
            ...eventData
          },
        });

        handleTrackerQuestion({
          type: "FS",
          event: "policyPurchased",
          data: {
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "policyPurchased",
            birthDate: new Date(leadResponse.lead.birthdate),
            ...eventData
          },
        });
      }))
    }
  };

  const IBAN_STYLE = {
    base: {
      color: "#104944",
      fontSize: "14px",
      "::placeholder": {
        color: "#104944",
      },
      ":-webkit-autofill": {
        color: "#104944",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: process.env.REACT_APP_DOMAIN_LOCALE.toUpperCase(),
    style: IBAN_STYLE,
  };

  const onIbanElementChange = (e) => setIbanElementStatus(e);

  const onIbanElementBlur = () => {
    if(ibanElementStatus.error) return setIbanError(true);
    if(ibanElementStatus.complete === false) return setIbanIncomplete(true);
    setIbanIncomplete(false);
    setIbanError(false);
  }

  return (
    <div className="card-select-payment-type">
      <div className="subcontainer">
        <h5>{translate("payment.paymentMethod.sepa")}</h5>
        <div className="container-card">
          <IbanElement
            options={IBAN_ELEMENT_OPTIONS}
            onChange={(e) => {
              onIbanElementChange(e);
            }}
            onBlur={onIbanElementBlur}
          />
          {ibanError === true || ibanIncomplete === true ? (
              <p className="!text-[#E84B4B] flex flex-row gap-[8px] items-center">
                <GlobalIcon
                  size="XXS"
                  iconName="AlertCircleIcon"
                  color="currentColor"
                />
                <span className="BodyS">
                  {ibanError
                    ? translate("payment.paymentMethod.errorCard")
                    : ibanIncomplete
                    ? translate("payment.paymentMethod.incompletedCard")
                    : ""}
                </span>
              </p>
            ) : null}
        </div>
      </div>
      <div className="button-container-payment flex flex-col gap-[8px]">
        <ButtonDS
          label={textButton}
          onClick={handleSubmit}
          disabled={
            ibanError !== false || ibanIncomplete !== false || loading === true
              ? true
              : false
          }
          loading={loading}
        />
        <ButtonDS
          label={translate("payment.paymentMethod.changePaymentMethod")}
          buttonType="secondary"
          onClick={undoSelectedPaymentMethod}
        />
      </div>
    </div>
  );
};

export default SepaPayment;
