import * as React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";

import { ContextGetlife } from "../../../contexts/ContextGetlife";
import Typography from "../../Rebranding/atoms/Typography/Typography.component";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Notification = () => {
  const { showNotification, setShowNotification } =
    React.useContext(ContextGetlife);
  const anchorOrigin = showNotification.anchorOrigin || {
    vertical: "top",
    horizontal: "center",
  };
  const autoHideDuration = showNotification.autoHideDuration || 3000;

  const getMessageWithLink = () => {
    const { message, link } = showNotification;
    if (link) {
      return (
        <p>
          {message.substring(0, message.indexOf("$"))}
          {link}
          {message.substring(message.indexOf("$") + 1, message.length)}
        </p>
      );
    }
    return <Typography variant="paragraph" text={message} />;
  };
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={showNotification.status}
      autoHideDuration={autoHideDuration}
      onClose={() =>
        setShowNotification({ ...showNotification, status: false })
      }
    >
      <Alert
        onClose={() =>
          setShowNotification({ ...showNotification, status: false })
        }
        severity={showNotification && showNotification.type}
      >
        {showNotification && getMessageWithLink()}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
