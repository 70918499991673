import React, { useState } from 'react';
import InputGetLife from '../components/inputGetLife/InputGetLife';
import Checkbox from '../components/checkbox/Checkbox';
import ButtonPrimary from '../components/buttons/ButtonPrimary';
import Pencil from '../../assets/img/Pencil_icon.svg';
import OpenArrow from '../../assets/img/desp_icon open.svg';
import WhiteButton from '../components/buttons/WhiteButton';
import ModalTalkWithExpert from '../components/modalTalkWithAnExpert/ModalTalkWithAnExpert';
import Modal from '@material-ui/core/Modal';

import './registerEmployeeMain.scss';

export default function RegisterEmployeeMain(props) {
    const {
        name,
        lastName,
        personalPhone,
        workPhone,
        email,
        acceptTerms,
        handleName,
        handleLastName,
        handlePersonalPhone,
        handleWorkPhone,
        handleEmail,
        handleSubmit,
        handleAcceptTerms,
    } = props;
    const [openQuestion, setOpenQuestion] = useState({
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
        question7: false,
        question8: false,
        question9: false,
    });

    const allStates = [name, lastName, personalPhone, email, acceptTerms];

    const [openTalk, setOpenTalk] = useState(false);
    const handleOpenTalk = () => setOpenTalk(!openTalk);

    const isButtonDisabled = () => {
        let filtered = false;
        for (let i = 0; i <= allStates.length; i++) {
            if (allStates[i] === false || allStates[i] === '') {
                filtered = true;
            }
        }
        return filtered;
    };

    const textAcceptTerms = () => {
        return (
            <div className="form-labelCheckbox">
                <p>
                    Acepto los
                    <a
                        href="https://life5.es/politica-privacidad-resumida/"
                        target="_blank"
                        rel="noreferrer"
                        className="register--anchor"
                    >
                        <b> términos y condiciones</b>
                    </a>{' '}
                    y la
                    <a
                        href="https://life5.es/politica-privacidad-resumida/"
                        target="_blank"
                        rel="noreferrer"
                        className="register--anchor"
                    >
                        <b> LOPD</b>
                    </a>
                </p>
            </div>
        );
    };

    const handleOpenQuestion = (index, value) => {
        const prevValue = openQuestion;

        setOpenQuestion({ ...prevValue, [index]: value });
        let element = document.getElementById(index);
        if (value) {
            element.classList.add('rotate');
        } else {
            element.classList.remove('rotate');
        }
    };
    return (
        <main className="main">
            <div className="main--container registerEmployee">
                <div className="title">
                    <div className="title--container">
                        <img
                            src={Pencil}
                            alt="Símbolo de un lápiz"
                            className="img--title"
                        />
                        <h1 className="title--title">Regístrate con Life5</h1>
                    </div>

                    <h3 className="title--text">
                        Únete a la revolución de los seguros de vida. ¡Consigue
                        tu primer cliente en minutos!
                    </h3>
                </div>
                <div className="registerEmployee--form">
                    <div className="registerEmployee--form__input1">
                        <InputGetLife
                            id={'name'}
                            name={'name'}
                            label={'Nombre'}
                            contentType={'text'}
                            handleValues={handleName}
                            // error={showErrors('name') === true ? true : false}
                            answer={name !== false ? name : ''}
                        />
                    </div>
                    <div className="registerEmployee--form__input2">
                        <InputGetLife
                            id={'lastName'}
                            name={'lastName'}
                            label={'Apellidos'}
                            contentType={'text'}
                            handleValues={handleLastName}
                            answer={lastName !== false ? lastName : ''}
                            // error={showErrors('lastName') === true ? true : false}
                        />
                    </div>
                    <div className="registerEmployee--form__input3">
                        <InputGetLife
                            id={'phone'}
                            name={'phone'}
                            label={'Teléfono personal'}
                            contentType={'tel'}
                            handleValues={handlePersonalPhone}
                            answer={
                                personalPhone !== false ? personalPhone : ''
                            }
                            // error={showErrors('phone') === true ? true : false}
                        />
                    </div>
                    <p className="registerEmployee--form__text">
                        Te enviaremos los códigos de acceso a este número.
                        Asegúrate de que sea único
                    </p>
                    <div className="registerEmployee--form__input4">
                        <InputGetLife
                            id={'phoneWork'}
                            name={'phoneWork'}
                            label={'Teléfono de trabajo (Opcional)'}
                            contentType={'tel'}
                            handleValues={handleWorkPhone}
                            answer={workPhone !== false ? workPhone : ''}
                            // error={showErrors('workPhone') === true ? true : false}
                        />
                    </div>
                    <div className="registerEmployee--form__input5">
                        <InputGetLife
                            id={'email'}
                            name={'email'}
                            label={'Email de trabajo'}
                            contentType={'email'}
                            handleValues={handleEmail}
                            answer={email !== false ? email : ''}
                            // error={showErrors('email') === true ? true : false}
                        />
                    </div>
                    <p className="registerEmployee--form__text2">
                        Los clientes contactarán contigo en este email.
                    </p>
                    <div className="registerEmployee--form__divCheckbox">
                        <Checkbox
                            id={'termsConditions'}
                            type={'checkbox'}
                            label={textAcceptTerms()}
                            name={'options'}
                            handleValues={handleAcceptTerms}
                            answer={acceptTerms}
                        />
                    </div>
                    <div className="registerEmployee--form__formbutton">
                        <ButtonPrimary
                            content="Registrarme"
                            disabled={isButtonDisabled()}
                            onClick={handleSubmit}
                            width={'100%'}
                        />
                    </div>
                </div>

                <section className="questions">
                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question1',
                                !openQuestion.question1
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question1"
                        />
                        <h3 className="questions--title__text">
                            ¿Life5 ofrece solo seguros de vida?
                        </h3>
                    </div>
                    {openQuestion.question1 && (
                        <p className="questions--text">
                            Así es, actualmente Life5 solo ofrece la rama de
                            seguros de vida. Un producto moderno y completamente
                            renovado. Sin papeleos y sin permanencia.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question2',
                                !openQuestion.question2
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question2"
                        />
                        <h3 className="questions--title__text">
                            ¿Qué hace diferente el seguro de vida de Life5?
                        </h3>
                    </div>
                    {openQuestion.question2 && (
                        <p className="questions--text">
                            Life5 ofrece un seguro de vida que se puede
                            contratar al instante. Sin exámenes médicos, sin
                            papeleos, sin permanencia y con pago mensual sin
                            recargos. ¡Y olvídate de la telesuscpición!
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question3',
                                !openQuestion.question3
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question3"
                        />
                        <h3 className="questions--title__text">
                            ¿Necesita el cliente pasar algún reconocimiento
                            médico?
                        </h3>
                    </div>
                    {openQuestion.question3 && (
                        <p className="questions--text">
                            No es necesario pasar ningún reconocimiento médico
                            ni entregar informes. Con solo rellenar un pequeño
                            cuestionario de salud el cliente podrá estar
                            cubierto al instante.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question4',
                                !openQuestion.question4
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question4"
                        />
                        <h3 className="questions--title__text">
                            ¿Cómo se calcula el precio del seguro?
                        </h3>
                    </div>
                    {openQuestion.question4 && (
                        <p className="questions--text">
                            Con un sistema innovador, teniendo en cuenta las
                            respuestas del cuestionario.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question5',
                                !openQuestion.question5
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question5"
                        />
                        <h3 className="questions--title__text">
                            ¿Estará cubierto el cliente en caso de Covid 19?
                        </h3>
                    </div>
                    {openQuestion.question5 && (
                        <p className="questions--text">
                            El seguro de vida de Life5 cubre el siniestro
                            producido por el Covid 19 / coronavirus, siempre y
                            cuando el asegurado se haya contagiado después del
                            contrato y la activación de la póliza.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question6',
                                !openQuestion.question6
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question6"
                        />
                        <h3 className="questions--title__text">
                            ¿La póliza de Life5 incluye a motoristas?
                        </h3>
                    </div>
                    {openQuestion.question6 && (
                        <p className="questions--text">
                            Sí, el seguro de vida de Life5 incluye a
                            motoristas, sea cual sea la cilindrada de la moto.
                            Si eres motero, podrás contratar tu seguro de vida
                            sin problemas y estarás totalmente cubierto, ya sea
                            por fallecimiento natural o un accidente. Sin
                            embargo, el seguro de vida de Life5 no cubrirá el
                            fallecimiento a causa de un accidente de moto, si es
                            tu profesión o se trata de una competición, ya seas
                            profesional o amateur.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question7',
                                !openQuestion.question7
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question7"
                        />
                        <h3 className="questions--title__text">
                            ¿Quién está detrás de Life5?
                        </h3>
                    </div>
                    {openQuestion.question7 && (
                        <p className="questions--text">
                            Life5 está respaldado por aseguradoras de primer
                            nivel y un equipo de profesionales de renombre.
                            Además, tiene la confianza de Chris Adelsbach,
                            considerado el mejor Inversor Ángel en el Reino
                            Unido y fue acompañado por directivos de
                            aseguradoras internacionales, bancos y emprendedores
                            de las grandes empresas insurtech.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question8',
                                !openQuestion.question8
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question8"
                        />
                        <h3 className="questions--title__text">
                            ¿Cuáles son mis comisiones?
                        </h3>
                    </div>
                    {openQuestion.question8 && (
                        <p className="questions--text">
                            Los mediadores que colaboran con Life5 cuentan con
                            las sobrebcomisiones con el porcentaje más elevado
                            del mercado actual.
                        </p>
                    )}

                    <div
                        className="questions--title"
                        onClick={() =>
                            handleOpenQuestion(
                                'question9',
                                !openQuestion.question9
                            )
                        }
                    >
                        <img
                            src={OpenArrow}
                            alt="flecha que despliega una respuesta hacia abajo"
                            className="questions--title__img"
                            id="question9"
                        />
                        <h3 className="questions--title__text">
                            ¿Getlife cumple con la LOPD?
                        </h3>
                    </div>
                    {openQuestion.question9 && (
                        <p className="questions--text">
                            En virtud de lo establecido en el Reglamento (UE)
                            2016/679, del Parlamento Europeo y del Consejo de 27
                            de abril de 2016 relativo a la protección de las
                            personas físicas en lo que respecta al tratamiento
                            de Datos Personales y a la libre circulación de
                            estos Datos Personales (“RGPD”), así como la
                            normativa nacional de desarrollo. Puedes leer más
                            sobre esto en nuestra pagina de Política de
                            Privacidad.
                        </p>
                    )}
                    <div className="dashboarMain-buttonContainer">
                        {/* eslint-disable-next-line no-undef */}
                        <WhiteButton
                            content="Hablar con mi gestor"
                            handleOpenTalk={handleOpenTalk}
                        />
                        <Modal
                            open={openTalk}
                            onClose={() => handleOpenTalk()}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div
                                className="Modal__Wrapper"
                                style={{
                                    minHeight: '100vh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <ModalTalkWithExpert
                                    handleOpenTalk={handleOpenTalk}
                                />
                            </div>
                        </Modal>
                    </div>
                </section>
            </div>
        </main>
    );
}
