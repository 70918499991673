import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import LoginMain from "./LoginMain";
import "./css/loginPage.scss";
import HttpBrokerRepository from "../../api/request/Broker/broker.service";
import { handleLogin } from "../../utils/fetchBroker";
import { I18nContext } from "../../contexts/i18n.context";

export default function LoginPage() {
  const { setBroker, setBrokerId, setToken, setStatus, setShowNotification } =
    useContext(ContextGetlife);

    const {
      state: { translate },
    } = useContext(I18nContext);

  const [userEmail, setUserEmail] = useState("");

  const [userPassword, setUserPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const brokerRepository = new HttpBrokerRepository();

  const controlErrors = (e) => {
    setError(e);
    setShowNotification({
      message: e.message,
      status: true,
      type: "error",
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    await handleLogin({
      brokerRepository,
      controlErrors,
      navigate,
      setBroker,
      setBrokerId,
      setStatus,
      setToken,
      value: { username: userEmail, password: userPassword },
    });
  };

  const handleEmail = (e) => setUserEmail(e);

  const handlePassword = (e) => setUserPassword(e);

  return (
    <div>
      <LoginMain
        userEmail={userEmail}
        userPassword={userPassword}
        handleEmail={handleEmail}
        handlePassword={handlePassword}
        handleSubmit={handleSubmit}
        error={error}
        translate={translate}
      />
    </div>
  );
}
