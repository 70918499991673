export default function apiVelogicaLogin(leadId, token) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/${leadId}/velogica/login`,
		{
			headers: {
				Authorization: bearer,
			},
		}
	).then((response) => response.json());
}
