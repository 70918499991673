import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./../../../assets/img/logo.png";
import HappyFace from "./../../../assets/img/happyFace.png";
import "./resetDone.scss";

export default function ResetDone() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className="changePassword">
      <main className="changePassword--main">
        <img
          src="https://storage.googleapis.com/getlife-bucket-1/public/image/life5/Life_5_Logo_BG.svg"
          alt="Logo de Life5"
          title="Logo de Life5"
          className="changePassword--main__img"
        />
        <img
          src={HappyFace}
          alt="Carita guiñando un ojo"
          title="Cariña guiñando un ojo"
          className="changePassword--main__happyFace"
        />
        <h6 className="changePassword--main__title">
          Te hemos enviado la contraseña a tu mail.{" "}
          <strong>Entra en Life5</strong> pulsando en el siguiente enlace:
        </h6>
        <button className="changePassword--main__btn" onClick={handleClick}>
          Entrar en Life5
        </button>
      </main>
    </div>
  );
}
