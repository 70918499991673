import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiBrokerPost from "./../../api/request/apiBrokerPost";
import apiBrokerGet from "../../api/request/apiBrokerGet";
import RegisterEmployeeMain from "./RegisterEmployeeMain";
import { ContextGetlife } from "../../contexts/ContextGetlife";

export default function RegisterEmployee(props) {
  const navigate = useNavigate();

  const { brokerId, setBroker, setBrokerId, setToken, token } =
    useContext(ContextGetlife);

  const [name, setName] = useState(false);

  const [lastName, setLastName] = useState(false);

  const [personalPhone, setPersonalPhone] = useState(false);

  const [workPhone, setWorkPhone] = useState(false);

  const [email, setEmail] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleName = (e) => {
    setName(e);
  };

  const handleLastName = (e) => {
    setLastName(e);
  };

  const handlePersonalPhone = (e) => {
    setPersonalPhone(e);
  };

  const handleWorkPhone = (e) => {
    setWorkPhone(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handleAcceptTerms = () => setAcceptTerms(!acceptTerms);

  const handleSubmit = async (e) => {
    apiBrokerPost(email, name, lastName, personalPhone, workPhone).then(
      (response) => {
        const { brokerId, token } = response;

        setToken(token);
        setBrokerId(brokerId);
        apiBrokerGet(brokerId, token).then((resp) => {
          setBroker(resp);
        });
        navigate("/dashboard/welcome");
      }
    );
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <RegisterEmployeeMain
      brokerId={brokerId}
      token={token}
      acceptTerms={acceptTerms}
      email={email}
      handleAcceptTerms={handleAcceptTerms}
      handleEmail={handleEmail}
      handleLastName={handleLastName}
      handleName={handleName}
      handlePersonalPhone={handlePersonalPhone}
      handleSubmit={handleSubmit}
      handleWorkPhone={handleWorkPhone}
      lastName={lastName}
      name={name}
      personalPhone={personalPhone}
      workPhone={workPhone}
    />
  );
}
