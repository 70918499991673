import * as React from "react";

interface Props {
  initialValue: number;
  functionToExecute: () => void;
}

const useCountDown = ({ initialValue, functionToExecute }: Props): number => {
  const [countDown, setCountDown] = React.useState<number>(initialValue);
  React.useEffect(() => {
    const interval = setInterval(
      () => setCountDown((second: number) => second - 1),
      1000
    );
    if (countDown === 0) {
      clearInterval(interval);
      functionToExecute();
    }
    return () => clearInterval(interval);
  }, [countDown]);
  return countDown;
};

export default useCountDown;
