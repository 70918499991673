import React from "react";
import { Answers } from "../../../../api/request/Lead/Model/Response/NomenclatorResponse.model";
import { errorMessage } from "../../../../utils/error";
import AutocompleteGoogle from "../../../components/autcompleteGMaps/AutocompleteGoogle";
import SelectGeneral from "../../../components/SelectGeneral/SelectGeneral";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { AddressValues } from "../../pages/PostStack/PostStack.controller";
import { FormAddressProps } from "./FromInterface.model";
import { PredictiveSearch } from "get-life-storybook-ts";

const FormAddress = ({
  disabled,
  error,
  provinceList,
  setValue,
  setValues,
  translate,
  values,
}: FormAddressProps): JSX.Element => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  return (
    <>
      <AutocompleteGoogle
        answer={values["addressLine"]}
        disabled={disabled}
        error={errorMessage(error, "addressLine")}
        handleDirection={(form: AddressValues) => {
          const correctValue: any =
            typeof form.province === "string"
              ? form.province
              : form.province?.value;
          const correctProvince: string =
            correctValue === "Illes Balears" ? "Baleares" : correctValue;
          let province: Answers | undefined | string;
          if (DOMAIN === "es" || DOMAIN === "pt") {
            province = provinceList.find(
              (item) => item.value === correctProvince
            );
          } else {
            province = correctProvince;
          }
          if(DOMAIN === "it") province = "";
          setValues({ ...form, province });
        }}
        initialVal={values["addressLine"]}
        label={translate("global.address")}
        placeholder={"Ej. Calle de Santa Engracia 90"}
        translate={translate}
      />
      <div>
        {DOMAIN === "it" && (
          <InputGeneral
            editValue={values["number"]}
            label={translate("global.number")}
            setter={(newValue: string) => setValue("number", newValue)}
            name="number"
            placeholder={translate("poststack.placeholder.number")}
            disabled={disabled}
            error={errorMessage(error, "number")}
          />
        )}
      </div>
      <div></div>
      <InputGeneral
        editValue={values["zipcode"]}
        label={translate("global.zipCode")}
        setter={(newValue: string) => setValue("zipcode", newValue)}
        name="zipcode"
        type="number"
        placeholder={translate("poststack.placeholder.postalCode")}
        disabled={disabled}
        error={errorMessage(error, "zipcode")}
      />
      {DOMAIN === "it" ? (
        <PredictiveSearch
          label={translate("global.province")}
          initialError={errorMessage(error, "province")}
          initialValue={values["province"] ? values["province"].label : ""}
          onValueChange={(value: string) => setValue("province", value)}
          options={provinceList}
          placeholder={translate("global.placeholder.province")}
          sortOptionsByValue={true}
        />
      ) : DOMAIN === "es" || DOMAIN === "pt" ? (
        <SelectGeneral
          className=""
          isMultiple={false}
          errorType="error"
          label={translate("global.province")}
          editValue={values["province"]}
          error={errorMessage(error, "province")}
          handleValues={(newValue: Answers) => setValue("province", newValue)}
          placeholder={translate("global.placeholder.province")}
          toMap={provinceList}
          disabled={disabled}
          width={{ width: "41px" }}
        />
      ) : (
        <InputGeneral
          editValue={values["province"]}
          label={translate("global.province")}
          setter={(newValue: string) => setValue("province", newValue)}
          name="province"
          type="text"
          placeholder={translate("global.placeholder.province")}
          disabled={disabled}
          error={errorMessage(error, "province")}
        />
      )}
      <InputGeneral
        editValue={values["city"]}
        label={translate("global.city")}
        setter={(newValue: string) => setValue("city", newValue)}
        name="city"
        placeholder={translate("poststack.placeholder.city")}
        disabled={disabled}
        error={errorMessage(error, "city")}
      />
    </>
  );
};

export default FormAddress;
