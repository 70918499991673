import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  SelectDS,
} from "get-life-storybook-ts";
import EmployeeFormController from "./WelcomeEmployee.controller";
import "./WelcomeEmployee.styles.scss";
import { onEnterBlur } from "../../../../../utils/onEnterBlur";

const WelcomeEmployee = () => {
  const {
    open,
    setOpen,
    roleOptions,
    form,
    updateForm,
    toggleFormModified,
    formError,
    translate,
    fetch,
    loading,
    registerCompleted,
  } = EmployeeFormController();
  return (
    <ModalDS
      open={open}
      onClose={() => setOpen(false)}
      title={translate("employee.complete.modal.title")}
      icon="ClipboardListIcon"
      content={
        registerCompleted ? (
          <div>
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            <p className="BodyM text-[#424242] mt-[16px]">
              {translate("employee.complete.modal.register.completed")}
            </p>
          </div>
        ) : (
          <div className="flex flex-col gap-[24px]">
            <SelectDS
              label={`${translate("employee.invite.modal.role.name")}*`}
              placeholder={translate("employee.invite.modal.role.placeholder")}
              options={roleOptions}
              value={form.role}
              disabled={true}
            />
            <div className="grid grid-cols-2 gap-[24px] flex-1">
              <InputDS
                label={translate("employee.invite.modal.name.name")}
                placeholder={translate(
                  "employee.invite.modal.name.placeholder"
                )}
                value={form.name}
                onBlur={() => toggleFormModified("name")}
                onChange={(e) => updateForm("name", e.target.value)}
                onKeyDownCapture={onEnterBlur}
              />
              <InputDS
                label={translate("employee.invite.modal.surname.name")}
                placeholder={translate(
                  "employee.invite.modal.surname.placeholder"
                )}
                value={form.lastname}
                onBlur={() => toggleFormModified("lastname")}
                onChange={(e) => updateForm("lastname", e.target.value)}
                onKeyDownCapture={onEnterBlur}
              />
            </div>
            <InputDS
              label={`${translate("employee.complete.modal.email.name")}*`}
              placeholder={translate(
                "employee.complete.modal.email.placeholder"
              )}
              helper={translate("employee.complete.modal.email.helper")}
              icon="MailIcon"
              value={form.email}
              disabled={true}
            />
            <InputDS
              label={translate("employee.complete.modal.phone.name")}
              placeholder={translate(
                "employee.complete.modal.phone.placeholder"
              )}
              helper={translate("employee.complete.modal.phone.helper")}
              icon="DeviceMobile"
              value={form.phone}
              onBlur={() => toggleFormModified("phone")}
              onChange={(e) => updateForm("phone", e.target.value)}
              onKeyDownCapture={onEnterBlur}
              error={formError.phone}
            />

            <InputDS
              label={translate("employee.complete.modal.contactEmail.name")}
              placeholder={translate(
                "employee.complete.modal.contactEmail.placeholder"
              )}
              helper={translate("employee.complete.modal.contactEmail.helper")}
              icon="MailIcon"
              value={form.contactEmail}
              onBlur={() => toggleFormModified("contactEmail")}
              onChange={(e) => updateForm("contactEmail", e.target.value)}
              onKeyDownCapture={onEnterBlur}
              error={formError.contactEmail}
            />

            <InputDS
              label={translate("employee.complete.modal.contactPhone.name")}
              placeholder={translate(
                "employee.complete.modal.contactPhone.placeholder"
              )}
              helper={translate("employee.complete.modal.contactPhone.helper")}
              icon="DeviceMobile"
              value={form.contactPhone}
              onBlur={() => toggleFormModified("contactPhone")}
              onChange={(e) => updateForm("contactPhone", e.target.value)}
              onKeyDownCapture={onEnterBlur}
              error={formError.contactPhone}
            />
          </div>
        )
      }
      buttons={
        registerCompleted ? null : (
          <ButtonDS
            label={translate("employee.complete.modal.confirm")}
            rightIcon="ArrowRightIcon"
            className="w-[291px] max-w-full ml-auto"
            onClick={fetch}
            disabled={loading}
          />
        )
      }
      buttonsWrap={true}
      autoClose={registerCompleted}
    />
  );
};

export default WelcomeEmployee;
