export default function apiLeadPeriodUpdate(token, leadId, period) {
    const bearer = 'Bearer ' + token;
    return fetch(
        process.env.REACT_APP_CUSTOMER_APP_URL +
            `api/lead/period/update/${leadId}`,

        {
            method: 'POST',
            headers: {
                Authorization: bearer,
            },
            body: JSON.stringify({
                period: period,
            }),
        }
    ).then((response) => response.json());
}
