import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "@typeform/embed/build/css/widget.css";
import "./_welcomeQuestions.scss";
import { PopupButton } from "@typeform/embed-react";

import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../api/request/Broker/broker.service";

const Typeform = () => {
  const { brokerId, setShowNotification, setStatus, token } =
    useContext(ContextGetlife);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const response = await brokerRepository.patchQuestionnaire();
      setStatus(response.status);
      navigate("/dashboard/calculator");
    } catch (e) {
      setShowNotification({
        message: e.message,
        status: true,
        type: "error",
      });
    }
  };
  return (
    <PopupButton
      id="piTjJX5z"
      style={{ fontSize: 20 }}
      className="button-primary"
      onSubmit={handleSubmit}
      autoClose
    >
      Completar cuestionario
    </PopupButton>
  );
};

const WelcomeQuestions = () => {
  return (
    <main className="welcomeTypeform">
      <h1 className="welcomeTypeform--title">¡Bienvenido a Life5!</h1>
      <p className="welcomeTypeform--text">
        Necesitamos que cubras un breve cuestionario para poder redactar y
        completar tu contrato.
      </p>
      <div className="welcomeTypeform--buttons">
        <Typeform />
      </div>
    </main>
  );
};

export default WelcomeQuestions;
