import { useState } from "react";
import { errorMessage } from "../../../../utils/error";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { FormPersonalDataProps } from "./FromInterface.model";
import { Typography } from "get-life-storybook-ts";

const FormPersonalData = ({
  broker,
  emailDisabled,
  error,
  setValue,
  translate,
  values,
  disabled = false,
}: FormPersonalDataProps): JSX.Element => {

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const [isActiveRelatedEmail, setIsActiveRelatedEmail] = useState<boolean>(false);
  const ENVIROMENT = process.env.REACT_APP_ENVIROMENT;

  // const BROKER_FR = ((ENVIROMENT === "LOCAL" || ENVIROMENT === "DEV" || ENVIROMENT === "TEST") && (broker.brokerageId === 459)) || (ENVIROMENT === "PRD" && broker.brokerageId === 459);
  // const isGetlifeBroker = broker.brokerageId === 1 || BROKER_FR;
  const isGetlifeBroker = true;

  return (
    <>
      <InputGeneral
        disabled={disabled}
        editValue={values["name"]}
        label={translate("global.name")}
        setter={(newValue: string) => setValue("name", newValue)}
        name="name"
        error={errorMessage(error, "name")}
      />

      <InputGeneral
        disabled={disabled}
        editValue={values["lastName"]}
        label={translate("global.lastName1")}
        setter={(newValue: string) => setValue("lastName", newValue)}
        name="lastName"
        error={errorMessage(error, "lastName")}
      />

      {(DOMAIN === "es" || DOMAIN === "pt" || DOMAIN === "it") && <InputGeneral
        disabled={disabled}
        editValue={values["lastName2"]}
        label={translate("global.lastName2")}
        setter={(newValue: string) => setValue("lastName2", newValue)}
        name="lastName2"
        error={errorMessage(error, "lastName2")}
      />}

      <div>
        <InputGeneral
          disabled={disabled || emailDisabled}
          readOnly={emailDisabled}
          editValue={values["email"]}
          label={translate("global.email")}
          setterOnBlur={(newValue: string) => {
            setValue("email", newValue);
          }}
          name="email"
          error={errorMessage(error, "email")}
        />
        {isGetlifeBroker && !isActiveRelatedEmail && !values["relatedEmail"] && <Typography
          weight="700"
          variant="LinkSmall"
          text={translate("calculator.button.relatedEmail")}
          className="pointer"
          onClick={() => setIsActiveRelatedEmail(true)}
        />}
      </div>
       {
        (isActiveRelatedEmail || values["relatedEmail"]) && <InputGeneral
        editValue={values["relatedEmail"]}
        label={translate("calculator.label.emailRelated")}
        placeholder={translate("calculator.placeholder.emailRelated")}
        setterOnBlur={(newValue: string) => {
          setValue("relatedEmail", newValue);
        }}
        name="relatedEmail"
        error={errorMessage(error, "email")}
      />
       }
      <InputGeneral
        disabled={disabled}
        editValue={values["phone"]}
        type="number"
        label={translate("global.phone")}
        setterOnBlur={(newValue: string) => setValue("phone", newValue)}
        name="phone"
        error={errorMessage(error, "phone")}
      />
    </>
  );
};

export default FormPersonalData;
