export default function apiCreateProfile(token, leadId) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/create-profile-in-payment-provider/${leadId}`,

		{
			method: 'POST',
			headers: {
				Authorization: bearer,
			},
		}
	).then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			return 'error';
		}
	});
}
