import { useNavigate } from "react-router-dom";
import useCountDown from "../../../hooks/useCountDown.hook";
import "./NotFound.styles.scss";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import { useContext, useEffect } from "react";

const NotFound = () => {

  const {
    setBroker,
    setBrokerId,
    setStatus,
    setToken,
  } = useContext(ContextGetlife);

  const navigate = useNavigate();
  const countDown = useCountDown({
    initialValue: 5,
    functionToExecute: () => navigate("/login"),
  });

  const logout = (error) => {
    setBroker(undefined);
    setBrokerId("");
    setToken("");
    setStatus("");
    navigate("/login");
  };

  useEffect(() => {
    logout();
  }, [])

  return (
    <>
      <div class="mainbox_notFound ">
        <h1 className="title_notFound">Ups...</h1>
      </div>
      <div class="msg_notFound ">
        <p>¡Esta página no existe, ha cambiado de sitio o no tienes acceso!</p>
        <p>Será redirigido a la página de inicio en {countDown}</p>
      </div>
    </>
  );
};

export default NotFound;
