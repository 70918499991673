import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "./contexts/ContextGetlife";
import { GoogleProvider } from "./contexts/GoogleTagManager.context";
import { WithI18n } from "./contexts/i18n.context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
    <WithI18n>
      <GoogleProvider>
        <Provider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </GoogleProvider>
    </WithI18n>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
