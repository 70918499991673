import {
  ButtonDS,
  CheckboxButton,
  GlobalIcon,
  IconNameT,
  TooltipHover,
} from "get-life-storybook-ts";
import {
  StageFilterI,
  StagesFilter,
  BiometryFilter,
} from "../constants/stages";
import { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../../../contexts/i18n.context";

const FilterPopup = ({
  label,
  savedStage,
  filterByFilters,
  filterByStage,
  filterByBiometry,
}: {
  label: string;
  savedStage: string;
  filterByFilters: (filters: string) => void;
  filterByStage: (stage: string) => void;
  filterByBiometry: (stage: string) => void;
}) => {
  const [filters, setFilters] = useState(structuredClone(StagesFilter));
  const [filtersBiometry, setFiltersBiometry] = useState(
    structuredClone(BiometryFilter)
  );
  const [filtersPopup, setFiltersPopup] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const popupRef = useRef<HTMLDivElement>(null);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const toggleFilter = (value: string) => {
    const _filters = structuredClone(filters);
    const filter = _filters.find((f) => f.value === value);
    if (filter) {
      filter.active = !filter?.active;
      setFilters(_filters);
    }
  };

  const toggleFilterBiometry = (value: string) => {
    const _filters = structuredClone(filtersBiometry);
    const filter = _filters.find((f) => f.value === value);
    if (filter) {
      filter.active = !filter?.active;
      setFiltersBiometry(_filters);
    }
  };

  const isAnyFilterActive = () =>
    filters?.some((f) => f.active) || filtersBiometry?.some((f) => f.active);

  const applyFilters = (
    appliedFilters: StageFilterI[],
    appliedFiltersBiometry: StageFilterI[],
    closeModal: boolean = true
  ) => {
    const activeFilters: string[] = [];
    const activeFiltersBiometry: string[] = [];
    appliedFilters.forEach((f) => {
      if (f.active) activeFilters.push(f.value);
    });

    appliedFiltersBiometry.forEach((f) => {
      if (f.active) activeFiltersBiometry.push(f.value);
    });

    if (closeModal) {
      setFiltersPopup(false);
    }
    if (activeFilters.length === 0 && activeFiltersBiometry.length === 0) {
      return filterByFilters("");
    }
    if (activeFilters.length > 0) {
      filterByFilters(activeFilters.join(","));
    }

    if (activeFiltersBiometry.length > 0) {
      filterByBiometry(activeFiltersBiometry.join(","));
    }
  };

  const isFilterVisible = (filter: string) => {
    if (savedStage === "") return true;
    const _filter = filter.split(",")[0];
    if (savedStage.includes(_filter)) {
      return true;
    }
    return false;
  };

  const removeAndApplyFilters = () => {
    const _filters = filters.map((f) => {
      return { ...f, active: false };
    });

    const _filtersBiometry = filtersBiometry.map((f) => {
      return { ...f, active: false };
    });

    filterByStage("");
    setFiltersApplied(false);
    setFilters(_filters);
    setFiltersBiometry(_filtersBiometry);
    applyFilters(_filters, _filtersBiometry, false);
  };

  useEffect(() => {
    setFiltersApplied(false);
    const newStage = StagesFilter.map((stage) => {
      if (savedStage.includes(stage.value)) {
        stage.active = true;
      } else {
        stage.active = false;
      }
      return stage;
    });
    setFilters(newStage);
  }, [savedStage]);

  useEffect(() => {
    const onClickOutsideRangeClosePopup = (e: MouseEvent) => {
      if (filtersPopup === true) {
        if (!popupRef.current?.contains(e.target as Node)) {
          setFiltersPopup(false);
        }
      }
    };
    document.addEventListener("mousedown", onClickOutsideRangeClosePopup);
    return () =>
      document.removeEventListener("mousedown", onClickOutsideRangeClosePopup);
  }, [filtersPopup]);

  return (
    <div className="relative cursor-pointer" ref={popupRef}>
      <button
        className="tableHeader flex items-center BodyS font-bold uppercase whitespace-nowrap w-full"
        onClick={() => setFiltersPopup(!filtersPopup)}
      >
        {label}
        {filtersPopup ? (
          <GlobalIcon
            iconName="ChevronDown"
            size="XS"
            color="#555555"
            className="ml-auto bg-[#ECECEC] rounded-[4px]"
          />
        ) : filtersApplied ? (
          <button
            className="normal-case ml-auto"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeAndApplyFilters();
            }}
          >
            <TooltipHover
              content={translate("projects.filter.popup.removeFilter")}
              direction="bottom center"
            >
              <GlobalIcon
                iconName="FilterIcon"
                size="XS"
                color="#555555"
                className="bg-[rgb(236,236,236)] rounded-[4px] inline-block"
              />
            </TooltipHover>
          </button>
        ) : (
          <GlobalIcon
            iconName="ChevronDown"
            size="XS"
            color="#FFFFFF"
            className="ml-auto"
          />
        )}
      </button>

      <div
        className="absolute bottom-[8px] right-[40px] bg-[#FFF] border border-[#D9D9D9] rounded-[12px] text-[#555555] py-[4px] normal-case z-10 w-[268px] max-w-[268px]"
        style={{
          transform: "translateY(100%) translateX(100%)",
          boxShadow:
            "0px 11px 15px 0px rgba(0, 0, 0, 0.10), 0px 9px 46px 0px rgba(0, 0, 0, 0.06), 0px 24px 38px 0px rgba(0, 0, 0, 0.07)",
          display: filtersPopup ? undefined : "none",
        }}
      >
        {!savedStage.includes("complete") && (
          <div className="border-b-[1px] border-[#D9D9D9]">
            {filtersBiometry.map((s, idx) => {
              const { label, icon, value, active } = s;
              return (
                <button
                  className="p-[12px_12px] flex flex-row items-start gap-[8px] w-full"
                  key={`${label}-${idx}`}
                  style={{
                    color:
                      value === "id_pending"
                        ? "#775F01"
                        : value === "id_review"
                        ? "#4945E6"
                        : "#E53737",
                  }}
                  onClick={() => toggleFilterBiometry(value)}
                  disabled={
                    filters.find((filter) => filter.active === true)
                      ? true
                      : false
                  }
                >
                  <GlobalIcon
                    color="currentColor"
                    size="XS"
                    iconName={icon as IconNameT}
                  />
                  <span className="BodyM font-medium flex-1 text-left">
                    {translate(label)}
                  </span>
                  <CheckboxButton
                    variant="SimpleCheck"
                    checked={active}
                    value={""}
                    disabled={
                      filters.find((filter) => filter.active === true)
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    // @ts-ignore comment
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    label={""}
                  />
                </button>
              );
            })}
          </div>
        )}
        {filters.map((s, idx) => {
          const { label, icon, value, active } = s;
          if (!isFilterVisible(value)) return null;
          return (
            <button
              className="p-[12px_12px] flex flex-row items-start gap-[8px] w-full"
              key={`${label}-${idx}`}
              style={{ color: value === "complete" ? "#018565" : undefined }}
              onClick={() => toggleFilter(value)}
              disabled={
                filtersBiometry.find((filter) => filter.active === true)
                  ? true
                  : false
              }
            >
              <GlobalIcon
                color="currentColor"
                size="XS"
                iconName={icon as IconNameT}
              />
              <span className="BodyM font-medium flex-1 text-left">
                {translate(label)}
              </span>
              <CheckboxButton
                variant="SimpleCheck"
                checked={active}
                value={""}
                disabled={
                  filtersBiometry.find((filter) => filter.active === true)
                    ? true
                    : false
                }
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                // @ts-ignore comment
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                label={""}
              />
            </button>
          );
        })}
        <div className="p-3 flex flex-row items-center gap-[16px] border-t border-[#D9D9D9]">
          <ButtonDS
            label={translate("projects.filter.popup.cleanFilter")}
            buttonType="tertiary"
            className="flex-1"
            size="32"
            onClick={() => removeAndApplyFilters()}
          />
          <ButtonDS
            label={
              !isAnyFilterActive()
                ? translate("projects.filter.popup.showAll")
                : translate("projects.filter.popup.filter")
            }
            className="flex-1"
            size="32"
            onClick={() => {
              applyFilters(filters, filtersBiometry);
              setFiltersApplied(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;
