import { ButtonDS, GlobalIcon, TooltipHover } from "get-life-storybook-ts";
import { Employee } from "../../../../api/request/Lists/Model/Request/ListRequest.model";

export const renderActions = (
  translate: (key: string) => string,
  showEditEmployee: (brokerUlid: string) => void,
  employee: Employee,
  setShowDeleteModal: (v: string) => void
) => {
  return (
    <div className="relative group">
      <ButtonDS
        label={translate("tables.actions")}
        rightIcon="ClickIcon"
        buttonType="secondary"
        size="32"
      />
      <div
        className="hidden group-hover:flex absolute top-[calc(100%_-_4px)] right-0 z-10 bg-white flex-col rounded-xl"
        style={{
          boxShadow:
            "0px -1px 0px 0px rgba(66, 66, 66, 0.10) inset, 0px 4px 8px 0px rgba(66, 66, 66, 0.10)",
        }}
      >
        <TooltipHover
          offsetY={-12}
          content={
            employee.status === "guested"
              ? translate("employees.list.button.edit.disabled")
              : ""
          }
          direction="bottom left"
        >
          <div
            className="flex flex-row gap-[8px] py-[12px] px-[16px] rounded-t-xl hover:bg-[#f3f5f9]"
            onClick={() => {
              if (employee.status === "guested") return;
              showEditEmployee(employee.ulid);
            }}
            style={{
              cursor: employee.status === "guested" ? "not-allowed" : "pointer",
            }}
          >
            <GlobalIcon
              iconName="PencilIcon"
              color={employee.status === "guested" ? "#A0A0A0" : "#555555"}
              className={employee.status === "guested" ? "" : "pointer"}
              size="XS"
            />
            <span
              className="flex-1 BodyM font-medium whitespace-nowrap"
              style={{
                color: employee.status === "guested" ? "#A0A0A0" : "#555555",
              }}
            >
              {translate("employees.list.button.edit")}
            </span>
          </div>
        </TooltipHover>
        <div
          className="flex flex-row gap-[8px] py-[12px] px-[16px] rounded-b-xl hover:bg-[#f3f5f9] text-[#EA5F5F]"
          onClick={() => setShowDeleteModal(employee.ulid)}
          style={{ cursor: "pointer" }}
        >
          <GlobalIcon iconName="TrashXIcon" color={"currentColor"} size="XS" />
          <span className="flex-1 BodyM font-medium whitespace-nowrap">
            {translate("employees.list.button.delete")}
          </span>
        </div>
      </div>
    </div>
  );
};
