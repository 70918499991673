import React from "react";
import { errorMessage } from "../../../../../utils/error";
import CrossIcon from "../../../atoms/Icons/CrossIcon";
import TickIcon from "../../../atoms/Icons/TickIcon";
import InfoMessage from "../../../atoms/InfoMessage/InfoMessage.component";
import { ValidateCouponT } from "../../../pages/Checkpoint/CheckPoint.controller";
import CouponModalController, {
  CouponModalControllerI,
} from "./CouponModal.controller";

import "./CouponModal.styles.scss";
import { ButtonDS, InputDS, ModalDS } from "get-life-storybook-ts";

interface CouponModalI extends CouponModalControllerI {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  translate: (key: string) => string;
  validateCoupon?: ValidateCouponT;
}

const CouponModal: React.FC<CouponModalI> = ({
  couponCode,
  fetchPromo,
  showModal,
  setShowModal,
  translate,
  validateCoupon,
}): JSX.Element => {
  const { disabled, error, handleSubmit, setDisabled, setValue, values } =
    CouponModalController({ couponCode, fetchPromo });
  return (
    <ModalDS
      open={showModal}
      onClose={() => setShowModal(false)}
      icon="Tag2Icon"
      title={translate("coupon.title")}
      content={
        <>
          <div className="couponModal__body flex flex-col gap-4">
            <span className="BodyM font-regular text-[#424242]">
              {translate("coupon.form.text")}
            </span>
          <InputDS
            label={translate("coupon.form.couponCode")}
            icon={"Tag2Icon"}
            error={errorMessage(error, "bankName")}
            placeholder={translate("checkpoint.couponPlaceholder")}
            onChange={(newValue) => {setValue("couponCode", newValue); setDisabled(!!newValue)}}
          />
          {!!validateCoupon && validateCoupon !== "error" && (
            <InfoMessage
              icon={
                validateCoupon === "validate" ? <TickIcon /> : <CrossIcon />
              }
              className="couponModal__body--message"
              message={translate(`coupon.message.${validateCoupon}`)}
              type={validateCoupon === "validate" ? "info" : "error"}
            />
          )}
        </div>
        </>
      }
      buttons={
        <>
          <ButtonDS onClick={() => handleSubmit()} label={translate(`coupon.buttonsDS.addCoupon`)} disabled={!disabled} size="40" className="invisible"/>
          <ButtonDS onClick={() => handleSubmit()} label={translate(`coupon.buttonsDS.addCoupon`)} disabled={!disabled} size="40" />
        </>
      }
    />
    
  );
};

export default CouponModal;
