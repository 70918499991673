import React from "react";
import { IconProps } from "./IconProps.interface";
import "./Icon.styles.scss";

const PlusIcon: React.FC<IconProps> = ({
  width = "25",
  height = "25",
  color = "#424242",
  colorPath = "white",
  className,
  disabled,
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      className={`${disabled && "disabled"} ${className}`}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H19C22.3137 0 25 2.68629 25 6V19C25 22.3137 22.3137 25 19 25H6C2.68629 25 0 22.3137 0 19V6Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9489 5C12.4572 5 12.0601 5.39712 12.0601 5.88882L12.0538 12.0475H5.88882C5.39712 12.0475 5 12.4447 5 12.9363C5 13.428 5.39712 13.8251 5.88882 13.8251H12.0538V19.9901C12.0538 20.4818 12.4509 20.8789 12.9426 20.8789C13.4343 20.8789 13.8314 20.4818 13.8314 19.9901V13.8251H19.9964C20.4881 13.8251 20.8853 13.428 20.8853 12.9363C20.8853 12.4447 20.4881 12.0475 19.9964 12.0475H13.8314V5.88251C13.8314 5.40343 13.428 5 12.9489 5Z"
        fill={colorPath}
      />
    </svg>
  );
};

export default PlusIcon;
