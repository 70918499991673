export default function apiBrokerPost(
	email,
	brokerageName,
	name,
	lastName,
	personalPhone,
	workPhone,
	idDgsfp,
	idCard
) {
	idDgsfp = idDgsfp.replace(/[^\w]/g, '');
	if (workPhone === false) {
		workPhone = null;
	}
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/broker`,

		{
			method: 'POST',

			body: JSON.stringify({
				email: email,
				brokerageName: brokerageName,
				name: name,
				lastName: lastName,
				personalPhone: personalPhone,
				workPhone: workPhone,
				idDgsfp: idDgsfp,
				idCard: idCard,
			}),
		}
	).then((response) => response.json());
}
