import BrokerGeneralResponse from "../api/request/Broker/Model/Response/BrokerGeneralResponse.model";
import posthog from 'posthog-js'

interface fetchBrokerProps {
  brokerId: string;
  brokerRepository: any;
  navigate: any;
  setBroker: (broker: BrokerGeneralResponse) => void;
  setStatus: (status: string) => void;
  token: string;
}

interface handleLoginProps {
  brokerRepository: any;
  controlErrors: (e: any) => void;
  navigate: any;
  otp: boolean;
  setBroker: (broker: BrokerGeneralResponse) => void;
  setBrokerId: (id: string) => void;
  setStatus: (status: string) => void;
  setToken: (status: string) => void;
  token: string;
  value: string | { username: string; password: string };
}

export const fetchBroker = async ({
  brokerId,
  brokerRepository,
  navigate,
  setBroker,
  setStatus,
  token,
}: fetchBrokerProps): Promise<void> => {
  const response = await brokerRepository.getBroker(token);
  setStatus(response.status || "");
  setBroker(response);
  navigate("/dashboard/calculator");
};

export const handleLogin = async ({
  brokerRepository,
  controlErrors,
  navigate,
  otp,
  setBroker,
  setBrokerId,
  setStatus,
  setToken,
  value,
}: handleLoginProps): Promise<void> => {
  const repository = otp
    ? brokerRepository.loginSubmitOtp(value)
    : brokerRepository.login(value);
  try {
    const response = await repository;
    const { id, token, email } = response;
    setBrokerId(id || "");
    setToken(token || "");
    posthog.identify(
      id, // Required. Replace 'distinct_id' with your user's unique identifier
      { email: email },  // $set, optional
    );
  
    await fetchBroker({
      brokerId: id,
      brokerRepository,
      navigate,
      setBroker,
      setStatus,
      token,
    });
  } catch (e: any) {
    controlErrors(e);
  }
};
