// Vendors4
import { useRef, createRef, Fragment } from 'react';
// Handlers
import InputOtpComponentHandlers from './handlers/input-otp.component.handlers';
// Styles
import './input-otp.component.scss';

const InputOtp = ({ error, setError, setValue, value }) => {
	const arr = ['digit1', 'digit2', 'digit3', 'digit4', 'digit5', 'digit6'];
	const inputRefs = useRef([]);
	const { handleChange, handleKeyDown, handlePaste } = InputOtpComponentHandlers(inputRefs, setError, setValue);

	inputRefs.current = Array(arr.length)
		.fill()
		.map((_, i) => inputRefs.current[i] || createRef());

	return (
		<>
			<div className="digit-group">
				{arr.map((name, position) => (
					<Fragment key={'fragment' + position}>
						<input
							key={'input' + position}
							type="text"
							ref={inputRefs.current[position]}
							name={name}
							maxLength="1"
							onChange={(e) => {
								handleChange(e, position);
							}}
							onKeyDown={(e) => {
								handleKeyDown(e, position);
							}}
							onPaste={(e) => {
								handlePaste(e, position);
							}}
						/>
						{position < arr.length - 1 && (
							<span key={'splitter' + position} className="splitter">
								&ndash;
							</span>
						)}
					</Fragment>
				))}
			</div>
		</>
	);
};

export default InputOtp;
