import { ButtonDS, ModalDS } from "get-life-storybook-ts";
import { useContext } from "react";
import { I18nContext } from "../../contexts/i18n.context";
import { ContextGetlife } from "../../contexts/ContextGetlife";
interface ViewProjectI {
  otpUrl: string;
  otpModal: boolean;
  setOtpModal: (value: boolean) => void;
}

const OtpModal = ({ otpUrl, otpModal, setOtpModal }: ViewProjectI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { setShowNotification } = useContext(ContextGetlife);

  const onClose = () => {
    setOtpModal(false);
  };

  const copyOtpUrl = () => {
    navigator.clipboard.writeText(otpUrl);
    setShowNotification({
      message: translate("dashboard.infoCards.shareModal.copied"),
      type: "success",
      status: true,
    });
  };

  return (
    <>
      <ModalDS
        open={otpModal}
        onClose={onClose}
        icon="LinkToIcon"
        title={translate("modal.otp.title")}
        content={
          <>
            <p
              className="BodyM font-normal text-[#424242] mb-[8px] list-decimal"
              dangerouslySetInnerHTML={{
                __html: translate("edit.projects.otp"),
              }}
            />
            {otpUrl ? (
              <button
                onClick={copyOtpUrl}
                className="text-[blue] font-bold underline"
              >
                {otpUrl}
              </button>
            ) : null}
          </>
        }
        buttons={
          <>
            <ButtonDS
              label={translate("edit.projects.goBack")}
              buttonType="secondary"
              leftIcon="ArrowLeft"
              onClick={onClose}
            />
            <ButtonDS
              label={translate("edit.project.copyLink")}
              onClick={copyOtpUrl}
              rightIcon="LinkIcon"
            />
          </>
        }
      />
    </>
  );
};

export default OtpModal;
