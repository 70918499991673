import React, { ReactNode } from "react";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label: string | ReactNode;
}

const Label: React.FC<LabelProps> = ({
  label,
  className,
  htmlFor,
}): JSX.Element => {
  return (
    <label className={className} htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default Label;
