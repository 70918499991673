import { post } from "../../core/HTTPService.service";
import CouponsRequest, { CouponsRemove } from "./Request/CouponsRequest";
import CouponsCheckResponse from "./Response/CouponsCheck.response";

export default class HttpCouponsRepository {
  private readonly API_URL_PROMO:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/promo`;

  private readonly header: { Authorization: string } | undefined;
  private readonly leadId: string;

  constructor(leadId: string, token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
    this.leadId = leadId;
  }

  checkPromotionalCode = async ({
    promotionalCode,
    yearlyPrice,
  }: CouponsRequest): Promise<CouponsCheckResponse> => {
    let infoLead: CouponsCheckResponse;
    try {
      const response: CouponsCheckResponse = await post(
        `${this.API_URL_PROMO}/check/${this.leadId}`,
        { promotional_code: promotionalCode, yearly_price: yearlyPrice },
        this.header
      );
      infoLead = response;
    } catch (error) {
      throw error;
    }
    return infoLead;
  };

  removeCoupon = async ({ promotionalCode }: CouponsRemove) => {
    try {
      return await post(
        `${this.API_URL_PROMO}/remove/${this.leadId}`,
        { promotional_code: promotionalCode },
        this.header
      );
    } catch (error) {
      throw error;
    }
  };

  applyCoupon = async ({ promotionalCode }: CouponsRemove) => {
    try {
      return await post(
        `${this.API_URL_PROMO}/apply/${this.leadId}`,
        { promotional_code: promotionalCode },
        this.header
      );
    } catch (error) {
      throw error;
    }
  };
}
