import {
  ButtonDS,
  GlobalIcon,
  ModalDS,
  RadioButton,
} from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import PhotoIDDS, { PhotoIDDSTranslationsT } from "./PhotoIDDS";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import {
  GetLeadDocumentationI,
  LeadDocumentationTypeT,
} from "../../../api/request/Lead/Model/Request/GetLeadDocumentation.model";
import { formatPhone } from "../../components/TableLife5Projects/Utils/formatPhone";

interface IdentificationModalI {
  open: boolean;
  setOpen: (v: boolean) => void;
  modalData: {
    leadId: string;
  };
  fetchProjects: ({
    stage,
    startD,
    omniSearch,
  }: {
    stage?: string;
    startD?: string;
    omniSearch?: string;
  }) => Promise<void>;
}

const IdentificationModal = ({
  open,
  setOpen,
  modalData,
  fetchProjects,
}: IdentificationModalI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { token } = useContext(ContextGetlife);

  const [frontal, setFrontal] = useState("");
  const [back, setBack] = useState("");
  const [option, setOption] = useState<LeadDocumentationTypeT>("UNKNOWN");
  const [documentation, setDocumentation] = useState<GetLeadDocumentationI>(
    {} as GetLeadDocumentationI
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const leadRepository = new HttpLeadRepository(token);

  const uploadImageToServer = async (type: string, base64: string) => {
    setIsLoading(true);
    await leadRepository.postLeadDocumentationUploadImage(modalData.leadId, {
      leadId: modalData.leadId,
      content: base64,
      type,
    });
    await fetchDocumentation();
    setIsLoading(false);
  };

  const deleteFile = async (type: string) => {
    setIsLoading(true);
    await leadRepository.deleteLeadDocumentationDeleteImage(
      modalData.leadId,
      type
    );
    if (type === "FRONT") setFrontal("");
    if (type === "BACK") setBack("");
    await fetchDocumentation();
    setIsLoading(false);
  };

  const toggleDocumentType = async (type: LeadDocumentationTypeT) => {
    setIsLoading(true);
    if (option === "UNKNOWN") {
      await leadRepository.postLeadDocumentationDocumentationType(
        modalData.leadId,
        type
      );
    } else {
      await leadRepository.putLeadDocumentationDocumentationType(
        modalData.leadId,
        type
      );
    }
    setIsLoading(false);
    setOption(type);
  };

  const fetchDocumentation = async () => {
    const response = await leadRepository.getLeadDocumentation(
      modalData.leadId
    );
    if (documentation.globalStatus) {
      console.log(documentation.globalStatus, "!==", response?.globalStatus);
      if (documentation.globalStatus !== response?.globalStatus) {
        fetchProjects({});
      }
    }
    setDocumentation(response as GetLeadDocumentationI);
    setOption(response?.documentationType as LeadDocumentationTypeT);
    response?.documents?.forEach((d) => {
      const { type, remoteUrl } = d;
      if (type === "FRONT") setFrontal(remoteUrl);
      if (type === "BACK") setBack(remoteUrl);
    });
  };

  const sendToReview = async () => {
    setIsLoading(true);
    await leadRepository.postLeadDocumentationSave(modalData.leadId);
    await fetchDocumentation();
    setIsSaved(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (open) {
      setFrontal("");
      setBack("");
      setDocumentation({} as GetLeadDocumentationI);
      setIsSaved(false);
    }
  }, [open]);

  useEffect(() => {
    if (modalData.leadId) {
      fetchDocumentation();
    }
  }, [modalData]);

  const fileTranslations = {
    validations: translate("identification.modal.validations"),
    uploading: translate("identification.modal.uploading"),
    loading: translate("identification.modal.loading"),
    completed: translate("identification.modal.completed"),
    remove: translate("identification.modal.remove"),
    see: translate("identification.modal.see"),
    cancel: translate("identification.modal.cancel"),
    delete: translate("identification.modal.delete"),
    deleteConfirmation: translate("identification.modal.deleteConfirmation"),
    errorFormat: translate("identification.modal.error.format"),
    errorSize: translate("identification.modal.error.size"),
  };

  const isRadioDisabled = !!(frontal || back) || isLoading;

  const hasDocumentation = Object.keys(documentation).length > 0;

  const globalStatus = documentation.globalStatus;

  const isSaveDisabled = ((): boolean => {
    if (option === "PA" && frontal) {
      return false;
    } else if (frontal && back) {
      return false;
    }
    return true;
  })();

  return (
    <ModalDS
      open={open}
      onClose={() => setOpen(false)}
      title={translate("tables.actions.identification")}
      content={
        isSaved ? (
          <div className="BodyM text-[#424242]">
            <div className="p-[8px] bg-[#ECECFE] rounded-full w-fit mb-[16px]">
              <GlobalIcon
                iconName="CheckedIcon"
                size="XS"
                color="var(--primary-color)"
              />
            </div>
            {translate("identification.modal.saved")}
          </div>
        ) : hasDocumentation ? (
          <div>
            <div className="flex flex-row gap-[16px] flex-wrap border-b border-b-[#E2E2E2] pb-[16px]">
              <p className="flex-1 flex-col gap-[4px] BodyM font-medium text-[#7B7B7B]">
                <span className="whitespace-nowrap">
                  {translate("identification.modal.insuredInformation")}
                </span>
                <br />
                <span className="text-[#424242]">{documentation.fullName}</span>
              </p>
              <p className="flex-1 flex-col gap-[4px] BodyM font-medium text-[#7B7B7B]">
                <span className="whitespace-nowrap">
                  {translate("identification.modal.email")}
                </span>
                <br />
                <span className="text-[#424242]">{documentation.email}</span>
              </p>
              <p className="flex-1 flex-col gap-[4px] BodyM font-medium text-[#7B7B7B]">
                <span className="whitespace-nowrap">
                  {translate("identification.modal.phone")}
                </span>
                <br />
                <span className="text-[#424242]">
                  {documentation.phone ? formatPhone(documentation.phone) : ""}
                </span>
              </p>
            </div>
            <div className="mt-[16px] pb-[8px] border-b border-b-[#E2E2E2]">
              <p className="text-[var(--dark-primary-color)] BodyM font-medium">
                {translate("identification.modal.selectDocumentType")}
              </p>
              <div className="grid grid-cols-[repeat(auto-fit,_150px)] gap-[40px] px-[16px] mt-[16px]">
                <RadioButton
                  checked={option === "ID"}
                  value={"ID"}
                  onChange={() => toggleDocumentType("ID")}
                  label={translate("identification.modal.dni")}
                  variant="SimpleRadio"
                  disabled={isRadioDisabled}
                />
                <RadioButton
                  checked={option === "RP"}
                  value={"RP"}
                  onChange={() => toggleDocumentType("RP")}
                  label={translate("identification.modal.nifnie")}
                  variant="SimpleRadio"
                  disabled={isRadioDisabled}
                />
                <RadioButton
                  checked={option === "PA"}
                  value={"PA"}
                  onChange={() => toggleDocumentType("PA")}
                  label={translate("identification.modal.passport")}
                  variant="SimpleRadio"
                  disabled={isRadioDisabled}
                />
              </div>
            </div>
            {option === "UNKNOWN" ? undefined : (
              <div className="mt-[24px]">
                <p className="BodyM font-medium text-[var(--dark-primary-color)] mb-[4px]">
                  {translate("identification.modal.clientIdentification")}
                </p>
                <div className="grid grid-cols-2 gap-[32px]">
                  <PhotoIDDS
                    image={frontal}
                    setImage={setFrontal}
                    onLoadCallback={async (base64: string) => {
                      await uploadImageToServer("FRONT", base64);
                    }}
                    translations={(() => {
                      const translations = {
                        ...fileTranslations,
                      } as PhotoIDDSTranslationsT;
                      if (option === "ID") {
                        translations.steps = translate(
                          "identification.modal.steps.dni.front"
                        );
                        translations.modal = translate(
                          "identification.modal.dni.front"
                        );
                      }
                      if (option === "RP") {
                        translations.steps = translate(
                          "identification.modal.steps.nifnie.front"
                        );
                        translations.modal = translate(
                          "identification.modal.nifnie.front"
                        );
                      }
                      if (option === "PA") {
                        translations.steps = translate(
                          "identification.modal.steps.passport.front"
                        );
                        translations.modal = translate(
                          "identification.modal.passport"
                        );
                      }
                      return translations;
                    })()}
                    deleteFile={async () => {
                      deleteFile("FRONT");
                    }}
                    error={globalStatus === "ERRORED"}
                    isRemovable={
                      globalStatus === "PENDING" || globalStatus === "ERRORED"
                    }
                  />
                  {option !== "PA" ? (
                    <PhotoIDDS
                      image={back}
                      setImage={setBack}
                      onLoadCallback={async (base64: string) => {
                        await uploadImageToServer("BACK", base64);
                      }}
                      translations={(() => {
                        const translations = {
                          ...fileTranslations,
                        } as PhotoIDDSTranslationsT;
                        if (option === "ID") {
                          translations.steps = translate(
                            "identification.modal.steps.dni.back"
                          );
                          translations.modal = translate(
                            "identification.modal.dni.back"
                          );
                        }
                        if (option === "RP") {
                          translations.steps = translate(
                            "identification.modal.steps.nifnie.back"
                          );
                          translations.modal = translate(
                            "identification.modal.nifnie.back"
                          );
                        }
                        return translations;
                      })()}
                      deleteFile={async () => {
                        deleteFile("BACK");
                      }}
                      error={globalStatus === "ERRORED"}
                      isRemovable={
                        globalStatus === "PENDING" || globalStatus === "ERRORED"
                      }
                    />
                  ) : undefined}
                </div>
              </div>
            )}
            <div className="mt-[24px] flex flex-row gap-[8px]">
              {globalStatus === "PENDING" ? (
                <>
                  <GlobalIcon
                    iconName="AlertTriangleIcon"
                    size="XS"
                    color="#775F01"
                  />
                  <div className="flex flex-col gap-[4px]">
                    <p className="BodyL font-bold text-[#775F01]">
                      {translate("identification.modal.pending.label")}
                    </p>
                    <p className="BodyM font-medium text-[#555555]">
                      {translate("identification.modal.pending.body")}
                    </p>
                    <p className="BodyM font-bold text-[#E84B4B]">
                      {translate("identification.modal.expireDays").replace(
                        "{{days_to_expire}}",
                        documentation.expiringDays.toString()
                      )}
                    </p>
                  </div>
                </>
              ) : undefined}
              {globalStatus === "IN_REVIEW" ? (
                <>
                  <GlobalIcon
                    iconName="InfoIcon"
                    size="XS"
                    color="var(--dark-primary-color)"
                  />
                  <div className="flex flex-col gap-[4px]">
                    <p className="BodyL font-bold text-[var(--dark-primary-color)]">
                      {translate("identification.modal.review.label")}
                    </p>
                    <p className="BodyM font-medium text-[#555555]">
                      {translate("identification.modal.review.body")}
                    </p>
                  </div>
                </>
              ) : undefined}
              {globalStatus === "SUCCESS" ? (
                <>
                  <GlobalIcon
                    iconName="CircleCheckFilledIcon"
                    size="XS"
                    color="#1A9174"
                  />
                  <div className="flex flex-col gap-[4px]">
                    <p className="BodyL font-bold text-[#1A9174]">
                      {translate("identification.modal.success.label")}
                    </p>
                    <p className="BodyM font-medium text-[#555555]">
                      {translate("identification.modal.success.body")}
                    </p>
                  </div>
                </>
              ) : undefined}
              {globalStatus === "ERRORED" ? (
                <>
                  <GlobalIcon
                    iconName="AlertOctagon"
                    size="XS"
                    color="#E84B4B"
                  />
                  <div className="flex flex-col gap-[4px]">
                    <p className="BodyL font-bold text-[#E84B4B]">
                      {translate("identification.modal.error.label")}
                    </p>
                    <p className="BodyM font-medium text-[#555555]">
                      {documentation.errorMessage}
                    </p>
                    <p className="BodyM font-bold text-[#E84B4B]">
                      {translate("identification.modal.expireDays").replace(
                        "{{days_to_expire}}",
                        documentation.expiringDays.toString()
                      )}
                    </p>
                  </div>
                </>
              ) : undefined}
            </div>
          </div>
        ) : undefined
      }
      width={768}
      buttons={
        isSaved ? undefined : globalStatus === "PENDING" ? (
          <ButtonDS
            label={translate("identification.modal.save.cta")}
            className="w-fit min-w-[259px] ml-auto !flex-initial"
            onClick={sendToReview}
            disabled={isSaveDisabled || isLoading}
          />
        ) : (
          <ButtonDS
            label={translate("identification.modal.close.cta")}
            className="w-fit min-w-[259px] ml-auto !flex-initial"
            onClick={() => setOpen(false)}
          />
        )
      }
      autoClose={isSaved}
    />
  );
};

export default IdentificationModal;
