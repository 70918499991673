import { IconProps } from "./IconProps.interface";

const TickIcon: React.FC<IconProps> = ({
  width = "15",
  height = "16",
  color = "#47AB83",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.212891C3.34091 0.212891 0 3.5538 0 7.71289C0 11.872 3.34091 15.2129 7.5 15.2129C11.6591 15.2129 15 11.872 15 7.71289C15 3.5538 11.6591 0.212891 7.5 0.212891ZM11.8295 5.36062L6.375 11.0197C6.30682 11.0879 6.23864 11.122 6.13636 11.122C6.03409 11.122 5.93182 11.0879 5.89773 11.0197L3.23864 8.15607L3.17045 8.08789C3.10227 8.01971 3.06818 7.91744 3.06818 7.84925C3.06818 7.78107 3.10227 7.6788 3.17045 7.61062L3.64773 7.13335C3.78409 6.99698 3.98864 6.99698 4.125 7.13335L4.15909 7.16744L6.03409 9.1788C6.10227 9.24698 6.20455 9.24698 6.27273 9.1788L10.8409 4.44016H10.875C11.0114 4.3038 11.2159 4.3038 11.3523 4.44016L11.8295 4.91744C11.9659 5.01971 11.9659 5.22426 11.8295 5.36062Z"
        fill={color}
      />
    </svg>
  );
};

export default TickIcon;
