import * as React from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import apiLeadQuestionPost from "../../../api/request/apiLeadQuestionPost";
import apiVelogicaResult from "../../../api/request/Integrations/apiVelogicaResult";
import { I18nContext } from "../../../contexts/i18n.context";

const VelogicaResume = () => {

  const {brokerId, tokenVelogica, question, leadId, token} = React.useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);
  
  React.useEffect(() => {
    apiVelogicaResult(token, tokenVelogica).then((res:any) => {
    apiLeadQuestionPost(
        brokerId,
        leadId,
        parseInt(question),
        JSON.parse(res.result),
        token,
      ).then(() => {
        window.parent.location.href = `${process.env.REACT_APP_WEB_URL}`; 
      }).catch();
    });
  }, []);

  return (
    <>
        <p>{translate("question.velogica.inprogress")}</p>
    </>
  );
};

export default VelogicaResume;
