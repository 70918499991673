import { IconProps } from "./IconProps.interface";

const CrossIcon: React.FC<IconProps> = ({
  width = "15",
  height = "15",
  color = "#F03738",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8434 2.18828C9.91851 -0.729426 5.11863 -0.729426 2.1937 2.18828C-0.731232 5.10598 -0.731232 9.89401 2.1937 12.8117C5.11863 15.7294 9.84351 15.7294 12.7684 12.8117C15.6934 9.89401 15.7684 5.10598 12.8434 2.18828ZM9.61852 10.6421L7.51857 8.54738L5.41862 10.6421L4.36865 9.59476L6.46859 7.5L4.36865 5.40524L5.41862 4.35786L7.51857 6.45262L9.61852 4.35786L10.6685 5.40524L8.56854 7.5L10.6685 9.59476L9.61852 10.6421Z"
        fill={color}
      />
    </svg>
  );
};

export default CrossIcon;
