import { ButtonDS, GlobalIcon, useDragScroll } from "get-life-storybook-ts";
import { UniqueStatus } from "./Uniquestatus";
import { useContext, useEffect, useState } from "react";
import { Stages } from "../constants/stages";
import { I18nContext } from "../../../../contexts/i18n.context";

interface StatusFilterI {
  filterByStage: (stage: string) => void;
  savedStage: string;
}

export const StatusFilter = ({ filterByStage, savedStage }: StatusFilterI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [stageSelected, setStageSelected] = useState<string>(savedStage);
  const [ref] = useDragScroll();

  const handleStage = (stage: string) => {
    let newStage = "";
    if (stageSelected === "") {
      newStage = stage;
      setStageSelected(stage);
    } else {
      if (!stageSelected.includes(stage)) {
        newStage = `${stageSelected},${stage}`;
        setStageSelected(newStage);
      } else {
        if (stageSelected.includes(`,${stage}`)) {
          newStage = stageSelected.replace(`,${stage}`, "");
          setStageSelected(newStage);
        } else {
          newStage = stageSelected.replace(`${stage}`, "");
          setStageSelected(newStage);
        }
      }
    }
    filterByStage(newStage);
  };

  const resetFilter = () => {
    setStageSelected("");
    filterByStage("");
  };

  useEffect(() => {
    setStageSelected(savedStage);
  }, [savedStage]);

  return (
    <div className="flex flex-col w-full p-6 pb-8 bg-white rounded-lg gap-10	">
      <div className="flex flex-row gap-2 justify-between items-center min-h-[36px]">
        <div className="flex flex-row">
          <GlobalIcon iconName="ReportMoneyIcon" size="XS" color="#424242" />
          <p className="BodyL font-bold text-gray-text">
            {translate("projects.stage.title")}
          </p>
        </div>
        {stageSelected !== "" && (
          <div>
            <ButtonDS
              label="Borrar todo"
              buttonType="secondary"
              onClick={() => resetFilter()}
              rightIcon="TrashIcon"
              size="32"
            />
          </div>
        )}
      </div>
      <div
        className="w-full flex justify-between flex-wrap gap-4 min-h-[58px] [&>*]:!min-w-[unset] [&>*]:flex-1 [&_span]:whitespace-nowrap"
        ref={ref as any}
      >
        {Stages(translate).map((stage) => {
          return (
            <UniqueStatus
              label={stage.title}
              value={stage.value}
              handleValue={handleStage}
              totalStatus={stageSelected}
            />
          );
        })}
      </div>
    </div>
  );
};
