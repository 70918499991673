import { ListEmployeeReference } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

export interface columnsEmployeeI {
    name: string;
    reference: ListEmployeeReference;
    width?: string;
}

export const columnsEmployee: columnsEmployeeI[] = [
    {
        name: "tables.headers.fullName",
        reference: "fullName",
        width: "320px"
    },
    {
        name: "tables.headers.roles",
        reference: "roles",
        width: "204px"
    },
    {
        name: "tables.headers.emailCorporative",
        reference: "email",
    },
    {
        name: "tables.headers.phonePersonal",
        reference: "phone",
    },
    {
        name: "tables.headers.createdAt",
        reference: "createdAt",
    },
    {
        name: "tables.headers.actions",
        reference: "actions",
        width: "150px"
    }
];