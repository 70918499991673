import { CircularProgress } from "@material-ui/core";
import React from "react";
import "./css/buttonPrimary.scss";

export default function ButtonPrimary(props) {
  const { disabled, type, content, onClick, loading, id } = props;
  const disabledStyle =
    disabled === true ? "button-primary-disabled" : "button-primary";

  return (
    <>
      <button
        className={`${disabledStyle} ${props.className}`}
        type={type ? type : ""}
        disabled={disabled ? disabled : false}
        onClick={() => (onClick ? onClick() : null)}
        id={id}
      >
        {!!loading ? (
          <CircularProgress className="button-primary__loader" size={20} />
        ) : (
          content
        )}
      </button>
    </>
  );
}
