function selectGetlifeStyle() {
  const styles = {
    control: (styles, state) => ({
      ...styles,
      border: state.menuIsOpen ? "solid 2px #E7E7FE" : "solid 2px #e7e6df",

      borderRadius: "8px",
      height: "48px",
      marginTop: "7px",
      boxShadow: state.menuIsOpen ? "0 0 0 5px #E7E7FE" : null,
      ":hover": {
        border: state.menuIsOpen ? "solid 2px #5A56F5" : "solid 2px #5A56F5",
      },
      ":active": {
        border: "solid 2px #5A56F5",
        boxShadow: "0 0 0 5px #E7E7FE",
      },
    }),

    option: (styles, { isSelected, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f2f1ed" : null,
        color: isDisabled ? "#B2AFA4" : "#2c2302",
        margin: "16px",
        width: "calc(100% - 32px)",
        padding: "10px 18px",
        borderRadius: "8px",

        ":hover": {
          cursor: "pointer",
          backgroundColor: "#D0CFFC",
        },
      };
    },

    menu: (styles) => {
      return {
        ...styles,
        "@media (max-width: 920px)": {
          maxHeight: "150px",
        },
      };
    },

    menuList: (styles) => {
      return {
        ...styles,
        "@media (max-width: 920px)": {
          maxHeight: "150px",
        },
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: "16px",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "#2c2302",
      ":hover": {
        backgroundColor: "white",
        color: "#ff0000",
        cursor: "pointer",
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: "white",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#2c2302",
      marginRight: "8px",
      ":hover": {
        color: "#2c2302",
      },
    }),
    placeholder: (styles) => ({
      ...styles,

      color: "hsl(0, 0%, 80%)",
    }),
  };
  return styles;
}

export default selectGetlifeStyle();
