import React from "react";
import { errorMessage } from "../../../../utils/error";
import InputGeneral from "../../molecules/InputGeneral/InputGeneral.component";
import { FormProps } from "./FromInterface.model";

const FormBankEntity: React.FC<FormProps> = ({
  error,
  setValue,
  translate,
  values,
}): JSX.Element => {
  return (
    <>
      <InputGeneral
        editValue={values["bankName"]}
        label={translate("postStack.bankEntityForm.name")}
        setter={(newValue: string) => setValue("bankName", newValue)}
        name="bankName"
        placeholder={translate("beneficiaries.placeholder.bankName")}
        error={errorMessage(error, "bankName")}
      />
      <InputGeneral
        editValue={values["loanMortgageNumber"]}
        label={translate("postStack.bankEntityForm.idNumber")}
        setter={(newValue: string) => setValue("loanMortgageNumber", newValue)}
        name="loanMortgageNumber"
        placeholder={translate("beneficiaries.placeholder.loanMortgageNumber")}
        error={errorMessage(error, "loanMortgageNumber")}
      />
      <InputGeneral
        editValue={values["capital"]}
        type="number"
        label={translate("global.capital")}
        setter={(newValue: string) => setValue("capital", newValue)}
        name="capital"
        placeholder={translate("beneficiaries.placeholder.name")}
        error={errorMessage(error, "capital")}
      />
    </>
  );
};

export default FormBankEntity;
