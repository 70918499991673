import { useContext } from "react";
import { useNavigate } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import apiCheckOutFinish from "./../../api/request/apiCheckOutFinish";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpTrackingRepository from "../../api/request/Tracking/tracking.service";

export default function CardValidation() {
  const { brokerId } = useContext(ContextGetlife);
  const leadId = localStorage.getItem("leadId");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const trackingRepository = new HttpTrackingRepository(token);
  async function getStripe() {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      locale: process.env.REACT_APP_DOMAIN_LOCALE,
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    });
    let params = new URLSearchParams(window.location.search);
    let paymentIntentSecret = params.get("payment_intent_client_secret");
    stripe.retrievePaymentIntent(paymentIntentSecret).then(function (result) {
        apiCheckOutFinish(token, leadId, result.paymentIntent.status).then((response) => {
          
            let eventDetails = [
              {
                leadId: leadId,
                token: token,
                brokerEmployeeId: brokerId,
              },
            ];
            trackingRepository.add({
              eventType: "PAYMENT_SUCCESS",
              eventDetails,
            });
        }).catch((e) => {
          let eventDetails = [
            {
              leadId: leadId,
              paymentMethod: "card",
              errorMessage: "FINISH_PAYMENT" + e,
              token: token,
              brokerEmployeeId: brokerId,
            },
          ];
          trackingRepository.add({
            eventType: "PAYMENT_ERROR",
            eventDetails,
          });
        });

        navigate("/waitingsignature");
        
    });
    return stripe;
  }

  getStripe();
  return "";
}
