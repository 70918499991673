import { intersection } from "lodash";
import { ROLES } from "./roles";

export const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length;
};

export const getRoles = () => Object.values(ROLES);

export const getAllowedRoutes = ({ routes, roles }) => {
  return routes.filter(({ permission }) => {
    if (!permission) {
      return true;
    } else if (!isArrayWithLength(permission)) {
      return true;
    } else {
      return intersection(permission, roles).length;
    }
  });
};
