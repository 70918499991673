import { Navigate, Outlet } from "react-router-dom";
import Header from "../views/components/header/Header";

const PrivateRoute = ({ token }) => {
  return token ? (
    <Header>
      <Outlet />
    </Header>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
