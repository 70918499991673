class CacheSessionManager {
  private readonly storage: Storage;
  constructor(storage: Storage) {
    this.storage = storage;
  }

  delete(cacheName: string): void {
    return this.storage.removeItem(cacheName);
  }

  purge(): void {
    return this.storage.clear();
  }

  has(cacheName: string): boolean {
    return !!this.storage.getItem(cacheName);
  }

  get(cacheName: string): any | null {
    const item = this.storage.getItem(cacheName);
    if (typeof item === "string" && item !== "" && item !== null) {
      return item;
    }
    return "";
  }

  keys(): { [key: string]: string } {
    return { ...this.storage };
  }

  put(cacheName: string, response: any): void {
    return this.storage.setItem(cacheName, response);
  }
}

export default CacheSessionManager;
