import { ButtonDS, Chip, ModalDS } from "get-life-storybook-ts";
import { ViewProjectData } from "../../api/request/Lead/Model/EditProjectReponse.model";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../contexts/i18n.context";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import { SQLPricingResponse } from "../../api/request/Lead/Model/Response/SQLPricingResponse";
import apiLeadSendDocument from "../../api/request/apiLeadSendDocument";

interface ViewProjectI {
  viewData: ViewProjectData;
  viewModal: boolean;
  setViewModal: (value: boolean) => void;
}

const ViewProject = ({ viewData, viewModal, setViewModal }: ViewProjectI) => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;
  const {
    state: { translate },
  } = useContext(I18nContext);
  const isRejected = viewData.status === "rejected";
  const [downloadableModal, setDownloadableModal] = useState(false);
  const [pricing, setPricing] = useState<SQLPricingResponse | undefined>(
    undefined
  );

  const [downloadablePdfs, setDownloadablePdfs] = useState<
    [{ template: string; url: string }] | []
  >([]);

  const { setShowNotification, token } = useContext(ContextGetlife);

  const leadRepository = new HttpLeadRepository(token);

  const fetchDownloadables = async (leadId: string) => {
    const downloadablePdfs = await leadRepository.getAvailableDocuments(leadId);
    const contract: any[] = [];

    setDownloadablePdfs([...downloadablePdfs, ...contract] as [
      { template: string; url: string }
    ]);
  };

  const onClose = () => {
    setDownloadablePdfs([]);
    setViewModal(false);
  };

  const coverageLabel =
    {
      basic: "view.project.basic",
      premium: "view.project.premium",
      eg: "view.project.eg",
    }[viewData.coverage] || "";

  const getPricing = async (_leadId: string) => {
    await leadRepository
      .getPricingCapitals(_leadId)
      .then((response: SQLPricingResponse) => {
        setPricing(response);
      });
  };

  const sendEmailDocument = async (email: string, text: string, template: string) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        viewData.leadId,
        type,
        text,
        email,
        resource,
        template.includes(",") ? template.split(",") : [template]
      );
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(viewData).length > 0) {
        await fetchDownloadables(viewData.leadId);
        if (DOMAIN === "sql_es") {
          await getPricing(viewData.leadId);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  return (
    <>
      <ModalDS
        open={viewModal}
        onClose={onClose}
        icon="EyeIcon"
        title={translate("view.project.title")}
        content={
          <div>
            <div className="py-[16px] flex flex-col border-b border-[#E2E2E2]">
              <span className="BodyM text-[#7B7B7B] font-medium">
                {translate("view.project.insuredDate")}
              </span>
              <span className="pb-[16px] BodyL text-[#424242] font-medium">
                {viewData.fullName ?? translate("view.project.notAvailable")}
              </span>
              <div className="pl-[24px] flex flex-col">
                <span className="BodyS text-[#7B7B7B] font-medium">
                  {translate("view.project.email")}
                </span>
                <span className="pb-[16px] BodyM text-[#424242] font-medium">
                  {viewData.email ?? translate("view.project.notAvailable")}
                </span>

                <span className="BodyS text-[#7B7B7B] font-medium">
                  {translate("view.project.phone")}
                </span>
                <span className="pb-[16px] BodyM text-[#424242] font-medium">
                  {viewData.phone ?? translate("view.project.notAvailable")}
                </span>
                {isRejected ? null : (
                  <>
                    <span className="BodyS text-[#7B7B7B] font-medium">
                      {translate("view.project.adress")}
                    </span>
                    <span className="BodyM text-[#424242] font-medium">
                      {viewData.fullAddress ??
                        translate("view.project.notAvailable")}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="py-[16px] flex flex-col border-b border-[#E2E2E2]">
              {isRejected ? (
                <>
                  <span className="BodyM text-[#7B7B7B] font-medium">
                    {translate("view.project.rejectedData")}
                  </span>
                  <div className="grid grid-cols-2 gap-x-[32px] gap-y-[16px] mt-[16px]">
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.creationDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.createdAt ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                      {translate("view.project.rejectedDate")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.rejectedDate ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                    <p className="BodyS text-[#7B7B7B] font-medium pl-[24px] col-span-2">
                      {translate("view.project.rejectionReason")}
                      <span className="block text-[#424242] BodyM">
                        {viewData.rejectionCause ??
                          translate("view.project.notAvailable")}
                      </span>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <span className="BodyM text-[#7B7B7B] font-medium">
                    {translate("view.project.policyData")}
                  </span>
                  <span className="pb-[16px] BodyL text-[#424242] font-medium">
                    {viewData.policyNumber ??
                      translate("view.project.notAvailable")}
                  </span>
                  <div className="grid grid-cols-2 gap-x-[32px]">
                    <div className="flex flex-col gap-y-[16px]">
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate(
                          DOMAIN === "sql_es"
                            ? "view.project.allCapital"
                            : "view.project.capital"
                        )}
                        {DOMAIN === "sql_es" && pricing
                          ? (() => {
                              let capital = 0;
                              Object.entries(pricing.capitals).forEach(
                                ([key, value]) => {
                                  capital += value.capital;
                                }
                              );
                              return (
                                <Chip
                                  text={new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }).format(capital)}
                                  type="success"
                                  icon={null}
                                />
                              );
                            })()
                          : null}
                        {DOMAIN !== "sql_es" ? (
                          viewData.capital ? (
                            <Chip
                              text={new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(viewData.capital)}
                              type="success"
                              icon={null}
                            />
                          ) : (
                            translate("view.project.notAvailable")
                          )
                        ) : null}
                      </p>
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.capital")}
                        {DOMAIN === "sql_es"
                          ? (() => {
                              if (!pricing) return null;
                              const chips: any[] = [];
                              Object.entries(pricing.capitals).forEach(
                                ([key, value]) => {
                                  console.log("view.project.capital." + key);
                                  if (value.capital > 0) {
                                    chips.push(
                                      <Chip
                                        text={
                                          translate(
                                            "view.project.capital." + key
                                          ) +
                                          " " +
                                          new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "EUR",
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                          }).format(value.capital)
                                        }
                                        type="success"
                                        icon={null}
                                      />
                                    );
                                  }
                                }
                              );
                              return (
                                <div className="flex flex-col gap-[8px]">
                                  {chips}
                                </div>
                              );
                            })()
                          : null}
                        {DOMAIN !== "sql_es" ? (
                          viewData.coverage ? (
                            <Chip
                              text={translate(coverageLabel)}
                              type="success"
                              icon={null}
                            />
                          ) : (
                            translate("view.project.notAvailable")
                          )
                        ) : null}
                      </p>
                      {viewData.lastNumbers ? (
                        <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                          {translate("view.project.paymenthMethod")}
                          <span className="block text-[#424242] BodyM">
                            {viewData.paymentMethod
                              ? `${translate(
                                  `view.project.${viewData.paymentMethod}`
                                )}`
                              : null}
                            {viewData.lastNumbers}
                          </span>
                        </p>
                      ) : null}
                    </div>
                    <div className="flex flex-col gap-y-[16px]">
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.pay")}
                        <span className="block text-[#424242] BodyM">
                          {viewData.premium
                            ? new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(viewData.premium) +
                              (viewData.frequency === "MONTHLY"
                                ? translate("view.project.monthly")
                                : translate("view.project.yearly"))
                            : translate("view.project.notAvailable")}
                        </span>
                      </p>
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.renewal")}
                        <span className="block text-[#424242] BodyM">
                          {viewData.renewalPremium
                            ? new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(viewData.renewalPremium) +
                              (viewData.frequency === "MONTHLY"
                                ? translate("view.project.monthly")
                                : translate("view.project.yearly"))
                            : translate("view.project.notAvailable")}
                        </span>
                      </p>
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.purchaseDate")}
                        <span className="block text-[#424242] BodyM">
                          {viewData.purchaseDate
                            ? viewData.purchaseDate
                            : translate("view.project.notAvailable")}
                        </span>
                      </p>
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.effectDate")}
                        <span className="block text-[#424242] BodyM">
                          {viewData.effectDate
                            ? viewData.effectDate
                            : translate("view.project.notAvailable")}
                        </span>
                      </p>
                      <p className="BodyS text-[#7B7B7B] font-medium pl-[24px]">
                        {translate("view.project.renewalDate")}
                        <span className="block text-[#424242] BodyM">
                          {viewData.renewalDate
                            ? viewData.renewalDate
                            : translate("view.project.notAvailable")}
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        }
        buttons={
          <>
            {isRejected ? null : (
              <ButtonDS
                label={translate("view.project.downloadables")}
                buttonType="tertiary"
                leftIcon="FileDownload"
                className="w-full"
                onClick={() => setDownloadableModal(true)}
              />
            )}
          </>
        }
      />
      <DownloadableFilesModalDS
        open={downloadableModal}
        setOpen={setDownloadableModal}
        downloadablePdfs={downloadablePdfs}
        emailToSend={viewData.email}
        sendEmail={sendEmailDocument}
      />
    </>
  );
};

export default ViewProject;
