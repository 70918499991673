// Constants
import constants from "../constants/login-otp.constants";

const handleClickNoAccountAction = (navigate) => {
  navigate("/register");
};

const handleClickChooseBetterAction = (
  inputType,
  setInputType,
  setRequested
) => {
  if (inputType === constants.types.TEL) {
    setInputType(constants.types.EMAIL);
    setRequested(false);
  } else {
    setInputType(constants.types.TEL);
    setRequested(false);
  }
};

const handleClickNoCodeAction = async (
  brokerRepository,
  contactValue,
  inputType
) => {
  await brokerRepository.loginOtp(contactValue, inputType);
};

const LoginOtpHandlers = (
  brokerRepository,
  contactValue,
  navigate,
  inputType,
  setInputType,
  setRequested
) => ({
  handleClickNoAccount: () => handleClickNoAccountAction(navigate),
  handleClickChooseBetter: () =>
    handleClickChooseBetterAction(inputType, setInputType, setRequested),
  handleClickNoCode: () =>
    handleClickNoCodeAction(brokerRepository, contactValue, inputType),
});

export default LoginOtpHandlers;
