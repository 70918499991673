import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RegisterMain from "./RegisterMain";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../api/request/Broker/broker.service";
import { I18nContext } from "../../contexts/i18n.context";

export default function Register() {
  const navigate = useNavigate();

  const {
    brokerId,
    setBroker,
    setBrokerId,
    setShowNotification,
    setStatus,
    setToken,
    token,
  } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const [brokerageName, setBrokerageName] = useState(false);

  const [name, setName] = useState(false);

  const [lastName, setLastName] = useState(false);

  const [personalPhone, setPersonalPhone] = useState(false);

  const [workPhone, setWorkPhone] = useState(false);

  const [email, setEmail] = useState(false);

  const [authorizeNumber, setAuthorizeNumber] = useState(false);

  const [dni, setDni] = useState(false);

  const [haveRcSecure, setHaveRcSecure] = useState(false);

  const [haveAuthorization, setHaveAuthorization] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleBrokerageName = (e) => {
    setBrokerageName(e);
  };

  const handleName = (e) => {
    setName(e);
  };

  const handleLastName = (e) => {
    setLastName(e);
  };

  const handlePersonalPhone = (e) => {
    setPersonalPhone(e);
  };

  const handleWorkPhone = (e) => {
    setWorkPhone(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handleAuthorizeNumber = (e) => {
    setAuthorizeNumber(e);
  };

  const handleHaveRcSecure = () => setHaveRcSecure(!haveRcSecure);

  const handleHaveAuthorization = () =>
    setHaveAuthorization(!haveAuthorization);

  const handleAcceptTerms = () => setAcceptTerms(!acceptTerms);

  const handleDni = (e) => setDni(e); //meter validaciones para dni

  const fetchBroker = async (token) => {
    const brokerResponse = await brokerRepository.getBroker(token);
    setBroker(brokerResponse);
    setStatus(brokerResponse.status || "");
    navigate("/dashboard");
  };

  const handleSubmit = async (e) => {
    try {
      const response = await brokerRepository.postBroker({
        email,
        brokerageName,
        name,
        lastname: lastName,
        personalPhone,
        workPhone,
        idDgsfp: authorizeNumber,
        idCard: dni,
      });
      const { brokerId, token } = response;
      setBrokerId(brokerId);
      setToken(token);
      await fetchBroker(token);
    } catch (e) {
      setShowNotification({
        message: translate(`errors.register.${e.message.replace(/\s/g, '')}`),
        status: true,
        type: "error",
      });
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <RegisterMain
      acceptTerms={acceptTerms}
      authorizeNumber={authorizeNumber}
      brokerageName={brokerageName}
      brokerId={brokerId}
      dni={dni}
      email={email}
      handleAcceptTerms={handleAcceptTerms}
      handleAuthorizeNumber={handleAuthorizeNumber}
      handleBrokerageName={handleBrokerageName}
      handleDni={handleDni}
      handleEmail={handleEmail}
      handleHaveAuthorization={handleHaveAuthorization}
      handleHaveRcSecure={handleHaveRcSecure}
      handleLastName={handleLastName}
      handleName={handleName}
      handlePersonalPhone={handlePersonalPhone}
      handleSubmit={handleSubmit}
      handleWorkPhone={handleWorkPhone}
      haveAuthorization={haveAuthorization}
      haveRcSecure={haveRcSecure}
      lastName={lastName}
      name={name}
      personalPhone={personalPhone}
      token={token}
      workPhone={workPhone}
      translate={translate}
    />
  );
}
