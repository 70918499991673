import {
  ButtonDS,
  Faq,
  GlobalIcon,
  IconButton,
  TrustPilotDS,
  useDragScroll,
} from "get-life-storybook-ts";
import LandingSQLController from "./LandingSQL.controller";
import { I18nContext } from "../../../../contexts/i18n.context";
import { useContext } from "react";
import CalculatorSQL from "./CalculatorSQL";
import {
  FiveStars,
  TrustPilotLogo,
} from "get-life-storybook-ts/lib/components/TrustPilotDS/TrustPilotDS";

const LandingSQL = () => {
  const { broker, brokerId, calculatorData, translate, qsLang } =
    LandingSQLController();

  const lang = (() => {
    if (broker?.brokerageId === 354) return "en";
    if (process.env.REACT_APP_ENVIROMENT && qsLang === "en") return "en";
    return undefined;
  })();

  const scrollToCalculator = () => {
    const element = document.getElementById("sql-calculator");
    window.scrollTo({
      behavior: "smooth",
      top:
        element?.getBoundingClientRect().top! -
        document.body.getBoundingClientRect().top -
        80,
    });
  };

  return (
    <div className="LandingPage">
      <section className="LandingPage-header">
        <div className="LandingPage-header-logos">
          {broker && broker.logoFilename ? (
            <img
              src={broker.logoFilename}
              alt="logo del broker"
              className="LandingPage-header-brokerLogo"
            />
          ) : broker ? (
            <p className={"LandingPage-header-brokerName BodyM"}>
              {broker.brokerageName}
            </p>
          ) : null}
        </div>
        <div className="LandingPage-header-CTA">
          <ButtonDS
            href={`mailto:${broker?.email}`}
            label={translate("landing.button.sendEmail", lang)}
            rightIcon="MailForwardIcon"
            buttonType="tertiary"
            size="40"
          />
          <ButtonDS
            href={`tel:${broker?.workPhone}`}
            label={translate("landing.button.call", lang)}
            leftIcon="PhoneCallIcon"
            size="40"
          />
        </div>
        <div className="LandingPage-header-CTA-mobile">
          <IconButton
            href={`mailto:${broker?.email}`}
            icon="MailForwardIcon"
            buttonType="tertiary"
            size="40"
          />
          <IconButton
            href={`tel:${broker?.workPhone}`}
            icon="PhoneCallIcon"
            size="40"
          />
        </div>
      </section>
      <HeroPrimary scrollToCalculator={scrollToCalculator} />
      <Banner scrollToCalculator={scrollToCalculator} />
      <CardComponent />
      <HeroCalculator
        data={calculatorData}
        brokerId={brokerId}
        translate={translate}
        lang={lang}
      />
      <ContactBanner
        phone={broker?.workPhone}
        email={broker?.email}
        translate={translate}
        lang={lang}
      />
      <Opinion />
      <FeatureContent scrollToCalculator={scrollToCalculator} />
      <ReviewCards />
      <FAQ translate={translate} lang={lang} />
    </div>
  );
};

const HeroPrimary = ({ scrollToCalculator }: any) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <section className="bg-[#ECF0FF] overflow-hidden">
      <div className="grid gap-[32px] items-center m-[0_auto] p-[80px_24px_40px_24px] max-w-content md:grid-cols-12 xl:px-[0px]">
        <div className="relative md:col-span-6 pr-[24px]">
          <div className="mb-[24px]">
            <h2 className="text-[#424242] font-DM font-bold H1">
              El seguro de vida{" "}
              <span className="H1 font-MW font-light">que crece contigo</span>
            </h2>
            <p className="text-[var(--theme-secondary)] mt-[24px] font-medium BodyXL">
              Descubre coberturas flexibles que evolucionan contigo y tus
              necesidades:
            </p>
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"#018565"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Invalidez permanente absoluta (IPA) o total (IPT).
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"#018565"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Cobertura por enfermedades graves, como el cáncer.
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"#018565"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Doble o triple capital en caso de accidente.
              </span>
            </div>
          </div>
          <ButtonDS
            label="Calcula tu precio"
            rightIcon="ArrowRightIcon"
            className="mt-[56px] w-fit"
            onClick={scrollToCalculator}
          />
          <TrustPilotDS
            CurrentRateLabel={"4.8"}
            OutOfLabel={"de 5"}
            className="mt-[32px]"
            TrustpilotLink={translate("landing.header.linkTrutspilot")}
          />
        </div>
        <div
          className={`h-[260px] left-[var(--heroCalculatorImageOffset)] relative md:h-[var(--heroCalculatorImageHeight)] md:col-start-[7] md:col-end-[13]`}
          style={
            {
              "--heroCalculatorImageHeight": "520px",
              "--heroCalculatorImageOffset": "0px",
            } as React.CSSProperties
          }
        >
          <img
            className={
              "h-full absolute overflow-hidden max-w-[unset] object-cover object-left rounded-tl-[999px] rounded-bl-[999px] md:!w-[50vw] w-[100vw]"
            }
            src={
              "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-1282475495b%201.png"
            }
            alt={""}
          />
        </div>
      </div>
    </section>
  );
};

const Banner = ({ scrollToCalculator }: any) => {
  return (
    <section className="bg-white">
      <div className="flex lg:flex-row flex-col lg:gap-[62px] gap-[40px] items-center m-[0_auto] p-[80px_24px_40px_24px] max-w-content xl:px-[0px]">
        <div className="lg:w-[478px] self-stretch rounded-[16px] overflow-hidden h-[320px] lg:h-[unset]">
          <picture className="h-full w-full">
            <img
              alt=""
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/joven-familia-su-pequeno-hijo-casa%201.png"
              className="object-cover h-full w-full"
            />
          </picture>
        </div>
        <div className="flex-1">
          <p className="BodyS text-[var(--theme-primary)] font-bold mb-[8px] tracking-[1.4px]">
            SEGURO DE VIDA
          </p>
          <h2 className="H2 text-[#424242] font-bold font-DM mb-[24px]">
            ¿Qué hace especial nuestro seguro de vida?
          </h2>
          <p className="BodyL text-[#424242] mb-[24px]">
            Con nuestro seguro de vida, pagas una pequeña cantidad regularmente
            y garantizas protección económica para ti y tus seres queridos. Tú
            decides qué coberturas necesitas.
          </p>
          <p className="BodyL text-[#424242] font-bold mb-[24px]">
            Las ventajas de tener un seguro de vida Life5:
          </p>
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-row gap-[8px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color="var(--theme-primary)"
                size="XS"
              />
              <span className="BodyL text-[#424242] block flex-1">
                <b>Tranquilidad financiera:</b> Asegura tu bienestar y el de tus
                seres queridos con la combinación perfecta de nuestras
                coberturas.
              </span>
            </div>
            <div className="flex flex-row gap-[8px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color="var(--theme-primary)"
                size="XS"
              />
              <span className="BodyL text-[#424242] block flex-1">
                <b>Cobertura personalizadas:</b> Desde protección básica hasta
                coberturas adicionales como invalidez, enfermedades graves...
              </span>
            </div>
            <div className="flex flex-row gap-[8px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color="var(--theme-primary)"
                size="XS"
              />
              <span className="BodyL text-[#424242] block flex-1">
                <b>Sin trámites complicados:</b> Sin exámenes médicos ni
                papeleos.
              </span>
            </div>
            <div className="flex flex-row gap-[8px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color="var(--theme-primary)"
                size="XS"
              />
              <span className="BodyL text-[#424242] block flex-1">
                <b>Flexibilidad:</b> Adapta el seguro a tus necesidades
                específicas.
              </span>
            </div>
          </div>
          <ButtonDS
            onClick={scrollToCalculator}
            label="Simula tu precio"
            rightIcon="ArrowRightIcon"
            className="w-fit mt-[40px]"
          />
        </div>
      </div>
    </section>
  );
};

const CardComponent = () => {
  const data = [
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-820376276%201.png",
      title: "Fallecimiento",
      content:
        "Proporciona una indemnización a los beneficiarios designados en caso de fallecimiento del asegurado, garantizando su bienestar económico y apoyo financiero en momentos difíciles.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/istockphoto-532129585-1024x1024%201.png",
      title: "Fallecimiento por accidente",
      content:
        "Ofrece una indemnización adicional en caso de que el fallecimiento del asegurado ocurra debido a un accidente inesperado (ej. un incendio), cubriendo cualquier tipo de accidente que no sea específicamente de tráfico.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-1590495194%201.png",
      title: "Fallecimiento por accidente de tráfico o circulación",
      content:
        "Ofrece un capital adicional a los beneficiarios en caso de fallecimiento debido a un accidente de tráfico, ya sea como conductor, pasajero, o incluso si eres peatón.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-873015278%201.png",
      title: "IPA - Incapacidad Absoluta",
      content:
        "Cubre la situación en la que el asegurado sufra una incapacidad permanente absoluta (IPA), impidiéndole realizar cualquier actividad laboral. El asegurado recibirá una indemnización que ayuda a mantener la estabilidad financiera.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-113685138%201.png",
      title: "IPA por accidente",
      content:
        "Cobertura que paga un capital al asegurado en el caso que sufra una  incapacidad permanente absoluta resultante de un accidente (ej. accidente laboral), excluyendo otras causas no relacionadas con accidentes.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/iStock-457945395%201.png",
      title: "IPA por accidente de tráfico o circulación",
      content:
        "Ofrece un capital en caso de incapacidad permanente absoluta causada por un accidente de tráfico, ya sea como conductor, pasajero o peatón en la vía pública.",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/Pexels%20Photo%20by%20Anastasiya%20Gepp.png",
      title: "IPT - Incapacidad Total",
      content:
        "Ofrece el capital asegurado si el asegurado queda incapaz de desempeñar su profesión habitual debido a una invalidez total, aunque pueda realizar otras actividades laborales (por ejemplo, un conductor profesional con pérdida significativa de visión).",
    },
    {
      image:
        "https://storage.googleapis.com/getlife-bucket-1/public/image/Pexels%20Photo%20by%20Anastasiya%20Gepp-1.png",
      title: "Enfermedades Graves",
      content:
        "La cobertura de enfermedades graves proporciona protección económica si sufres una de estas enfermedades:<ul style='list-style-type: disc; padding-inline-start:24px;'><li>Cáncer</li><li>Accidente cerebro vascular.</li><li>Infarto Miocardio</li><li>Cirugía de las Arterias Coronarias (Bypass Aorta-coronario)</li></ul>",
    },
  ];

  return (
    <section className="m-[0_auto] p-[80px_24px_40px_24px] max-w-content xl:px-[0px]">
      <div className="text-center text-[#424242] flex flex-col gap-[16px]">
        <h2 className="H2 font-DM font-bold">
          Nuestras coberturas{" "}
          <span className="font-MW font-light">son exclusivas</span>
        </h2>
        <p className="H4 mb-[64px]">
          Elige las coberturas que mejor se adapten a tus necesidades y protege
          a los tuyos
        </p>
      </div>
      <div className="flex flex-row flex-wrap gap-[32px] justify-center">
        {data.map((d) => {
          const { image, title, content } = d;
          return (
            <div
              className="rounded-[24px] overflow-hidden flex flex-col items-center flex-[352px] max-w-[352px]"
              style={{
                boxShadow:
                  "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07)",
              }}
            >
              <picture className="h-[154px] w-full">
                <img
                  src={image}
                  alt=""
                  className="object-cover h-full w-full"
                />
              </picture>
              <div className="p-[24px]">
                <p className="text-[#424242] BodyXL font-bold">{title}</p>
                <p
                  dangerouslySetInnerHTML={{ __html: content }}
                  className="mt-[16px] BodyM text-[#555555]"
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const HeroCalculator = ({ data, brokerId, translate, lang }: any) => {
  return (
    <section className="bg-white" id="sql-calculator">
      <div className="grid gap-[32px] items-center m-[0_auto] p-[80px_24px_40px_24px] max-w-content md:grid-cols-12 xl:px-[0px]">
        <div className="relative md:col-span-6 pr-[24px]">
          <GlobalIcon
            iconName="LoopingArrow"
            className="hidden absolute top-[80px] right-[-184px] z-10 [transform:rotateY(180deg)_rotateZ(5deg)_scale(0.75)] lg:!block"
            color="var(--theme-primary)"
          />
          <TrustPilotDS
            CurrentRateLabel={"4.8"}
            OutOfLabel={"de 5"}
            TrustpilotLink={translate("landing.header.linkTrutspilot")}
          />
          <div className="mt-[32px] mb-[24px]">
            <h2 className="text-[#424242] font-DM font-bold H1">
              Calcula en minutos{" "}
              <span className="H1 font-MW font-light">tu precio estimado</span>
            </h2>
            <p className="text-[var(--theme-secondary)] mt-[24px] font-medium BodyXL">
              Nuestro seguro es uno de los más completos del mercado:
            </p>
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"var(--theme-secondary)"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Protección financiera para ti y tus seres queridos.
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"var(--theme-secondary)"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Cobertura integral de diversas invalideces.
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"var(--theme-secondary)"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Cobertura ante enfermedades graves.
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"var(--theme-secondary)"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Incremento de capital en caso de accidente.
              </span>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                color={"var(--theme-secondary)"}
                size="XS"
              />
              <span className="BodyXL text-[#424242] flex-1">
                Sin exámenes médicos, fácil y rápido.
              </span>
            </div>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-end">
          <CalculatorSQL
            data={data}
            brokerId={brokerId}
            translate={translate}
            lang={lang}
          />
        </div>
      </div>
    </section>
  );
};

const ContactBanner = ({ translate, phone, email, lang }: any) => {
  if (!phone && !email) return null;
  return (
    <section className="bg-[var(--theme-tertiary)]" id="landing-contact">
      <div className="max-w-content flex flex-col gap-[32px] m-[0_auto] p-[64px_24px] text-center md:gap-[64px] xl:px-[0px]">
        <div className="flex flex-col gap-[8px] md:gap-[16px]">
          <h2
            className="H2 font-DM font-bold text-[#424242]"
            dangerouslySetInnerHTML={{
              __html: translate("landing.iconCards.title", lang),
            }}
          />
          <p className="H4 text-[#424242] font-medium">
            {translate("landing.iconCards.subtitle", lang)}
          </p>
        </div>
        <div className="flex flex-row flex-wrap gap-[32px] justify-center">
          {phone ? (
            <div className="p-[24px] rounded-[24px] flex flex-col gap-[16px] items-center flex-[352px] max-w-[352px] bg-white">
              <span className="block w-fit bg-[#f5f7fa] text-[var(--theme-primary)] p-[8px] rounded-full mb-[8px]">
                <GlobalIcon
                  iconName="CallIcon"
                  color={"currentColor"}
                  colorFill="transparent"
                  size="M"
                />
              </span>
              <h3 className="text-[#424242] font-bold H4">
                {translate("landing.infoCards.phoneTitle", lang)}
              </h3>
              <p>
                {translate("landing.infoCards.phoneSubtitle", lang)}
                <br />
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
              <ButtonDS
                href={`tel:${phone}`}
                rightIcon="ArrowRightIcon"
                size="40"
                label={translate("landing.infoCards.phoneButton", lang)}
              />
            </div>
          ) : null}
          {email ? (
            <div className="p-[24px] rounded-[24px] flex flex-col gap-[16px] items-center flex-[352px] max-w-[352px] bg-white">
              <span className="block w-fit bg-[#f5f7fa] text-[var(--theme-primary)] p-[8px] rounded-full mb-[8px]">
                <GlobalIcon
                  iconName="EmailIcon"
                  color={"currentColor"}
                  colorFill="transparent"
                  size="M"
                />
              </span>
              <h3 className="text-[#424242] font-bold H4">
                {translate("landing.infoCards.emailTitle", lang)}
              </h3>
              <p>
                {translate("landing.infoCards.emailSubtitle", lang)}
                <br />
                <a href={`mailto:${email}`}>{email}</a>
              </p>
              <ButtonDS
                href={`mailto:${email}`}
                buttonType="tertiary"
                rightIcon="ArrowRightIcon"
                size="40"
                label={translate("landing.infoCards.emailButton", lang)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

const FAQ = ({ translate, lang }: any) => {
  return (
    <section className="LandingFaq">
      <div className="LandingFaq-wrapper max-w-content">
        <Faq
          headerProps={{
            title: translate("landing.FAQuestions.title", lang),
          }}
          accordion={
            [
              {
                headerText:
                  "¿Cómo funciona la cobertura de Incapacidad Permanente Absoluta (IPA)?",
                bodyContent: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "La cobertura de IPA proporciona protección económica si sufres una invalidez que te <b>impide realizar cualquier tipo de trabajo u oficio</b>. Esta cobertura se aplica a condiciones que incapacitan al asegurado de manera total para trabajar.<br/><br/>Puedes añadir garantías complementarias de accidentes y/o de accidentes de tráfico a esta cobertura. Sin embargo, la IPA no puede ser combinada con la Incapacidad Permanente Total (IPT), ya que son excluyentes.",
                    }}
                  />
                ),
                expanded: true,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: "¿Qué cubre la Incapacidad Permanente Total (IPT)?",
                bodyContent: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "La cobertura de IPT ofrece protección en caso de invalidez que <b>te impide realizar tus actividades laborales habituales</b>, aunque no es tan severa como la Incapacidad Permanente Absoluta (IPA). Se aplica a incapacidades significativas que afectan tu capacidad de trabajo.<br/><br/>No es posible añadir garantías complementarias de accidentes y/o de accidentes de tráfico a esta cobertura. Además, la IPT no puede ser combinada con la IPA, ya que son coberturas excluyentes.",
                    }}
                  />
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText:
                  "¿Puedo combinar la cobertura IPA con la cobertura de IPT?",
                bodyContent:
                  "No, las coberturas de Incapacidad Permanente Absoluta (IPA) y de Incapacidad Permanente Total (IPT) no se pueden combinar en una misma póliza. Cada una cubre diferentes grados de invalidez y no pueden elegirse simultáneamente.",
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: "¿Qué cubre la cobertura de Enfermedades Graves?",
                bodyContent:
                  "La cobertura de enfermedades graves proporciona protección económica si sufres una de estas enfermedades: Cáncer, Accidente cerebrovascular, Infarto de miocardio y Cirugía de las arterias Coronarias (Bypass Aorta-coronario).",
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText:
                  "¿Qué sucede si tengo una enfermedad grave y ya he recibido el pago de esta cobertura?",
                bodyContent:
                  "Una vez que recibes el pago por una enfermedad grave, la cobertura específica para enfermedades graves se cancela. Sin embargo, el resto de las coberturas del seguro, como fallecimiento, IPA o IPT, seguirán activas según los términos de la póliza. También seguirán activas las coberturas adicionales por accidente o accidente de tráfico.",
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText:
                  "¿Qué coberturas puedo combinar en mi seguro de vida?",
                bodyContent: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "Siempre que se encuentren en los capitales mínimos y máximos contratables, se puede hacer combinaciones de garantías de la siguiente manera:<br/><br/><ul style='list-style-type: disc; padding-inline-start: 40px;'><li><b>Fallecimiento:</b> Es la cobertura básica y se puede combinar con todas las demás coberturas.</li><li><b>Incapacidad Permanente Absoluta (IPA):</b> Se puede combinar con fallecimiento y/o enfermedades graves. También se puede añadir con garantías complementarias como accidentes y/o accidentes de tráfico (ofreciendo una suma adicional al beneficiario).</li><li><b>Incapacidad Permanente Total (IPT):</b> se puede combinar con Fallecimiento y/o con Enfermedades graves.</li><li><b>Enfermedades graves:</b> se puede combinar con Fallecimiento y/o con IPA o IPT.</li><li><b>Accidente:</b> se puede combinar con Fallecimiento y/o IPA, pero no con IPT.</li><li><b>Accidente de tráfico:</b> se puede combinar con Fallecimiento y/o IPA, pero no con IPT.</li></ul>",
                    }}
                  />
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText:
                  "¿Qué tipos de accidentes están cubiertos por la protección adicional en caso de accidente?",
                bodyContent: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "La protección en caso de accidente, ofrece una suma adicional en caso de fallecimiento y/o de Invalidez Permanente Absoluta debido a éste. Esta garantía cubre accidentes que ocurren fuera del ámbito de tráfico, como accidentes laborales o domésticos. También puedes elegir además de esta la cobertura, accidentes de tráfico como complementaria a ésta.<br/><br/>Esta cobertura se puede aplicar tanto a fallecimiento como a Incapacidad Permanente Absoluta (IPA), pero no a Incapacidad Permanente Total (IPT).",
                    }}
                  />
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText:
                  "¿Qué es la cobertura adicional por accidente de tráfico o circulación?",
                bodyContent: (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "La cobertura de fallecimiento por accidente de tráfico o circulación ofrece una suma adicional en caso de fallecimiento y/o de Invalidez Permanente Absoluta debido a un accidente de tráfico, ya sea como conductor, pasajero o peatón. Esta cobertura se puede aplicar tanto a fallecimiento como a Incapacidad Permanente Absoluta (IPA), pero no a Incapacidad Permanente Total (IPT).",
                    }}
                  />
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
            ] as any
          }
          globalRightIconProps={{
            displayedItemIcon: "PlusIcon",
            closedItemIcon: "MinusIcon",
            color: "var(--theme-primary)",
          }}
        />
      </div>
    </section>
  );
};

const Opinion = () => {
  return (
    <section
      className="bg-center bg-cover bg-no-repeat min-h-[350px] py-[24px] flex items-center"
      style={{
        backgroundImage:
          "url(https://storage.googleapis.com/getlife-bucket-1/public/image/mother-her-child-posing-indoor%201.png)",
      }}
    >
      <div className="max-w-content mx-auto flex items-center h-full">
        <div className="md:max-w-[550px] relative p-[24px] lg:mr-[160px] md:mr-[120px] mr-[24px] ml-[24px] md:ml-[0px]">
          <div
            className="absolute top-0 left-0 w-full h-full"
            style={{
              background: "rgba(255, 255, 255, 0.65)",
              filter: "blur(26px)",
            }}
          />
          <p className="BodyXL font-MW font-light text-[#000000] text-right z-[1] relative">
            “Desde que <b>tengo el seguro Life5</b>, estoy mucho más tranquila.
            Si me ocurre algo, puedo centrarme en mi salud sin preocupaciones
            económicas.”
          </p>
          <span className="BodyXL font-WM text-[var(--theme-secondary)] mt-[16px] w-full text-right block z-[1] relative">
            Andrea Gil, 33 años, madre soltera
          </span>
        </div>
      </div>
    </section>
  );
};

const FeatureContent = ({ scrollToCalculator }: any) => {
  return (
    <section className="bg-[var(--theme-quaternary)]" id="sql-calculator">
      <div className="max-w-content flex flex-col gap-[32px] m-[0_auto] p-[64px_24px] text-center md:gap-[64px] xl:px-[0px]">
        <div className="flex flex-col gap-[8px] md:gap-[16px]">
          <h2 className="H2 font-DM font-bold text-[var(--theme-primary)]">
            Descubre las ventajas de nuestros Seguros
          </h2>
          <p className="H4 text-[#424242]">
            Protección integral y beneficios exclusivos adaptados a tus
            necesidades
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-x-[40px] gap-y-[48px]">
          <div className="flex flex-col gap-[24px]">
            <GlobalIcon
              iconName="PuzzlePieceIcon"
              size="L"
              color="var(--theme-primary)"
            />
            <h3 className="H4 text-[#424242] font-bold text-left">
              Coberturas Flexibles y Personalizadas
            </h3>
            <p className="text-[#555555] BodyM text-left">
              Asegúrate según tus necesidades y elige la cobertura que más te
              convenga ¡Tu protección, tus condiciones!
            </p>
            <div className="border-t-[#D9D9D9] border-t" />
          </div>
          <div className="flex flex-col gap-[24px]">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M62.3565 31.279L58.3926 38.7115L44.2286 59.0938C40.716 64.1485 33.793 65.4415 28.6928 61.9954C23.553 58.5226 22.1944 51.5447 25.6556 46.3972L38.887 26.7198C38.8907 26.7142 38.8931 26.7107 38.8967 26.705C39.0086 26.5283 41.4018 22.7644 43.3926 20.7115C49.355 14.5628 58.3926 7.54737 63.3926 11.7115C67.7382 15.3305 62.7938 30.043 62.4152 31.146C62.3987 31.1942 62.3805 31.2341 62.3565 31.279Z"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M58.3306 39.7104C59.8306 42.3771 62.7306 48.5104 62.3306 51.7104C61.8306 55.7104 57.8306 63.7104 56.3306 63.2104C55.1306 62.8104 52.1639 54.7104 50.8306 50.7104"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M38.1364 27.4491C35.1008 27.0669 28.3265 26.6972 25.5143 28.2758C21.9991 30.249 16.1014 36.9733 17.1307 38.1735C17.9542 39.1337 26.5748 38.8226 30.7821 38.5471"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M29.8926 40.2104L48.3926 52.7104"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M35.8926 44.7104L23.8743 62.7379C22.7625 64.4056 23.1471 66.6514 24.7506 67.854V67.854C26.4656 69.1402 28.9061 68.7426 30.1241 66.9785L42.3926 49.2104"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <circle
                cx="51.8306"
                cy="27.4722"
                r="6.25"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
              />
            </svg>

            <h3 className="H4 text-[#424242] font-bold text-left">
              Fácil y Rápido
            </h3>
            <p className="text-[#555555] BodyM text-left">
              Contrata tu seguro de vida en minutos, sin exámenes médicos ni
              papeleos innecesarios. Text link
            </p>
            <div className="border-t-[#D9D9D9] border-t" />
          </div>
          <div className="flex flex-col gap-[24px]">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5099 50.833C22.5099 48.333 25.6636 46.333 29.5099 46.333C33.0099 46.333 36.5099 47.3332 39.5099 47.3332C42.5099 47.3332 52.0099 44.833 53.0099 46.8332C54.0099 48.8333 53.0099 51.3332 47.5099 54.3332M47.5099 54.3332C42.0099 57.3332 36.6766 57.1665 35.0099 57.3332M47.5099 54.3332C49.6766 53.1665 55.0099 49.8332 59.0099 45.8332C64.0099 40.8332 66.5099 37.8332 69.0099 40.3332C71.5099 42.8332 63.5099 51.833 62.0099 53.333C60.5099 54.833 53.0099 63.833 42.5099 65.333C32.0099 66.833 22.5099 64.833 19.5099 63.333"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M43.7599 25.333C43.7599 27.1279 42.3048 28.583 40.5099 28.583C38.715 28.583 37.2599 27.1279 37.2599 25.333C37.2599 23.5381 38.715 22.083 40.5099 22.083C42.3048 22.083 43.7599 23.5381 43.7599 25.333Z"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
              />
              <path
                d="M19.5099 65.333V48.333C19.5099 47.7807 19.0622 47.333 18.5099 47.333H11.5099C10.9576 47.333 10.5099 47.7807 10.5099 48.333V65.333C10.5099 65.8853 10.9576 66.333 11.5099 66.333H18.5099C19.0622 66.333 19.5099 65.8853 19.5099 65.333Z"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M25.5099 33.333V17.333H55.5099V33.333H25.5099Z"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M58.5099 30.333H55.5099"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M28.5099 14.333V17.333"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M28.5099 14.333H58.5099V30.333"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>

            <h3 className="H4 text-[#424242] font-bold text-left">
              Tranquilidad para ti y tu Familia
            </h3>
            <p className="text-[#555555] BodyM text-left">
              Garantiza el futuro de tus seres queridos con un seguro de vida
              que cubre en caso de fallecimiento, enfermedades graves o
              invalidez. Text link
            </p>
            <div className="border-t-[#D9D9D9] border-t" />
          </div>
          <div className="flex flex-col gap-[24px]">
            <svg
              width="80"
              height="81"
              viewBox="0 0 80 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask id="path-1-inside-1_8879_416" fill="white">
                <path d="M61 40.708C61.4142 40.708 61.7514 40.3721 61.7366 39.9582C61.6455 37.407 61.09 34.8903 60.0944 32.5281C59.0013 29.9348 57.3992 27.5785 55.3796 25.5936C53.3599 23.6087 50.9622 22.0343 48.3234 20.9601C45.6845 19.8859 42.8563 19.333 40 19.333C37.1437 19.333 34.3155 19.8859 31.6766 20.9601C29.0378 22.0343 26.6401 23.6087 24.6204 25.5936C22.6008 27.5785 20.9987 29.9348 19.9056 32.5282C18.91 34.8903 18.3545 37.407 18.2634 39.9582C18.2486 40.3721 18.5858 40.708 19 40.708L40 40.708H61Z" />
              </mask>
              <path
                d="M61 40.708C61.4142 40.708 61.7514 40.3721 61.7366 39.9582C61.6455 37.407 61.09 34.8903 60.0944 32.5281C59.0013 29.9348 57.3992 27.5785 55.3796 25.5936C53.3599 23.6087 50.9622 22.0343 48.3234 20.9601C45.6845 19.8859 42.8563 19.333 40 19.333C37.1437 19.333 34.3155 19.8859 31.6766 20.9601C29.0378 22.0343 26.6401 23.6087 24.6204 25.5936C22.6008 27.5785 20.9987 29.9348 19.9056 32.5282C18.91 34.8903 18.3545 37.407 18.2634 39.9582C18.2486 40.3721 18.5858 40.708 19 40.708L40 40.708H61Z"
                stroke="var(--theme-primary)"
                stroke-width="3"
                mask="url(#path-1-inside-1_8879_416)"
              />
              <path
                d="M40 40.333V59.083C40 61.1541 41.6789 62.833 43.75 62.833V62.833C45.8211 62.833 47.5 61.1541 47.5 59.083V58.8624"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M40 19.333V17.833"
                stroke="var(--theme-primary)"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>

            <h3 className="H4 text-[#424242] font-bold text-left">
              Beneficios Exclusivos
            </h3>
            <p className="text-[#555555] BodyM text-left">
              Cobertura amplia y capital extra en caso tanto de accidentes
              generales como de tráfico, asegurando que siempre estés protegido.
              Text link
            </p>
            <div className="border-t-[#D9D9D9] border-t" />
          </div>
        </div>
        <ButtonDS
          label="Simula tu precio"
          rightIcon="ArrowRightIcon"
          className="w-fit mx-auto"
          onClick={scrollToCalculator}
        />
      </div>
    </section>
  );
};

const ReviewCards = () => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const [ref] = useDragScroll();

  const cards = () => [
    {
      Date: "05 de agosto de 2024",
      Name: "Jose Antonio Espinosa ",
      Review:
        "Muy amables y bien servicio en cuanto a información a la hora de contratación.",
      ReviewTitle: "Muy amables y bien servicio en cuanto a…",
    },
    {
      Date: "19 de julio de 2024",
      Name: "Antonio",
      Review:
        "Yo he hecho dos pólizas para mis dos hijos y es un precio increíble. Atención telefónica sin problemas. Modificación o ampliación sin problemas. Totalmente recomendable.",
      ReviewTitle: "Un seguro sin sorpresas.",
    },
    {
      Date: "12 de marzo 2024",
      Name: "José Luis",
      Review:
        "Rápido y ágil, un problema puntual solventado con eficacia por Carla, un encanto de gestora, muy profesional en la atención.",
      ReviewTitle: "Rápido y ágil",
    },
    {
      Date: "20 de febrero 2024",
      Name: "Lucía Ruiz",
      Review:
        "Rápido y sencillo de contratar, póliza recibida a los pocos minutos. Precio muy competitivo",
      ReviewTitle: "Rápido y sencillo de contratar",
    },
  ];

  return (
    <section className="overflow-hidden bg-white">
      <div className="ReviewCards-wrapper max-w-content">
        <div className="ReviewCards-labels">
          <h2
            className="H2"
            dangerouslySetInnerHTML={{
              __html: translate("landing.review.title"),
            }}
          ></h2>
          <p className="H4">{translate("landing.review.subtitle")}</p>
        </div>
        <TrustPilotDS
          CurrentRateLabel={"4.8"}
          OutOfLabel={"sobre 5"}
          className={`!w-fit mx-auto`}
          typography={"BodyL"}
          TrustpilotLink={translate("landing.header.linkTrutspilot")}
          ratingFirst={true}
        />
        <div className="ReviewCards-cards" ref={ref as any}>
          {cards().map((card, idx) => {
            const { Date, Name, Review, ReviewTitle } = card;
            return (
              <div
                className="ReviewCards-card"
                style={{ backgroundColor: "var(--theme-quaternary)" }}
                key={`${Name}_${idx}`}
              >
                <TrustPilotLogo />
                <div className="flex flex-col gap-[16px] mt-[16px]">
                  <span className="H4 font-bold text-[#424242]">
                    {ReviewTitle}
                  </span>
                  <p className="BodyL font-normal text-[#555555]">{Review}</p>
                </div>
                <div className="flex flex-col gap-[4px] mt-auto">
                  <span className="BodyL font-bold text-[#555555]">{Name}</span>
                  <FiveStars />
                  <span className="BodyS font-normal text-[#686868]">
                    {Date}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <ButtonDS
          label={translate("landing.review.seeMore")}
          buttonType="tertiary"
          ghost={true}
          href={translate("landing.header.linkTrutspilot")}
          target="_blank"
          className="w-fit mx-auto"
        />
      </div>
    </section>
  );
};

export default LandingSQL;
