import React, { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import apiVelogicaLogin from "../../../api/request/Integrations/apiVelogicaLogin";
import { I18nContext } from "../../../contexts/i18n.context";
import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";

export interface VelogicaI {
  question: any;
}
const VelogicaQuestion: React.FC<VelogicaI> = ({ question }): JSX.Element => {
  const {
    leadId,
    token,
    setLoading,
    setTokenVelogica,
    setQuestion,
    tokenVelogica,
    DOMAIN,
  } = useContext(ContextGetlife);

  const [confirmEdit, setConfirmEdit] = useState<boolean>(false);
  const [warning, setWarning] = useState<boolean>(false);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const fetchVelogica = async () => {
    try {
      const data = await apiVelogicaLogin(leadId, token);
      setTokenVelogica(data.access_token);
      setQuestion(question.questionId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchVelogica();
  }, []);

  const renderVelogica = () => {
    if (confirmEdit && question.value !== "answered") {
      return (
        <ModalDS
          open={confirmEdit}
          closeCallback={() => {
            setWarning(true);
          }}
          title={translate("question.velogica.modal.title")}
          icon="ActivityIcon"
          content={
            <iframe
              width={"100%"}
              height={"100%"}
              src={`${process.env.REACT_APP_VELOGICA_IFRAME}${tokenVelogica}`}
              title="Velogica form"
            />
          }
          fitHeight={true}
          fitWidth={true}
        />
      );
    } else if (question.value === "answered") {
      return (
        <div className="flex flex-row justify-between items-center gap-[24px]">
          <span className="BodyL font-bold text-[var(--dark-primary-color)]">
            {translate("question.velogica.modal.title")}
          </span>
          <span className="BodyM font-bold text-[#424242] flex items-center flex-row">
            <GlobalIcon
              iconName="CircleCheckFilledIcon"
              size="XS"
              color="#1A9174"
            />
            {translate("velogica.alert.completed")}
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row w-full gap-[2px] border-[2px] border-[#5D5AE9] rounded-[8px] p-[24px_16px] bg-white">
          <GlobalIcon
            iconName="InfoIcon"
            size="XS"
            className="w-[28px] h-[28px] p-[4px]"
          />
          <div className="flex-1">
            <div className="flex flex-col gap-[16px] mb-[12px]">
              <span className="BodyL font-bold text-[#3430E3]">
                {translate("velogica.alert.title")}
              </span>
              <span className="BodyM font-medium text-[#686868]">
                {translate("velogica.alert.subtitle")}
              </span>
            </div>
            <div className="flex flex-row-reverse">
              <ButtonDS
                label={translate("landing.calculator.buttonContinue")}
                rightIcon="ArrowGoRightIcon"
                size="32"
                onClick={() => setConfirmEdit(true)}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {renderVelogica()}
      <ModalDS
        title={translate("velogica.alert.warning.title")}
        icon="AlertTriangleIcon"
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: translate("velogica.alert.warning.text"),
            }}
          />
        }
        open={warning}
        onClose={() => {
          setWarning(false);
        }}
        buttons={
          <>
            <ButtonDS
              label={translate("velogica.alert.warning.cancel")}
              buttonType="primary"
              onClick={() => {
                setWarning(false);
              }}
              ghost
            />
            <ButtonDS
              label={translate("velogica.alert.warning.exit")}
              buttonType="primary"
              onClick={() => {
                setWarning(false);
                setConfirmEdit(false);
              }}
            />
          </>
        }
      />
    </>
  );
};

export default VelogicaQuestion;
