import { useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { GuaranteeT } from "../../dashboard/multiproduct/PricingSummary";
import { I18nContext } from "../../../contexts/i18n.context";
import { getTemplates } from "../../../api/request/apiGetDownloadableFiles";
import apiLeadSendDocument from "../../../api/request/apiLeadSendDocument";
import { ModalDataI } from "../EditProjects.types";
import { SQLBoundariesResponse } from "../../../api/request/Lead/Model/Response/SQLBoundariesResponse";
import { SQLRulesResponse } from "../../../api/request/Lead/Model/Response/SQLRulesResponse";
import REGEX from "../../../utils/regExp";
import { SQLPricingResponse } from "../../../api/request/Lead/Model/Response/SQLPricingResponse";
import {
  BasicBoundariesI,
  GuaranteeFormI,
  ToggleGuaranteeActiveT,
  UpdateGuaranteeCapitalT,
} from "../../dashboard/multiproduct/MultiproductData";
import { getTrackingUtms } from "../../../api/request/apiLeadTrackingGet";
import { extractFromString } from "../../../utils/extractFromString";
import { GoogleContext } from "../../../contexts/GoogleTagManager.context";
import { useNavigate } from "react-router";
import { getValidateEmail } from "../../../utils/staticData";

interface BasicFormI {
  email?: string;
  phone?: string;
  referenceName?: string;
  birthDate?: string;
}

type EditBasicFormT = <K extends keyof BasicFormI>(
  field: K,
  value: BasicFormI[K]
) => void;

export type BasicFormErrorsI = {
  [key in keyof BasicFormI]?: string;
};

const EditSQLProjectController = (
  modalData: ModalDataI,
  showModal: boolean,
  fetchProjects?: (p: any) => void
) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {
    token,
    setShowNotification,
    setLeadId,
    setTokenVelogica,
    broker,
    brokerId,
  } = useContext(ContextGetlife);
  const { handleTrackerQuestion } = useContext(GoogleContext);
  const leadRepository = new HttpLeadRepository(token);

  const [basicBoundaries, setBasicBoundaries] = useState<BasicBoundariesI>({
    min: undefined,
    max: undefined,
    available: false,
  });
  const [basicForm, setBasicForm] = useState<BasicFormI>({
    email: undefined,
    phone: undefined,
    referenceName: undefined,
    birthDate: undefined,
  });
  const [basicFormError, setBasicFormError] = useState<BasicFormErrorsI>({});
  const [guaranteeForm, setGuaranteeForm] = useState<GuaranteeFormI>({
    DEATH: {
      active: true,
      capital: 175000,
    },
    DISABILITY: {
      active: false,
      capital: 0,
    },
    TEMPORARY_DISABILITY: {
      active: false,
      capital: 0,
    },
    SERIOUS_ILLNESS: {
      active: false,
      capital: 0,
    },
    ACCIDENT_DISABILITY: {
      active: false,
      capital: 0,
    },
    TRAFFIC_ACCIDENT_DISABILITY: {
      active: false,
      capital: 0,
    },
    ACCIDENT_DEATH: {
      active: false,
      capital: 0,
    },
    TRAFFIC_ACCIDENT_DEATH: {
      active: false,
      capital: 0,
    },
  });
  const [rules, setRules] = useState<SQLRulesResponse | undefined>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState<
    Pick<Record<GuaranteeT, boolean>, "basic" | "additional" | "accidental">
  >({
    basic: true,
    additional: true,
    accidental: true,
  });
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [sendOTP, setSendOTP] = useState<boolean>(false);
  const [OTP, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadablePdfs, setDownloadablePdfs] = useState<
    [{ template: string; url: string }] | []
  >([]);
  const [documentSelected, setDocumentSelected] = useState("");
  const [pricing, setPricing] = useState<SQLPricingResponse | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const getBoundaries = async () => {
    leadRepository
      .getSQLBoundaries({
        birthdate: basicForm.birthDate ?? modalData.birthDate,
      })
      .then((response: SQLBoundariesResponse) => {
        setBasicBoundaries(response);
      })
      .catch(() => {
        setBasicBoundaries({ min: 0, max: 0, available: false });
      });
  };

  let allDocumentsArray: string = "";
  const parseDocuments: { label: string; value: string }[] = [];
  downloadablePdfs.map((key) => {
    let position = {
      label: translate(`document.download.${key.template}`),
      value: key.template,
    };
    parseDocuments.push(position);
    if (key.template === downloadablePdfs[0]?.template) {
      allDocumentsArray = allDocumentsArray.concat("", key.template);
    } else {
      allDocumentsArray = allDocumentsArray.concat(",", key.template);
    }
  });

  let allDocuments = {
    label: translate(`document.download.all`),
    value: allDocumentsArray,
  };

  parseDocuments.push(allDocuments);

  const updateCheckpoint = () => {
    navigate("/questions");
  };

  const getRules = async (_leadId: string) => {
    await leadRepository
      .getSQLRules(_leadId)
      .then((response: SQLRulesResponse) => {
        setRules(response);
      });
  };

  const toggleGuaranteeActive: ToggleGuaranteeActiveT = (
    key: keyof GuaranteeFormI
  ) => {
    setGuaranteeForm(() => {
      const isActive = guaranteeForm[key].active;
      const trait = rules?.find((e) => e.trait === key);
      const capital = isActive ? 0 : trait?.min || 0;
      const guarantee = {
        ...guaranteeForm,
        [key]: {
          active: !guaranteeForm[key].active,
          capital: capital,
        },
      };

      updateComplementaryGuarantees(key, capital);

      return guarantee;
    });
  };

  const updateComplementaryGuarantees = async (
    trait: keyof GuaranteeFormI,
    capital: number
  ) => {
    if (modalData.leadId) {
      try {
        setLoading(true);
        await leadRepository.updateAdditionalBoundariesFromCheckpoint(
          modalData.leadId,
          {
            trait: trait,
            capital: capital,
          }
        );
        if (trait === "DEATH") {
          await getRules(modalData.leadId);
          await leadRepository.postReducedOrganicSQL(
            {
              leadId: modalData.leadId,
              brokerId: brokerId,
              capital: capital,
              birthDate: modalData.birthDate,
              referenceName: basicForm.referenceName,
              phone: basicForm.phone,
            },
            "put"
          );
        }
        await getRules(modalData.leadId);
        await getPricing(modalData.leadId);
        if (fetchProjects) {
          fetchProjects({});
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  const getPricing = async (_leadId: string) => {
    await leadRepository
      .getPricingCapitals(_leadId)
      .then((response: SQLPricingResponse) => {
        setPricing(response);
        setGuaranteeForm(response.capitals as GuaranteeFormI);
      });
  };

  const updateGuaranteeCapital: UpdateGuaranteeCapitalT = (
    key: keyof GuaranteeFormI,
    capital: number,
    callback = false
  ) => {
    setGuaranteeForm((prevGuarantee) => {
      const guarantee = {
        ...prevGuarantee,
        [key]: { ...prevGuarantee[key], capital },
      };
      if (callback) {
        updateComplementaryGuarantees(key, capital);
      }
      return guarantee;
    });
  };

  const toggleOpen = (guarantee: GuaranteeT) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [guarantee as keyof typeof open]:
        !prevOpen[guarantee as keyof typeof open],
    }));
  };

  const editBasicForm: EditBasicFormT = (field, value) => {
    setBasicForm({ ...basicForm, [field]: value });
  };

  const sendEmailDocument = async (
    email: string,
    text: string,
    template: string
  ) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      setLoading(true);
      await apiLeadSendDocument(
        token,
        modalData.leadId,
        type,
        text,
        email,
        resource,
        template.includes(",") ? template.split(",") : [template]
      );
      setLoading(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setLoading(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  const validateBasicForm = () => {
    const _error: any = {};
    const { email, phone, referenceName } = basicForm;

    if (email) {
      const isValidEmail = email.match(REGEX["EMAIL"]);
      if (!isValidEmail) {
        _error["email"] = translate(
          "dashboard.multiproduct.validate.wrongEmail"
        );
      }
    }

    if (phone) {
      const isValidPhone = phone.match(REGEX["PHONE"]);
      if (!isValidPhone) {
        _error["phone"] = translate(
          "dashboard.multiproduct.validate.wrongPhone"
        );
      }
    }

    if (!referenceName && !phone && !email) {
      _error["referenceName"] = translate(
        "dashboard.multiproduct.error.atLeastOneMandatory"
      );
      _error["phone"] = translate(
        "dashboard.multiproduct.error.atLeastOneMandatory"
      );
      _error["email"] = translate(
        "dashboard.multiproduct.error.atLeastOneMandatory"
      );
    }

    setBasicFormError(_error);
    return _error;
  };

  const saveChanges = async () => {
    const errors = validateBasicForm();
    const hasErrors = Object.keys(errors).length > 0;

    if (!hasErrors) {
      const { birthDate, email, phone, referenceName } = basicForm;
      await leadRepository.putLeadUpdatesSQL(modalData.leadId, {
        referenceName,
        phone,
        email,
        birthDate,
      });
      if (fetchProjects) {
        fetchProjects({});
      }
      setShowNotification({
        message: translate("edit.project.saved"),
        type: "success",
        status: true,
      });
    }
  };

  const fetchDownloadables = async (leadId: string, coverage: string) => {
    const downloadablePdfs = await leadRepository.getAvailableDocuments(leadId);
    setDownloadablePdfs(
      downloadablePdfs as [{ template: string; url: string }]
    );
  };

  const generateOTP = async () => {
    const response = await leadRepository.getOtp(modalData.leadId);
    setSendOTP(true);
    if (response.otpUrl) {
      setOTP(response.otpUrl);
    }
  };

  const recoverProject = async () => {
    try {
      setLeadId(modalData.leadId);
      setTokenVelogica("");
      const utms = await getTrackingUtms(modalData.leadId);

      const event = "recoverLead";
      const eventData = {
        page: window.location.href,
        leadId: modalData.leadId,
        coverage: "",
        intent: undefined,
        calculatedPremium: pricing?.prices.monthly.promoted,
        paymentMethod: undefined,
        calculatedCapital: guaranteeForm["DEATH"].capital,
        gender: "",
        postalCode: "",
        brokerEmployeeId: brokerId,
        brokerId: broker?.brokerageId,
        utm_source: utms.utm_source ?? "brokerapp",
        utm_medium: utms.utm_medium ?? broker?.brokerageId,
        utm_campaign: utms.utm_campaign ?? brokerId,
        utm_content: utms.utm_content,
        utm_term: utms.utm_term,
        utm_test: utms.utm_test,
        fbclid: utms.fbclid,
        gclid: utms.gclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        ABTestVersion: utms.ABTestVersion,
      };

      const cookies = document.cookie;

      const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
      const GAID = extractFromString(cookies, "_ga");
      const FullStoryID = extractFromString(cookies, "fs_uid");
      const AT_GD = extractFromString(cookies, "at_gd");

      const dataTracker = {
        leadUlid: modalData.leadId,
        campaign: "broker-projects",
        googleAnalyticsId: GAID,
        fullstoryId: FullStoryID,
        vwoId: VWOID,
        tid: null,
        atGd: AT_GD,
        utmCampaign: utms.utm_campaign,
        utmSource: utms.utm_source,
        utmMedium: utms.utm_medium && utms.utm_medium.toString(),
        utmContent: utms.utm_content,
        utmTerm: utms.utm_term,
        utmTest: utms.utm_test,
        gclid: utms.gclid,
        fbclid: utms.fbclid,
        entryPage: utms.entryPage,
        referrerUrl: utms.referrerUrl,
        aBTestVersion: utms.ABTestVersion,
      };

      await leadRepository.saveTracking({ data: dataTracker });

      handleTrackerQuestion({
        type: "FS",
        event: event,
        data: {
          birthDate: new Date(basicForm.birthDate!),
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event: event,
          birthDate: new Date(basicForm.birthDate!),
          ...eventData,
        },
      });

      navigate("/questions");
    } catch (e: any) {
      setShowNotification({
        message: translate(`errors.projects.${e.message}`),
        status: true,
        type: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  useEffect(() => {
    let resetTimer: ReturnType<typeof setTimeout> | null = null;

    if (showModal === false) {
      resetTimer = setTimeout(() => {
        setSendEmail(false);
        setSendOTP(false);
        setEditMode(false);
        setBasicFormError({});
      }, 200);
    }

    return () => {
      if (resetTimer) clearTimeout(resetTimer);
    };
  }, [showModal]);

  useEffect(() => {
    (async () => {
      if (modalData && Object.keys(modalData).length > 0) {
        setBasicForm({
          birthDate: modalData.birthDate,
          referenceName: modalData.referenceName,
          phone: modalData.phone,
          email: modalData.email,
        });
        setEmail(getValidateEmail(modalData.email));
        await getBoundaries();
        await getRules(modalData.leadId);
        await getPricing(modalData.leadId);
        await fetchDownloadables(modalData.leadId, modalData.coverage);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  return {
    basicBoundaries,
    basicForm,
    editBasicForm,
    guaranteeForm,
    toggleGuaranteeActive,
    updateGuaranteeCapital,
    rules,
    editMode,
    setEditMode,
    toggleOpen,
    open,
    sendEmail,
    setSendEmail,
    sendOTP,
    setSendOTP,
    email,
    setEmail,
    text,
    setText,
    loading,
    sendEmailDocument,
    basicFormError,
    saveChanges,
    downloadablePdfs,
    pricing,
    generateOTP,
    OTP,
    recoverProject,
    updateCheckpoint,
    setShowNotification,
    parseDocuments,
    documentSelected,
    setDocumentSelected,
  };
};

export default EditSQLProjectController;
