import React from "react";

interface variants {
  h1: "h1";
  h2: "h2";
  h3: "h3";
  h4: "h4";
  h5: "h5";
  h6: "h6";
  subheading: "h6";
  paragraph: "p";
  bold: "b";
  italic: "i";
  span: "span";
}

const variantMap: variants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subheading: "h6",
  paragraph: "p",
  bold: "b",
  italic: "i",
  span: "span",
};

export type VariantType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subheading"
  | "paragraph"
  | "bold"
  | "italic"
  | "span";

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: VariantType;
  text: string | number;
  typeText?: "primary" | "secondary";
  onClick? : () => void;
}

const Typography: React.FC<TypographyProps> = ({
  variant,
  children,
  className,
  itemRef,
  text,
  typeText = "primary",
  onClick
}): JSX.Element => {
  const Component = variantMap[variant];
  return (
    <Component itemRef={itemRef} className={`text ${typeText} ${className}`} onClick={onClick && onClick}>
      {text} {children}
    </Component>
  );
};

export default Typography;
