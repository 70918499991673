import { post, put, get } from "../../core/HTTPService.service";
import InviteEmployeesResponse, { InfoEmployeesResponse } from "./Model/Response/InviteEmployeesResponse.model";
import EmployeeRequest from "./Model/Resquest/EmployeeRequest.model";

export default class HttpEmployeeRepository {
  private readonly API_URL_EMPLOYEE:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/employee`;

  private readonly API_URL_BROKER_EMPLOYEE:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/broker/employee`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }
  editEmployee = async (data: EmployeeRequest): Promise<void> => {
    try {
      await put(`${this.API_URL_EMPLOYEE}/update`, data, this.header);
    } catch (e) {
      throw e;
    }
  };

  inviteEmployee = async (data: string[]): Promise<InviteEmployeesResponse> => {
    let result: InviteEmployeesResponse | null = null;
    try {
      const response: InviteEmployeesResponse = await post(
        `${this.API_URL_EMPLOYEE}/inviteEmployees`,
        data,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }
    return result;
  };

  getInfoEmoployee = async (brokerUlid: string): Promise<InfoEmployeesResponse> => {
    let result: InfoEmployeesResponse | null = null;
    try {
      const response: InfoEmployeesResponse = await get(
        `${this.API_URL_BROKER_EMPLOYEE}/data/${brokerUlid}`,
        this.header
      );
      result = response;
    } catch (e) {
      throw e;
    }
    return result;
  };
}
