// Vendors
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api

import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";
import { useLoginOtpContext } from "../../context/login-otp.context";
// Handlers
import LoginOtpRequestViewHandlers from "../handlers/login-otp-request.handlers";
import { I18nContext } from "../../../../contexts/i18n.context";

const LoginOtpController = () => {
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    contactValue,
    inputType,
    setContactValue,
    setInputType,
    setRequested,
  } = useLoginOtpContext();

  const brokerRepository = new HttpBrokerRepository();

  const { handleClickSubmit, handleKeyDown } = LoginOtpRequestViewHandlers({
    brokerRepository,
    contactValue,
    navigate,
    inputType,
    setContactValue,
    setError,
    setErrorMessage,
    setRequested,
  });

  return {
    contactValue,
    error,
    errorMessage,
    handleClickSubmit,
    handleKeyDown,
    inputType,
    setContactValue,
    setInputType,
    translate
  };
};

export default LoginOtpController;
