const KEYS = {
  KEY_A: "KeyA",
  KEY_BACK: "Backspace",
  KEY_C: "KeyC",
  KEY_CTRL_LEFT: "ControlLeft",
  KEY_CTRL_RIGHT: "ControlRight",
  KEY_DEL: "Del",
  KEY_DOWN: "ArrowDown",
  KEY_ENTER: "Enter",
  KEY_ESC: "Escape",
  KEY_LEFT: "ArrowLeft",
  KEY_MINUS: "-",
  KEY_POINT: ".",
  KEY_RIGHT: "ArrowRight",
  KEY_TAB: "Tab",
  KEY_UP: "ArrowUp",
  KEY_V: "KeyV",
};

export default KEYS;
