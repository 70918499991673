import React from "react";
import "./question.scss";
import { I18nContext } from "../../../contexts/i18n.context";
import { GlobalIcon } from "get-life-storybook-ts";

export default function QuestionAlert(props) {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className="flex flex-row w-full gap-[2px] border-[2px] border-[#EA5F5F] rounded-[8px] p-[24px_16px] bg-white">
          <GlobalIcon
            iconName="InfoIcon"
            size="XS"
            className="w-[28px] h-[28px] p-[4px]"
            color="#EA5F5F"
          />
          <div>
            <div className="flex flex-col gap-[16px] mb-[12px]">
            <span
            dangerouslySetInnerHTML={{
              __html: props.telesubscription ? translate("questions.question.alerttelesub") : translate("questions.question.alert"),
            }}
          />
            </div>
          </div>
        </div>
    
  );
}
