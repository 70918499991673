import { ListProjectsReference } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

export interface columnsI {
    name: string;
    reference: ListProjectsReference;
    width?: string;
    borderLeft? : boolean
}

export const columns: columnsI[] = [
    {
        name: "tables.headers.email",
        reference: "email",
        width: "320px"
    },
    {
        name: "tables.headers.name",
        reference: "nameList",
        width: "204px"
    },
    {
        name: "tables.headers.phone",
        reference: "phone",

    },
    {
        name: "tables.headers.status",
        reference: "status",
        borderLeft: true
    },
    {
        name: "tables.headers.annualPrice",
        reference: "annualPrice",
    },
    {
        name: "tables.headers.effectDate",
        reference: "effectDate",
    },
    {
        name: "tables.headers.actions",
        reference: "actions",
        width: "150px",
        borderLeft: true
    }
];

export const columnsAdmin: columnsI[] = [
    {
        name: "tables.headers.email",
        reference: "email",
        width: "320px"
    },
    {
        name: "tables.headers.name",
        reference: "nameList",
        width: "204px"
    },
    {
        name: "tables.headers.phone",
        reference: "phone",
    },
    {
        name: "tables.headers.status",
        reference: "status",
        borderLeft: true
    },
    {
        name: "tables.headers.annualPrice",
        reference: "annualPrice",
    },
    {
        name: "tables.headers.effectDate",
        reference: "effectDate",
    },
    {
        name: "tables.headers.owner",
        reference: "proprietor",
    },
    {
        name: "tables.headers.actions",
        reference: "actions",
        width: "150px",
        borderLeft: true
    } 
];

export const columnsIsGetlife: columnsI[] = [
    {
        name: "tables.headers.email",
        reference: "email",
        width: "320px"
    },
    {
        name: "tables.headers.name",
        reference: "nameList",
        width: "204px"
    },
    {
        name: "tables.headers.phone",
        reference: "phone",
    },
    {
        name: "tables.headers.status",
        reference: "status",
        borderLeft: true
    },
    {
        name: "tables.headers.annualPrice",
        reference: "annualPrice",
    },
    {
        name: "tables.headers.effectDate",
        reference: "effectDate",
    },
    {
        name: "tables.headers.market",
        reference: "market",
    },
    {
        name: "tables.headers.actions",
        reference: "actions",
        width: "150px",
        borderLeft: true
    } 
];