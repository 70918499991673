import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import { useEffect, useRef, useState } from "react";
import { ButtonDS, GlobalIcon, ModalDS } from "get-life-storybook-ts";
import { FileIcon, FileXIcon } from "./IdentificationIcons";

interface PhotoIDDSI {
  image: string;
  setImage: (image: string) => void;
  onLoadCallback: (base64: string) => Promise<void>;
  deleteFile: () => Promise<void>;
  error?: boolean;
  isRemovable?: boolean;
  translations: PhotoIDDSTranslationsT;
}

export type PhotoIDDSTranslationsT = {
  steps: string;
  validations: string;
  uploading: string;
  loading: string;
  completed: string;
  remove: string;
  see: string;
  cancel: string;
  delete: string;
  modal: string;
  deleteConfirmation: string;
  errorFormat: string;
  errorSize: string;
};

const PhotoIDDS = ({
  image,
  setImage,
  onLoadCallback,
  deleteFile,
  error = false,
  isRemovable = true,
  translations,
}: PhotoIDDSI) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  // Progress in MB
  const [fileName, setFileName] = useState("");
  const [uploadingProgress, setUploadingProgress] = useState({
    total: "0",
    loaded: "0",
  });
  const [previewModal, setPreviewModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileError, setFileError] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const bytesToMegabytes = (bytes: number) => (bytes / 1000000).toFixed(2);

  const preventDefaults = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const highlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.classList.add("PhotoIDDS-dragOver");
  };

  const unhighlightFileInput = (e: React.DragEvent) => {
    const target = e.target as HTMLElement;
    target.classList.remove("PhotoIDDS-dragOver");
  };

  const handleOnDropImage = (e: React.DragEvent) => {
    let dt = e.dataTransfer;
    let files = dt.files;
    handleFile(files);
  };

  const triggerInputFile = () => fileInputRef.current?.click();

  const handleFile = (files: FileList) => {
    setFileError("");
    if (files.length >= 1) {
      const imageTypes = ["image/png", "image/jpeg"];
      const file = files[0];
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (!imageTypes.includes(file.type)) {
        setFileError(translations.errorFormat);
        return;
      }
      if (file.size > maxSizeInBytes) {
        setFileError(translations.errorSize);
        return;
      }
      let img = new Image();
      const imgUrl = URL.createObjectURL(file);
      img.src = imgUrl;
      setFileName(file.name);
      img.onload = () => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = (e) => {
          setUploadingImage(true);
          setUploadingProgress({
            ...uploadingProgress,
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onprogress = (e) => {
          setUploadingProgress({
            loaded: bytesToMegabytes(e.loaded),
            total: bytesToMegabytes(e.total),
          });
        };
        reader.onload = async () => {
          await onLoadCallback(reader.result as string);
          setUploadingImage(false);
          setImage(reader.result as string);
        };
      };
    }
  };

  const handleFileInput = () => {
    if (fileInputRef.current?.files) {
      handleFile(fileInputRef.current?.files);
    }
  };

  const getFileName = () => {
    if (fileName) return fileName;
    const imageNameSplit = image.split("/");
    if (imageNameSplit.length > 0) {
      return imageNameSplit[imageNameSplit.length - 1];
    }
    return "";
  };

  useEffect(() => {
    setUploadingImage(false);
    setIsDeleting(false);
  }, [image]);

  if (image) {
    return (
      <>
        {isDeleting ? (
          <div className="border border-[#D9D8FD] bg-[#ECF0FF] p-[16px] min-h-[116px] rounded-[8px] flex flex-col items-start gap-[12px]">
            <div className="flex flex-row gap-[8px] items-center">
              <FileIcon />
              <div className="flex flex-col gap-[4px] flex-1">
                <p className="BodyL text-[#424242] font-bold">
                  {translations.deleteConfirmation}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-[16px] w-full">
              <ButtonDS
                label={translations.cancel}
                buttonType="secondary"
                size="32"
                className="flex-1"
                onClick={(e) => {
                  (e.target as HTMLElement).blur();
                  setIsDeleting(false);
                }}
              />
              <ButtonDS
                label={translations.delete}
                buttonType="tertiary"
                size="32"
                className="flex-1"
                onClick={deleteFile}
              />
            </div>
          </div>
        ) : (
          <div
            className="border border-[#D9D9D9] bg-[#ffffff] p-[16px] min-h-[116px] rounded-[8px] flex flex-col items-start gap-[12px]"
            style={{
              backgroundColor: error ? "#FCEBEB" : undefined,
              borderColor: error ? "#E84B4B" : undefined,
            }}
          >
            <div className="flex flex-row gap-[8px] w-full items-center">
              {error ? <FileXIcon /> : <FileIcon />}
              <div className="flex flex-col gap-[4px] flex-1 min-w-0">
                <p className="BodyL text-[#424242] font-bold text-ellipsis overflow-hidden max-w-full">
                  {getFileName()}
                </p>
                {uploadingProgress.total !== "0" ? (
                  <div className="flex flex-row items-center gap-[4px]">
                    <span
                      className="text-[#7B7B7B] BodyS"
                      dangerouslySetInnerHTML={{
                        __html: translations.uploading
                          .replace("{{loaded}}", uploadingProgress.loaded)
                          .replace("{{total}}", uploadingProgress.total),
                      }}
                    />
                    <span className="text-[#424242] BodyS">
                      {translations.completed}
                    </span>
                  </div>
                ) : undefined}
              </div>
            </div>
            <div className="flex flex-row gap-[16px] ml-auto">
              <ButtonDS
                label={translations.remove}
                rightIcon="TrashIcon"
                buttonType="secondary"
                size="32"
                onClick={(e) => {
                  (e.target as HTMLElement).blur();
                  setIsDeleting(true);
                }}
                disabled={!isRemovable}
              />
              <ButtonDS
                label={translations.see}
                rightIcon="EyeIcon"
                buttonType="secondary"
                size="32"
                onClick={() => setPreviewModal(true)}
              />
            </div>
          </div>
        )}
        <ModalDS
          open={previewModal}
          onClose={() => setPreviewModal(false)}
          title={translations.modal}
          icon="IdCardIcon"
          content={
            <div className="w-full flex justify-center items-center">
              <picture className="max-w-[473px]">
                <img src={image} alt="" />
              </picture>
            </div>
          }
        />
      </>
    );
  }

  if (uploadingImage) {
    return (
      <div className="border border-[#D9D9D9] bg-[#ffffff] p-[16px] min-h-[116px] rounded-[8px] flex flex-col justify-center">
        <div className="flex flex-row gap-[8px]">
          <FileIcon />
          <div className="flex flex-col gap-[4px] flex-1 min-w-0">
            <p className="BodyL text-[#424242] font-bold text-ellipsis overflow-hidden max-w-full">
              {getFileName()}
            </p>
            {uploadingProgress.total !== "0" ? (
              <div className="flex flex-row items-center gap-[4px]">
                <span
                  className="text-[#7B7B7B] BodyS"
                  dangerouslySetInnerHTML={{
                    __html: translations.uploading
                      .replace("{{loaded}}", uploadingProgress.loaded)
                      .replace("{{total}}", uploadingProgress.total),
                  }}
                />
                <Spinner width={"18px"} height={"18px"} />
                <span className="text-[#424242] BodyS">
                  {translations.loading}
                </span>
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <input
        type="file"
        id="logoFileInput"
        accept="image/jpeg, image/png"
        onChange={handleFileInput}
        className="hidden"
        ref={fileInputRef}
      />

      <div
        className="text-[#424242] flex flex-col gap-[4px] p-[16px] border-[2px] border-dashed border-[var(--primary-color)] bg-[var(--light-primary-color)] rounded-[8px] cursor-pointer [&>*]:pointer-events-none"
        onDragEnter={(e) => {
          preventDefaults(e);
          highlightFileInput(e);
        }}
        onDragLeave={(e) => {
          preventDefaults(e);
          unhighlightFileInput(e);
        }}
        onDragOver={(e) => {
          highlightFileInput(e);
          preventDefaults(e);
        }}
        onDrop={(e) => {
          preventDefaults(e);
          unhighlightFileInput(e);
          handleOnDropImage(e);
        }}
        onClick={triggerInputFile}
      >
        <p
          className="BodyL font-bold"
          dangerouslySetInnerHTML={{ __html: translations.steps }}
        />
        <p
          className="BodyM font-normal"
          dangerouslySetInnerHTML={{ __html: translations.validations }}
        />
      </div>
      {fileError ? (
        <div className="text-[#EA5F5F] BodyS flex flex-row items-center gap-[8px] mt-[16px]">
          <GlobalIcon
            iconName="AlertCircleIcon"
            color="currentColor"
            size="XXS"
          />
          <span>{fileError}</span>
        </div>
      ) : undefined}
    </div>
  );
};

export default PhotoIDDS;
