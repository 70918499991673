async function apiBrokerGet(brokerId, token) {
	const bearer = 'Bearer ' + token;
	const response = await fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/broker/${brokerId}`,
		{
			headers: {
				Authorization: bearer,
			},
		}
	);
	return await response.json();
}

export default apiBrokerGet;