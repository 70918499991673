import { useEffect, useState } from "react";
import Select from "react-select";
import selectGetlifeStyle from "../select/SelectGetlifeStyle.js";
import "../select/css/selectGetLife.scss";
import InfoMessage from "../../Rebranding/atoms/InfoMessage/InfoMessage.component";

const SelectGeneral = ({
  className,
  disabled,
  editValue,
  error,
  errorType,
  handleValues,
  isMultiple,
  label,
  placeholder,
  toMap,
  width,
}) => {
  const [value, setValue] = useState(editValue);
  useEffect(() => {
    if (value !== editValue) {
      setValue(editValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editValue]);

  return (
    <div className={`selectGetLife-container ${className}`}>
      {label && label !== "" && (
        <div style={{ paddingBottom: "8px" }}>
          <label
            style={disabled ? { color: "grey" } : {}}
            className="selectGetLife-label"
          >
            {label}
          </label>
        </div>
      )}
      <Select
        className={className}
        style={{ width: width ? width : "" }}
        options={toMap}
        isMulti={isMultiple}
        isClearable={false}
        isSearchable
        isDisabled={disabled}
        value={value}
        styles={selectGetlifeStyle}
        onChange={(e) => {
          setValue(e);
          handleValues(e);
        }}
        placeholder={placeholder ? placeholder : ""}
      />
      {error && (
        <InfoMessage message={error} type={errorType ? errorType : "error"} />
      )}
    </div>
  );
};

export default SelectGeneral;
