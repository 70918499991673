import * as React from "react";
import InfoIcon from "../../atoms/Icons/InfoIcon";
import Typography from "../../atoms/Typography/Typography.component";

import "./InfoBox.styles.scss";

interface InfoBoxI {
  className?: string;
  icon?: React.ReactNode;
  infoIcon?: boolean;
  onClick?: (value?: any) => void;
  text: string;
}

const InfoBox: React.FC<InfoBoxI> = ({
  className,
  icon,
  infoIcon = true,
  onClick,
  text,
}): JSX.Element => {
  return (
    <div className={`infoBox ${className}`}>
      {icon}
      <p dangerouslySetInnerHTML={{__html: text}}></p>
      {infoIcon && <InfoIcon onClick={onClick} />}
    </div>
  );
};

export default InfoBox;
