import * as React from "react";
import { IconProps } from "./IconProps.interface";

const TicketIcon: React.FC<IconProps> = ({
  className,
  color = "#423EF4",
  height = "25",
  width = "24",
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 4.93044L19.2714 0.212891H13.3137L10.9658 2.55316C5.12766 2.06605 0.420321 5.35034 0.35438 5.39756C0.163364 5.5337 0.118694 5.79959 0.255681 5.99103C0.338639 6.10675 0.469245 6.16886 0.601978 6.16886C0.687489 6.16886 0.773851 6.14291 0.848726 6.0893C0.90786 6.04719 4.98047 3.20662 10.1575 3.35849L2.55213 10.9392L2.55554 10.9426L0 13.4917L10.6978 24.2129L14.9691 19.9531C15.8463 20.4126 16.8669 20.6385 17.8764 20.638C19.3658 20.638 20.8267 20.1539 21.7482 19.2324C24.8823 16.0979 23.6384 12.6596 23.213 11.7309L23.9996 10.946L24 4.93044ZM18.92 1.06374L23.1491 5.28355V10.5929L14.8372 18.8776C13.0997 17.632 12.5709 15.2947 13.3512 12.2299C13.4094 12.0023 13.2716 11.7704 13.044 11.7126C12.8155 11.6518 12.585 11.7921 12.5267 12.0197C11.4252 16.3451 12.9053 18.4781 14.228 19.4847L13.2525 20.4572L3.75907 10.9438L11.2806 3.44188C13.0665 3.64608 14.9525 4.23572 16.8307 5.42734C16.8141 5.52136 16.8018 5.61708 16.8018 5.71578C16.8018 6.63896 17.5527 7.38983 18.4758 7.38983C19.399 7.38983 20.1503 6.63896 20.1503 5.71578C20.1503 4.79261 19.399 4.04173 18.4758 4.04173C17.9585 4.04173 17.5012 4.28252 17.194 4.65264C15.4545 3.56482 13.7111 2.95859 12.0387 2.68632L13.6651 1.06374H18.92ZM17.7888 6.08377C17.9768 6.22502 18.2436 6.18715 18.3839 5.99869C18.5252 5.81108 18.4869 5.54433 18.2989 5.40352C18.1674 5.30524 18.0355 5.21846 17.9041 5.12614C18.0525 4.98192 18.2538 4.89258 18.4758 4.89258C18.9298 4.89258 19.2995 5.26185 19.2995 5.71621C19.2995 6.16971 18.9302 6.53941 18.4758 6.53941C18.1342 6.53941 17.8411 6.3301 17.7165 6.03315C17.7407 6.05059 17.765 6.06548 17.7888 6.08377ZM1.20311 13.4934L3.15666 11.545L12.653 21.0618L10.6995 23.0102L1.20311 13.4934ZM21.1467 18.6309C20.0508 19.7259 17.5858 20.2254 15.6034 19.3205L22.5697 12.3724C22.993 13.4258 23.678 16.0992 21.1467 18.6309Z"
        fill={color}
      />
    </svg>
  );
};

export default TicketIcon;
