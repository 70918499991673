import * as React from "react";
import InfoMessage, {
  InfoMessageProps,
} from "../../atoms/InfoMessage/InfoMessage.component";
import InputBase, {
  InputBaseProps,
} from "../../atoms/Input/InputBase.component";

import "./InputGeneral.styles.scss";

interface InputGeneralProps extends InputBaseProps {
  error: string;
  errorType?: InfoMessageProps["type"] | undefined;
}

const InputGeneral: React.FC<InputGeneralProps> = ({
  className,
  classNameLabel,
  disabled,
  editValue,
  error,
  errorType,
  id,
  isNumber,
  label,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  setter,
  setterOnBlur,
  type,
}): JSX.Element => {
  const classNameType = (): string => {
    if (error) {
      return `inputGeneral-input-${errorType || "error"}`;
    }
    return "";
  };
  return (
    <div className="inputGetLife-container">
      <InputBase
        className={`${classNameType()} ${className}`}
        classNameLabel={classNameLabel}
        disabled={disabled}
        editValue={editValue}
        id={id}
        isNumber={isNumber}
        label={label}
        max={max}
        maxLength={maxLength}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        setter={setter}
        setterOnBlur={setterOnBlur}
        type={type}
      />
      {!!error.length && (
        <InfoMessage message={error} type={errorType ? errorType : "error"} />
      )}
    </div>
  );
};

export default InputGeneral;
