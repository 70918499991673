import React from "react";
import Button, { ButtonProps } from "../ButtonBase/Button.component";
import "./IconButton.styles.scss";

interface IconButtonProps extends ButtonProps {
  icon: any;
  label?: string;
  reverse?: "reverse";
}

const IconButton: React.FC<IconButtonProps> = ({
  buttonType = "primary",
  className,
  disabled,
  icon,
  label,
  reverse,
  onClick,
}) => {
  return (
    <Button
      buttonType={buttonType}
      onClick={onClick}
      disabled={disabled}
      className={`icon-button ${reverse} ${className}`}
    >
      {icon} {label}
    </Button>
  );
};

export default IconButton;
