/* eslint-disable react-hooks/exhaustive-deps */
// Components
import { useEffect } from "react";
import ButtonPrimary from "../../components/ButtonPrimary/ButtonPrimary";
import InputOtp from "../../components/inputOtp/input-otp.component";
// Constants
import constants from "../constants/login-otp.constants";
// Controllers
import LoginOtpSubmitViewController from "./controllers/login-otp-submit.controller";
// Styles
import "./login-otp-submit.view.scss";

export const LoginOtpSubmitView = ({
  handleBroker,
  handleBrokerId,
  handleToken,
  setStatus,
}) => {
  const {
    errorOtp,
    errorMessage,
    handleClickSubmit,
    setErrorOtp,
    setValue,
    translate,
    value,
  } = LoginOtpSubmitViewController(
    handleBroker,
    handleBrokerId,
    handleToken,
    setStatus
  );

  useEffect(() => {
    value?.length === 6 && handleClickSubmit();
  }, [value]);

  return (
    <>
      <div className="login-otp__form__body submit">
        <label>{translate(constants.otp.label)}</label>
        <div className="login-otp__form__body__input">
          <div className="login-otp__form__body__input__field">
            <InputOtp
              value={value}
              setValue={setValue}
              error={errorOtp}
              setError={setErrorOtp}
            />
          </div>
          <div className="login-otp__form__body__input__button">
            <ButtonPrimary
              content={translate(constants.submit_button_text)}
              disabled={errorOtp ? true : false}
              onClick={handleClickSubmit}
            />
          </div>
        </div>
        {errorOtp && (
          <div className="login-otp__form__body__input__error">
            <span>{translate(errorMessage)}</span>
          </div>
        )}
      </div>
    </>
  );
};
