import "./Projects.scss";
// Controller
import ProjectController from "./controllers/Project.controller";
import { TableLife5Projects } from "../components/TableLife5Projects/TableLife5Projects";
import EditProjects from "./EditProjects";
import ViewProject from "./ViewProject";
import OtpModal from "./OtpModal";
import EditSQLProject from "./editProjectsSQL/EditSQLProject";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";
import IdentificationModal from "./identificationModal/identificationModal";
import SkipBiometryModal from "../components/TableLife5Projects/components/SkipBiometryModal";

const Projects = () => {
  const {
    projects,
    projectsInfo,
    broker,
    rol,
    token,
    omniValue,
    setOmniValue,
    fetchProjects,
    showModal,
    setShowModal,
    modalData,
    setModalData,
    recoverProject,
    rangeProps,
    viewModal,
    setViewModal,
    otpModal,
    setOtpModal,
    otpUrl,
    setOtpUrl,
    viewData,
    setViewData,
    sendEmailDocument,
    getDownloableFiles,
    setDownloadableModal,
    downloadableModal,
    downloadableFiles,
    leadActive,
    showNewIncident,
    emailToSend,
    savedStage,
    identificationModal,
    setIdentificationModal,
    identificationData,
    setIdentificationData,
    skipBiometryModal,
    setSkipBiometryModal,
    setLeadActive,
    exportCSV,
    exportModal,
    setExportModal,
    exportDocsLoading,
    filterByOwners,
    filterOwners,
  } = ProjectController();

  const isSQL = process.env.REACT_APP_DOMAIN_LOCALE === "sql_es";

  return (
    <div className="project__container">
      <TableLife5Projects
        projects={projects}
        projectsInfo={projectsInfo}
        broker={broker}
        token={token}
        omniValue={omniValue}
        setOmniValue={setOmniValue}
        fetchProjects={fetchProjects}
        recoverProject={recoverProject}
        setShowModal={setShowModal}
        setModalData={setModalData}
        rangeProps={rangeProps}
        viewData={viewData}
        setViewData={setViewData}
        setViewModal={setViewModal}
        setOtpModal={setOtpModal}
        setOtpUrl={setOtpUrl}
        otpModal={otpModal}
        otpUrl={otpUrl}
        getDownloableFiles={getDownloableFiles}
        showNewIncident={showNewIncident}
        savedStage={savedStage}
        setIdentificationModal={setIdentificationModal}
        setIdentificationData={setIdentificationData}
        setSkipBiometryModal={setSkipBiometryModal}
        setLeadActive={setLeadActive}
        exportCSV={exportCSV}
        exportModal={exportModal}
        setExportModal={setExportModal}
        exportDocsLoading={exportDocsLoading}
        filterByOwners={filterByOwners}
        filterOwners={filterOwners}
      />
      {isSQL ? (
        <EditSQLProject
          modalData={modalData}
          setShowModal={setShowModal}
          showModal={showModal}
          fetchProjects={fetchProjects}
        />
      ) : (
        <EditProjects
          disabledButton={false}
          modalData={modalData}
          recoverProject={recoverProject}
          setShowModal={setShowModal}
          showModal={showModal}
          token={token}
          broker={broker}
          fetchProjects={fetchProjects}
        />
      )}
      <ViewProject
        viewData={viewData}
        viewModal={viewModal}
        setViewModal={setViewModal}
      />

      <OtpModal otpModal={otpModal} otpUrl={otpUrl} setOtpModal={setOtpModal} />
      <DownloadableFilesModalDS
        open={downloadableModal}
        setOpen={setDownloadableModal}
        downloadablePdfs={downloadableFiles}
        sendEmail={sendEmailDocument}
        leadId={leadActive}
        emailToSend={emailToSend}
      />
      <IdentificationModal
        open={identificationModal}
        setOpen={setIdentificationModal}
        modalData={identificationData}
        fetchProjects={fetchProjects}
      />

      <SkipBiometryModal
        open={skipBiometryModal}
        setOpen={setSkipBiometryModal}
        leadId={leadActive}
        fetchProjects={fetchProjects}
      />
    </div>
  );
};

export default Projects;
