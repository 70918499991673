export const FileIcon = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3334 5.51636V12.183C23.3334 13.1035 24.0796 13.8497 25 13.8497H31.6667"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.3334 35.5164H11.6667C9.82576 35.5164 8.33337 34.024 8.33337 32.183V8.84969C8.33337 7.00874 9.82576 5.51636 11.6667 5.51636H23.3334L31.6667 13.8497V32.183C31.6667 34.024 30.1743 35.5164 28.3334 35.5164Z"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 15.5164H16.6667"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 22.1829H25"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 28.8499H25"
      stroke="var(--primary-color)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const FileXIcon = () => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4027 5V11.6667C23.4027 12.5871 24.1489 13.3333 25.0694 13.3333H31.736"
      stroke="#E53737"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.4027 35H11.736C9.89509 35 8.40271 33.5076 8.40271 31.6667V8.33333C8.40271 6.49238 9.89509 5 11.736 5H23.4027L31.736 13.3333V31.6667C31.736 33.5076 30.2437 35 28.4027 35Z"
      stroke="#E53737"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4431 19.2929C17.0525 18.9024 16.4194 18.9024 16.0289 19.2929C15.6383 19.6834 15.6383 20.3166 16.0289 20.7071L17.4431 19.2929ZM22.6955 27.3738C23.086 27.7643 23.7192 27.7643 24.1097 27.3738C24.5003 26.9832 24.5003 26.3501 24.1097 25.9596L22.6955 27.3738ZM24.1097 20.7071C24.5003 20.3166 24.5003 19.6834 24.1097 19.2929C23.7192 18.9024 23.086 18.9024 22.6955 19.2929L24.1097 20.7071ZM16.0289 25.9596C15.6383 26.3501 15.6383 26.9832 16.0289 27.3738C16.4194 27.7643 17.0525 27.7643 17.4431 27.3738L16.0289 25.9596ZM16.0289 20.7071L22.6955 27.3738L24.1097 25.9596L17.4431 19.2929L16.0289 20.7071ZM22.6955 19.2929L16.0289 25.9596L17.4431 27.3738L24.1097 20.7071L22.6955 19.2929Z"
      fill="#E53737"
    />
  </svg>
);
