import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';
import './talkWithAnExpert.scss';

const ModalTalkWithExpert = (props) => {
    return (
        <section
            className="ModalTalkWithExpert"
            style={{ position: 'relative' }}
        >
            <CloseIcon
                onClick={props.handleOpenTalk}
                style={{ position: 'absolute', top: '8px', right: '8px' }}
            />
            <div className="ModalTalkWithExpert--container">
                <div className="ModalTalkWithExpert--content">
                    <h2 className="ModalTalkWithExpert--title">
                        Estamos aquí para ayudarte.
                    </h2>
                    <p className="ModalTalkWithExpert--text">
                        Te ofrecemos{' '}
                        <span className="ModalTalkWithExpert--span">
                            dos posibilidades:
                        </span>
                    </p>
                    <p className="ModalTalkWithExpert--text">
                        La primera es{' '}
                        <span className="ModalTalkWithExpert--span">
                            llamarnos
                        </span>{' '}
                        para hablar lo más rápido posible con uno de nuestros
                        agentes.
                    </p>
                    <p className="ModalTalkWithExpert--text">
                        La segunda te la recomendamos si aún tienes dudas:
                        <br />
                        <span className="ModalTalkWithExpert--span">
                            Agenda una cita para hablar con uno de nuestros
                            expertos
                        </span>
                        {' '}y resuelve todas las preguntas que tengas ahora en otro
                        momento.
                    </p>
                    <p className="ModalTalkWithExpert--text">
                        En ambas opciones{' '}
                        <span className="ModalTalkWithExpert--span">
                            te atenderemos lo antes posible.
                        </span>
                    </p>
                    <div className="ModalTalkWithExpert--buttons">
                        <div className="ModalTalkWithExpert--butons__div">
                            <button
                                className="ModalTalkWithExpert--buttons__btn"
                                href="/habla-experto/"
                                target="_blank"
                                onClick={() =>
                                    // eslint-disable-next-line no-undef
                                    Calendly.initPopupWidget({
                                        url: 'https://calendly.com/oscar-getlife',
                                    })
                                }
                            >
                                <ChatIcon /> <span>Habla con un experto</span>
                            </button>
                            <button className="ModalTalkWithExpert--buttons__btn ModalTalkWithExpert--buttons__btn--primary">
                                <LocalPhoneIcon />
                                <a
                                    href={`tel:${process.env.REACT_APP_OSCAR_TEL}`}
                                    className="ModalTalWithExpert--buttons__anchor"
                                >
                                    Llámanos al{' '}
                                    {process.env.REACT_APP_OSCAR_TEL}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ModalTalkWithExpert;
