import * as React from "react";
import CancelIcon from "../../atoms/Icons/CancelIcon.component";
import Typography from "../../atoms/Typography/Typography.component";
import "./Card.styles.scss";

interface CardProps {
  actions?: any;
  className?: string;
  classNameBody?: string;
  classNameContainer?: string;
  classNameTitle?: string;
  children: React.ReactNode;
  handleClose?: () => void;
  image?: JSX.Element;
  textBody?: string;
  title: string;
  variantTitle: "h1" | "paragraph" | "bold" | "h3";
}

const Card: React.FC<CardProps> = ({
  actions,
  children,
  className,
  classNameBody,
  classNameContainer,
  classNameTitle,
  handleClose,
  image,
  textBody,
  title,
  variantTitle = "h1",
}) => {
  return (
    <div className={`card-container ${classNameContainer}`}>
      <div className={`card ${className}`}>
        <div className={`card-title ${classNameTitle}`}>
          {image}
          <Typography variant={variantTitle} text={title} />
          {handleClose && (
            <div className="modal-close" onClick={() => handleClose()}>
              <CancelIcon
                style={{ fontSize: "xx-large" }}
                className="closeIcon"
              />
            </div>
          )}
        </div>
        <div className={`card-body ${classNameBody}`}>
          {typeof textBody === "string" ? (
            <Typography variant={"paragraph"} text={textBody} />
          ) : (
            <Typography variant={"paragraph"} text="">
              {textBody}
            </Typography>
          )}
          {children}
          {actions}
        </div>
      </div>
    </div>
  );
};

export default Card;
