/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import SelectGetlife from "../select/SelectGetlife";
import "./question.scss";
import CalendarExtendedGetlife from "../calendarGetlife/CalendarExtendedGetlife";
import KEYS from "../../../utils/keyboardKeys";
import {
  ButtonDS,
  InputDS,
  QuestionDateInputDS,
  SelectDS,
  Selector,
  TextInput,
} from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import VelogicaQuestion from "../VelogicaQuestion/VelogicaQuestion";
export default function Question(props) {
  const {
    answered,
    answers,
    content,
    handleNextQuestion,
    label,
    questionId,
    type,
    componentName,
    question,
    isNotEditable,
    isEditActive,
    defaultAnswer = null,
    toggleEditAnswer,
  } = props;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const isGetlifeBroker = true;
  const [answer, setAnswer] = useState(defaultAnswer);
  const [buttonText, setButtonText] = useState(translate("global.advance"));
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const isPrpQuestion = componentName === "prp_question";
  const isVelogicaQuestion = componentName === "external_underwriting";
  const hasSubtitle = componentName === "broker_show_subtitle";
  // const leadRepository = new HttpLeadRepository(token);
  const handleThisQuestion = async () => {
    let answerResponse =
      type === "checkbox_optional" && !answer
        ? "none"
        : answer?.value
        ? answer.value
        : answer;
    handleNextQuestion(question.questionId, answerResponse);
  };

  const handleAnswer = (e) => {
    const answerLength = e.length;
    const type = typeof e;
    if (answerLength !== undefined && type === "object") {
      let filterValue = [];
      filterValue = [];
      for (let i = 0; i < e.length; i++) {
        filterValue.push(e[i].value);
      }
      setAnswer(filterValue.toString());
      setIsAddDisabled(false);
    } else {
      setAnswer(e);
      setIsAddDisabled(false);
    }
  };

  const prepareText = (value) => {
    if (value !== null && value !== undefined) {
      if (value.includes("altura")) {
        return "Introduce la altura en centímetros.";
      }

      if (value.includes("peso")) {
        return "Introduce el peso en kg.";
      }
    }

    return "";
  };

  const handleKeyDown = (e) => {
    if (KEYS.KEY_ENTER === e.key && !!e.target.value.length) {
      handleThisQuestion();
    }
  };

  const handleKeyUp = (e) => {
    if (!e.target.value.length) {
      setIsAddDisabled(true);
    }
  };

  const handleSelectChange = (e) => {
    if (answer !== e?.value) {
      setAnswer(e?.value ? e?.value : e);
    }
  };

  const onSelectChange = (e) => {
    if (e.target?.value) {
      setAnswer(e.target.value);
    }
  };

  const handleSelectChangeDateInput = (e) => {
    if (answer !== e) {
      setAnswer(e);
    }
  };

  useEffect(() => {
    setAnswer(defaultAnswer);
  }, [defaultAnswer]);

  const filterComponent = (type) => {
    switch (type) {
      case "input":
        return (
          <>
            input
            <TextInput
              onValueChange={handleAnswer}
              disabled={isGetlifeBroker ? answered : answered}
              initialValue={answer || ""}
              onEnterKeyPress={handleKeyDown}
            />
          </>
        );

      case "text":
        return (
          <>
            text
            <TextInput
              onValueChange={handleAnswer}
              disabled={isGetlifeBroker ? answered : answered}
              initialValue={answer || ""}
              onEnterKeyPress={handleKeyDown}
            />
          </>
        );
      case "Text":
        return (
          <>
            Text
            <SelectGetlife
              default={props.default}
              disabled={isGetlifeBroker ? answered : answered}
              handleValues={handleSelectChange}
              placeholder={props.placeholder}
              toMap={answers}
              type={type}
            />
          </>
        );
      case "number":
        return (
          <InputDS
            onChange={(e) => handleAnswer(e.target.value)}
            value={answer || ""}
            onKeyDown={handleKeyDown}
            onEnterKeyPress={handleKeyUp}
            type="number"
          />
        );
      case "select_multiple":
      case "checkbox_mandatory":
      case "checkbox_optional":
      case "checkbox":
        return (
          <Selector
            placeholder={props.placeholder}
            setDisplay={setIsDisplayed}
            display={isDisplayed}
            iconName="ChevronDown"
            initialValue={(() => {
              if (answer) {
                return answer.split(",");
              }
              return [];
            })()}
            onValueChange={(e) => {
              setAnswer(e.toString());
              setIsAddDisabled(false);
            }}
            options={answers}
            selectedOptions={(() => {
              if (answer) {
                return answer.split(",");
              }
              return [];
            })()}
            isMultiSelect
            layout="ds"
          />
        );
      case "calendar_extended":
        return (
          <CalendarExtendedGetlife
            disabled={isGetlifeBroker ? answered : answered}
            handleValues={handleSelectChange}
            placeholder={props.placeholder}
            toMap={answers}
            type={type}
            answer={answer}
          />
        );

      case "radio":
      case "select_search":
      case "boolean_question":
      case "select":
      case "select_button":
        return (
          <SelectDS
            options={answers}
            value={answer || ""}
            placeholder={props.placeholder}
            onChange={onSelectChange}
          />
        );

      default:
        break;
    }
  };

  const renderPrpAnswer = () => {
    let _value = answer.split("|");
    if (Array.isArray(_value)) {
      _value = _value.filter((v) => v !== "undefined");
    }
    return _value.join(" | ");
  };
  const renderPrpQuestion = () => {
    return (
      <QuestionDateInputDS
        inputPlaceholder={translate("productQuestions.label.positionLabel")}
        fromLabel={translate("productQuestions.label.labelDateFrom")}
        toLabel={translate("productQuestions.label.labelDatoTo")}
        checkboxLabel={translate("productQuestions.label.checkBoxLabel")}
        notValidDateLabel={translate(
          "productQuestions.label.invalidDateMessage"
        )}
        setValue={(e) => handleSelectChangeDateInput(e)}
        timeLabel={translate("productQuestions.label.dateLabel")}
        value={answer}
        isDisabled={false}
      />
    );
  };

  const renderVelogica = () => <VelogicaQuestion question={question} />;

  useEffect(() => {
    if (type === "checkbox_optional") {
      if (answer) {
        setButtonText(translate("global.advance"));
      }

      if (!answer) {
        setButtonText(translate("global.any"));
      }
    }
  }, [type, answer]);

  const getQuestionAnswer = () => {
    if (answer === "none") {
      return translate("global.any");
    }
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(answer)) {
      return answer.split("-").reverse().join("-");
    }
    if (answers && answers.length > 0) {
      const _answer = answers.find((a) => a.value === answer);
      if (_answer && _answer.label) return _answer.label;
    }
    return answer;
  };

  if (answered)
    return (
      <div className="flex flex-col gap-[32px]">
        <div className="w-full flex flex-row gap-[24px] items-center">
          <div className="flex-1">
            <p
              className="BodyL font-bold text-[#424242]"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <p
              className="question--textContainer__info"
              style={{ color: answered === true ? "#B2AFA4" : "" }}
            >
              {prepareText(content)}
              {hasSubtitle && (
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.subtitle }}
                  />
                </div>
              )}
            </p>
          </div>
          <div className="w-[220px] text-right BodyM font-bold text-[#555]">
            {isPrpQuestion ? renderPrpAnswer() : getQuestionAnswer()}
          </div>
          {answered && !isNotEditable && (
            <ButtonDS
              buttonType="tertiary"
              onClick={() => toggleEditAnswer(questionId)}
              label={translate("productQuestions.button.edit")}
              rightIcon="PencilIcon"
              size="32"
              disabled={(isEditActive !== null) & (isEditActive !== questionId)}
            />
          )}
        </div>
      </div>
    );

  if (isVelogicaQuestion) return renderVelogica();

  return (
    <div className="flex flex-col gap-[32px]">
      <div
        className="bg-white rounded-[12px] p-[32px_24px] w-full flex flex-row gap-[24px] items-center"
        style={{
          boxShadow:
            "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <div className="flex-1">
          <p
            className="BodyL font-bold text-[#424242]"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <p
            className="question--textContainer__info"
            style={{ color: answered === true ? "#B2AFA4" : "" }}
          >
            {prepareText(content)}
            {hasSubtitle && (
              <div>
                <span
                  dangerouslySetInnerHTML={{ __html: question?.subtitle }}
                />
              </div>
            )}
          </p>
        </div>

        <div className="w-[327px] flex flex-row items-center">
          <div className="w-full flex flex-col gap-[8px]">
            {label && (
              <p style={{ color: answered ? "#c8c7bf" : "" }}>{label}</p>
            )}
            {isPrpQuestion ? renderPrpQuestion() : filterComponent(type)}
          </div>
        </div>
        {!answered && !isVelogicaQuestion && (
          <>
            <ButtonDS
              onClick={() => handleThisQuestion()}
              disabled={
                type === "checkbox_optional"
                  ? false
                  : isEditActive && isEditActive !== questionId
                  ? true
                  : isAddDisabled
              }
              label={buttonText}
              buttonType={"primary"}
              rightIcon="ArrowRightIcon"
            />
          </>
        )}
      </div>
    </div>
  );
}
