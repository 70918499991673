export default function apiSendOtpPost(
	token,
	leadId,
	channel,
	receiver
) {
	const bearer = 'Bearer ' + token;
	return fetch(
		process.env.REACT_APP_CUSTOMER_APP_URL + `api/lead/payment/sendOtp`,

		{
			method: 'POST',
			headers: {
				Authorization: bearer,
			},
			body: JSON.stringify({
				"leadId": leadId,
				"channel": channel,
				"receiver": receiver
			}),
		}
	).then((response) => {
		if (response.status === 200) {
			return response.json();
		} else {
			return 'error';
		}
	});
}
