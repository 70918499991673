import {
  InputBaseController,
  InputBaseControllerProps,
} from "./InputBase.controller";
import keys from "../../../../utils/keyboardKeys";
import "./InputBase.styles.scss";
import Label from "../Label/Label.component";

export interface InputBaseProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Omit<InputBaseControllerProps, "max" | "min"> {
  classNameLabel?: string;
  isNumber?: boolean;
  label?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
}

const InputBase: React.FC<InputBaseProps> = ({
  classNameLabel,
  className,
  disabled,
  editValue,
  id,
  isNumber,
  label,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readOnly,
  setter,
  setterOnBlur,
  type,
}): JSX.Element => {
  const { isAllowedKeys, handleKeyDown, handleBlur, handleChange, value } =
    InputBaseController({
      editValue,
      onChange,
      onBlur,
      setter,
      setterOnBlur,
      max,
      min,
    });

  return (
    <div className="inputGetLife-container">
      {!!label && (
        <Label
          label={label}
          className={`${
            disabled ? "inputGetLife-labelAnswered" : "inputGetLife-label"
          } ${classNameLabel}`}
        />
      )}

      <input
        className={`inputGetLife-input ${className}`}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (!isAllowedKeys(event.code || event.keyCode) && isNumber) {
            event.preventDefault();
          }
          if (keys.KEY_TAB === event.key || keys.KEY_ENTER === event.key) {
            handleKeyDown(event);
          }
        }}
      />
    </div>
  );
};

export default InputBase;
