import { Chip, GlobalIcon, TooltipHover } from "get-life-storybook-ts";
import { Status } from "../constants/status";
import { ListProjectsV3 } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

const statusBiometry = "ops_review, it_review , insurer_review , docs_pending";

export const renderstatus = (
  translate: (key: string) => string,
  project: ListProjectsV3,
  isBottom: boolean = false
) => {
  const PENDING = project.documentStatus === "PENDING";
  const IN_REVIEW = project.documentStatus === "IN_REVIEW";
  const ERROR = project.documentStatus === "ERRORED";

  return Status(translate).map((stage) => {
    const chipsArray = [];
    if (stage.value.includes(project.status)) {
      if (project.coverage === "eg") {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"WiderUmbrellaIcon"}
            tooltip={translate("projects.tooltip.eg")}
            className="p-[4px] border border-[#5D5AE9]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (project.hasMaxCapital) {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"CashIcon"}
            tooltip={translate("projects.tooltip.maxCapital")}
            className="p-[4px]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (project.hasTelesubscription) {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"MedicalIcon"}
            tooltip={translate("projects.tooltip.telesubscription")}
            className="p-[4px]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (statusBiometry.includes(project.status)) {
        chipsArray.push(
          <TooltipHover
            content={
              <>
                {translate(
                  `projects.tooltip.${project.documentStatus.toLowerCase()}`
                )}
                {project.isAboutToExpire && (PENDING || ERROR) ? (
                  <>
                    <br />
                    <b>
                      {/* @to-do recuperar y añadir days_to_expire reales  */}
                      {translate("projects.tooltip.daysToExpire").replace(
                        "{{days_to_expire}}",
                        project.daysToExpire.toString()
                      )}
                    </b>
                  </>
                ) : null}
              </>
            }
            direction={isBottom ? "top center" : "bottom center"}
          >
            <div className="flex flex-row gap-[4px] items-center">
              {project.isAboutToExpire && (PENDING || ERROR) ? (
                <div className="bg-[#E84B4B] p-[4px] rounded-full w-fit h-fit">
                  <GlobalIcon size="XXS" iconName="ClockIcon" color="#FFFFFF" />
                </div>
              ) : null}
              <Chip
                text={
                  PENDING
                    ? translate("projects.stage.biometryPending")
                    : IN_REVIEW
                    ? translate("projects.stage.biometryReview")
                    : ERROR
                    ? translate("projects.stage.biometryError")
                    : translate("projects.stage.biometryValidated")
                }
                type={
                  PENDING
                    ? "warning"
                    : IN_REVIEW
                    ? "info"
                    : ERROR
                    ? "destructive"
                    : "success"
                }
                icon={
                  PENDING
                    ? "FileAlertIcon"
                    : IN_REVIEW
                    ? "FileSearchIcon"
                    : ERROR
                    ? "FileXIcon"
                    : "ShieldCheckIcon"
                }
                className="py-[4px] whitespace-nowrap"
              />
            </div>
          </TooltipHover>
        );
      } else {
        chipsArray.push(
          <Chip
            text={stage.title}
            type={stage.chipType}
            icon={stage.chipIcon}
            tooltip={stage.tooltip}
            className="py-[4px] whitespace-nowrap"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      return chipsArray;
    }
  });
};
