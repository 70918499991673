import {
  ErrorI,
  isEmail,
  isOver,
  isPhone,
  isUnder,
} from "get-life-storybook-ts/lib/components/Validations";
import { useContext, useEffect, useRef, useState } from "react";
import { useDevice } from "get-life-storybook-ts";
import { ulid } from "ulid";
import { extractFromString } from "../../../../utils/extractFromString";
import { GoogleContext } from "../../../../contexts/GoogleTagManager.context";
import PricesResponse from "../../../../api/request/Lead/Model/Response/PricesResponse.model";
import { AnimatedAlertRef } from "get-life-storybook-ts/lib/components/AnimatedAlert/AnimatedAlert";
import HttpLeadRepository from "../../../../api/request/Lead/lead.service";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import prepareCapitalsSQL from "./prepareCapitalsSQL";

export interface StrapiDataCalculator {
  data: {
    Url: string;
    backgroundColor?: string;
    HasPhoneValidation?: boolean;
  }[];
  brokerId: string;
  translate: (key: string, lang?: string) => string;
  lang: string;
}
interface Validations extends ErrorI<string | number> {}

interface FormDataFirstI {
  birthDate: string;
  capital: number | string;
}

interface FormDataSecondI {
  phone: string;
  email: string;
  checkedConditions: string | boolean;
  checkedConditions2: string | boolean;
}

type ErrorCalculator =
  | "birthDate"
  | "capital"
  | "phone"
  | "email"
  | "checkedConditions"
  | "checkedConditions2";

const errorMessages = ({
  translate,
  lang,
}: {
  translate: (key: string, lang?: string) => string;
  lang: string;
}) => {
  return {
    birthDate: translate("calculator.error.birthDate", lang),
    capital: translate("calculator.error.capital", lang),
    phone: translate("calculator.error.phone", lang),
    email: translate("calculator.error.email", lang),
    checkedConditions: translate("calculator.error.checkedConditions", lang),
    checkedConditions2: translate("calculator.error.checkedConditions2", lang),
  };
};

const CalculatorSQLController = ({
  data,
  brokerId,
  translate,
  lang,
}: StrapiDataCalculator) => {
  const cookies = document.cookie;

  const VWOID = extractFromString(cookies, "_vwo_uuid_v2");
  const GAID = extractFromString(cookies, "_ga");
  const FullStoryID = extractFromString(cookies, "fs_uid");
  const AT_GD = extractFromString(cookies, "at_gd");

  const { handleTrackerQuestion } = useContext(GoogleContext);

  const [formDataFirst, setFormDataFirst] = useState<FormDataFirstI>({
    birthDate: "",
    capital: 0,
  });
  const [formDataSecond, setFormDataSecond] = useState<FormDataSecondI>({
    phone: "",
    email: "",
    checkedConditions: "",
    checkedConditions2: "",
  });
  const [formError, setFormError] = useState<any>();
  const [capitalOptions, setCapitalOptions] = useState<any>([]);
  const [actualStep, setActualStep] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<string>("");
  const [leadData, setLeadData] = useState<PricesResponse>();
  const [downloadables, setDownloadables] = useState<any>([]);
  const [downloadablesOpen, setDownloadablesOpen] = useState<boolean>(false);
  const { isMobile, isDesktop, isTablet } = useDevice();

  const { broker } = useContext(ContextGetlife);

  const leadRepository = new HttpLeadRepository();

  const refPhoneValidationModal = useRef<any>();
  const alertRef = useRef<AnimatedAlertRef>(null);

  const handleFocus = () => {
    const dataInfo = {
      event: "virtualPageview",
      page: window.location.href + " - " + actualStep,
      pageTitle: `APP Calculator - Paso ${actualStep}`,
      answeredQ: "",
    };

    handleTrackerQuestion({
      type: "FS",
      event: `Calculator Step ${actualStep} ${
        actualStep === 1 ? "Interacted" : "Viewed"
      }`,
      data: {},
    });

    handleTrackerQuestion({
      type: "GA",
      data: dataInfo,
    });
  };

  const validationDateOfBirth = ({ value, min, max }: Validations) => {
    const errorYoung = isOver({
      value: value as string,
      message: translate("calculator.error.birthDateisOver", lang).replace(
        "$",
        min!.toString()
      ),
      min,
    });
    const errorElder = isUnder({
      value: value as string,
      message: translate("calculator.error.birthDateisUnder", lang).replace(
        "$",
        max!.toString()
      ),
      max,
    });

    if (errorYoung.error) {
      return errorYoung;
    } else {
      return errorElder;
    }
  };

  const fieldValidation = ({
    data,
    key,
    newError,
  }: {
    data: any;
    key: ErrorCalculator;
    newError: any;
  }) => {
    if (!data[key]) {
      newError = {
        ...newError,
        [key]: {
          error: true,
          message: errorMessages({ translate, lang })[key],
        },
      };
    } else if (key === "phone" && isPhone({ value: data[key] }).error) {
      newError = {
        ...newError,
        [key]: {
          error: true,
          message: errorMessages({ translate, lang })[key],
        },
      };
    } else if (key === "email" && isEmail({ value: data[key] }).error) {
      newError = {
        ...newError,
        [key]: {
          error: true,
          message: errorMessages({ translate, lang })[key],
        },
      };
    } else {
      newError = { ...newError, [key]: { error: false, message: "" } };
    }

    return newError;
  };

  const validations = (data: any) => {
    let newError: any = {};
    Object.keys(data).forEach((key: any) => {
      newError = fieldValidation({ data, key, newError });
    });
    let sumValidate = 0;
    Object.keys(newError).forEach((key) => {
      if (!newError[key].error) {
        sumValidate += 1;
      }
    });
    return sumValidate === Object.keys(newError).length ? undefined : newError;
  };

  const signupOrganic = async (lead: string) => {
    let dataOrganic = {
      leadId: lead,
      birthDate: formDataFirst.birthDate,
      campaign: "broker-landing",
      capital:
        typeof formDataFirst.capital === "number"
          ? formDataFirst.capital
          : parseInt(formDataFirst.capital),
      email: formDataSecond.email,
      phone: formDataSecond.phone,
      brokerEmployeeId: brokerId,
      market: "SQL_ES",
    };

    const organic = await fetch(
      `${API_URL}api/public/lead/organic/landing/es`,
      {
        method: "POST",
        body: JSON.stringify(dataOrganic),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resultData) => resultData)
      .catch((e) => e);
    return organic;
  };

  const downloadableFiles = async () => {
    const documents = await leadRepository.getAvailableDocuments(leadId);
    setDownloadables(documents);
    setDownloadablesOpen(true);
  };

  const getLeadData = async (lead: string) => {
    const leadData = await fetch(`${API_URL}api/lead/${lead}/price`)
      .then((response) => {
        const data = response.json();
        return data;
      })
      .then((resultData) => resultData);
    setLeadData(leadData);
    return leadData;
  };

  const fetchOrganic = async () => {
    setLoading(true);
    try {
      const secondStepErrors = validations(formDataSecond);
      if (secondStepErrors) {
        throw secondStepErrors;
      }

      const lead = ulid();

      const organic = await signupOrganic(lead);
      if (organic.msg) {
        alertRef.current?.openAlert();
        setLoading(false);
        return;
      }

      await getLeadData(lead);

      setLeadId(lead);

      const tracking = await leadRepository.getTracking(lead);
      const index = tracking ? tracking.length - 1 : 0;

      const dataTracker = {
        leadUlid: lead,
        campaign: tracking ? tracking[index].newCampaign : "broker-landing",
        googleAnalyticsId: tracking ? tracking[index].googleAnalyticsId : GAID,
        fullstoryId: tracking ? tracking[index].fullstoryId : FullStoryID,
        vwoId: tracking ? tracking[index].vwoId : VWOID,
        utmCampaign: tracking ? tracking[index].utmCampaign : brokerId,
        utmSource: tracking ? tracking[index].utmSource : "brokerapp",
        utmMedium: tracking
          ? tracking[index].utmMedium
          : broker?.brokerageId?.toString(),
        utmContent: tracking ? tracking[index].utmContent : null,
        utmTerm: tracking ? tracking[index].utmTerm : null,
        utmTest: tracking ? tracking[index].utmTest : null,
        gclid: tracking ? tracking[index].gclid : null,
        fbclid: tracking ? tracking[index].fbclid : null,
        tid: tracking ? tracking[index].tid : null,
        atGd: tracking ? tracking[index].atGd : AT_GD,
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      await leadRepository.saveTrackingPublic({ data: dataTracker });

      const eventData = {
        calculatedCapital: formDataFirst.capital,
        email: formDataSecond.email,
        leadId: leadId,
        page: window.location.href,
        phone: formDataSecond.phone,
        brokerEmployeeId: brokerId,
        brokerId: broker?.brokerageId,
        utm_source: tracking ? tracking[index].utmSource : "brokerapp",
        utm_campaign: tracking ? tracking[index].utmCampaign : brokerId,
        utm_medium: tracking
          ? tracking[index].utmMedium
          : broker?.brokerageId.toString(),
        platform: "Broker",
        entryPage: tracking ? tracking[index].utmEntryPage : null,
        referrerUrl: tracking ? tracking[index].utmReferrerUrl : null,
        ABTestVersion: tracking ? tracking[index].aBTestVersion : null,
      };

      handleTrackerQuestion({
        type: "FS",
        event: "leadCreated",
        data: eventData,
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event: "leadCreated",
          ...eventData,
        },
      });

      handleTrackerQuestion({
        type: "FS",
        event: "calculatorQuoteGenerated",
        data: eventData,
      });

      handleTrackerQuestion({
        type: "GA",
        data: {
          event: "calculatorQuoteGenerated",
          ...eventData,
        },
      });

      setActualStep(3);

      setLoading(false);
    } catch (error) {
      setFormError(error);
      setLoading(false);
    }
  };

  const handleButton = async (scrollToTop = false) => {
    if (scrollToTop) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    if (actualStep === 1) return await ValidateStepOneAndGoToNextStep();
    if (actualStep === 2) return await ValidateStepTwoAndGoToNextStep();
  };

  const ValidateStepOneAndGoToNextStep = async () => {
    const firstStepErrors = validations(formDataFirst);
    if (firstStepErrors) {
      setFormError(firstStepErrors);
    } else {
      setActualStep(2);
    }
  };

  const ValidateStepTwoAndGoToNextStep = async () => {
    const secondErrors = validations(formDataSecond);
    if (secondErrors) {
      setFormError(secondErrors);
    } else {
      await fetchOrganic();
    }
  };

  const handleChange = ({ setValue, value, field, formData }: any) => {
    let newData = { ...formData, [field]: value };
    let newError: any = {};
    if (value && formError && formError[field] && formError[field].error) {
      newError = fieldValidation({ data: newData, key: field, newError });
      setFormError({ ...formError, ...newError });
    }
    setValue(newData);
  };

  const handleBlur = ({ field, formData }: any) => {
    let newError: any = {};
    if (!!formData[field]) {
      newError = fieldValidation({ data: formData, key: field, newError });
      setFormError({ ...formError, ...newError });
    }
  };

  useEffect(() => {
    (async () => {
      if (formDataFirst.birthDate) {
        if (typeof window !== undefined) {
          try {
            const errorBirth = validationDateOfBirth({
              value: formDataFirst.birthDate,
              min: 18,
              max: 75,
            });
            if (errorBirth.error) {
              throw errorBirth;
            }

            const capitals = await fetch(
              `${process.env.REACT_APP_CUSTOMER_APP_URL}api/public/boundaries/base-trait/${formDataFirst.birthDate}`
            )
              .then((response) => response.json())
              .then((resultData) => {
                return resultData;
              });

            const { options, indexPreSelected } = prepareCapitalsSQL({
              capitals,
            });

            setCapitalOptions(options);

            setFormDataFirst({
              ...formDataFirst,
              capital: options[indexPreSelected].value,
            });
            setFormError({
              ...formError,
              birthDate: { error: false, message: "" },
            });
          } catch (error: any) {
            if (error.error) {
              setFormError({ ...formError, birthDate: error });
            } else {
              setFormError({
                ...formError,
                birthDate: {
                  error: true,
                  message: errorMessages({ translate, lang })["birthDate"],
                },
              });
            }
            setCapitalOptions([]);
          }
        }
      }
    })();
  }, [formDataFirst.birthDate]);

  const API_URL = process.env.REACT_APP_CUSTOMER_APP_URL;

  useEffect(() => {
    const snackbar = document.getElementById("snackbar_module");
    if (snackbar) snackbar.style.display = isMobile ? "none" : "inherit";
  }, [isMobile]);

  return {
    actualStep,
    capitalOptions,
    downloadableFiles,
    display,
    formDataFirst,
    formDataSecond,
    formError,
    handleBlur,
    handleButton,
    handleChange,
    handleFocus,
    isDesktop,
    isMobile,
    isTablet,
    loading,
    setActualStep,
    setDisplay,
    setFormDataFirst,
    setFormDataSecond,
    validations,
    refPhoneValidationModal,
    leadData,
    alertRef,
    downloadables,
    downloadablesOpen,
    setDownloadablesOpen,
  };
};

export default CalculatorSQLController;
