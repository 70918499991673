import React from "react";

import "./CrossedOut.styles.scss";

interface CrossedOutI {
  className?: string;
  data?: string | number;
}

const CrossedOut: React.FC<CrossedOutI> = ({ className, data }) => {
  return <del className={`crossedOut ${className}`}>{data}</del>;
};

export default CrossedOut;
