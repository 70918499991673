import { useContext } from "react";
import { ContextGetlife } from "../../contexts/ContextGetlife";

const ComplementaryProduct = () => {

  const {
    broker,
  } = useContext(ContextGetlife);

  return (
    <div className="w-full h-full px-20 py-12">
      <div className="bg-white">
        <div>
          <iframe 
          className="w-full px-10 h-[880px]"
          src={broker?.complementaryUrl}></iframe>
        </div>

      </div>

    </div>
  );
};

export default ComplementaryProduct;
