// Vendors
import { useContext, useEffect, useState } from "react";
// Utils
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpListRepository from "../../../api/request/Lists/list.service";
import { Employee } from "../../../api/request/Lists/Model/Request/ListRequest.model";
import HttpEmployeeRepository from "../../../api/request/Employee/employee.service";
import { I18nContext } from "../../../contexts/i18n.context";
import EmployeeRequest from "../../../api/request/Employee/Model/Resquest/EmployeeRequest.model";
import { AlertColor } from "@mui/material";
import { formErrorsI } from "../../Rebranding/organisms/Modal/WelcomeEmployee/WelcomeEmployee.controller";
import REGEX from "../../../utils/regExp";

interface FormEditEmployee {
  name: string | null;
  lastname: string | null;
  email: string;
  phone: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
  rol: string | null;
}

const EmployeesController = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<string>("");
  // Table
  const [totalItems, setTotalItems] = useState(0);
  const [indexPage, setIndexPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  // OmniSearch
  const [omniValue, setOmniValue] = useState("");
  // Filter
  const [totalPages, setTotalPages] = useState(1);
  const [formError, setFormError] = useState<formErrorsI>({});

  const [values, setValues] = useState<FormEditEmployee>({
    name: "",
    lastname: "",
    email: "",
    phone: "",
    contactEmail: "",
    contactPhone: "",
    rol: "",
  });

  const [brokerUlidActive, setBrokerUlidActive] = useState<string | null>(null);

  const { token, setShowNotification, DOMAIN } = useContext(ContextGetlife);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const employeeRepository = new HttpEmployeeRepository(token);
  const listRepository = new HttpListRepository(token);

  //--------------------------------------------------------------------------------------//
  //                                  ACTIONS / HANDLERS                                  //
  //--------------------------------------------------------------------------------------//

  const fetchEmployees = async ({
    newPage,
    newPageSize,
    signal,
  }: {
    newPage?: number;
    newPageSize?: number;
    signal?: AbortSignal;
  } = {}): Promise<void> => {
    try {
      const employeesList = await listRepository.getEmployeesList({
        indexPage: newPage ?? indexPage,
        omniValue: omniValue,
        pageSize: newPageSize ?? pageSize,
        signal,
      });

      setEmployees(employeesList.employees);
      setTotalItems(employeesList.totalItems);
      setIndexPage(employeesList.index);
      setPageSize(employeesList.size);
      setTotalPages(employeesList.totalPages);
    } catch (error) {
      setEmployees([]);
      setTotalItems(0);
      setIndexPage(1);
      setTotalPages(0);
      console.log(error);
    }
  };

  // useEffect(() => {
  //   validateForm();
  // }, [values])

  const validateForm = () => {
    const _error: any = {};
    const { phone, contactPhone, email, contactEmail } = values;

    if (contactEmail) {
      const isValidEmail = contactEmail.match(REGEX["EMAIL"]);
      if (!isValidEmail) {
        _error["contactEmail"] = translate(
          "dashboard.multiproduct.validate.wrongEmail"
        );
      }
    }

    if (email) {
      const isValidEmail = email.match(REGEX["EMAIL"]);
      if (!isValidEmail) {
        _error["email"] = translate(
          "dashboard.multiproduct.validate.wrongEmail"
        );
      }
    }

    if (phone) {
      let isValidPhone = true;
      if (DOMAIN === "fr") {
        isValidPhone = phone.toString().match(REGEX["PHONEFR"]) !== null;
      } else if (DOMAIN === "it") {
        isValidPhone = phone.toString().match(REGEX["PHONEIT"]) !== null;
      } else {
        isValidPhone = phone.toString().match(REGEX["PHONE"]) !== null;
      }
      if (!isValidPhone) {
        _error["phone"] = translate(
          "dashboard.multiproduct.validate.wrongPhone"
        );
      }
    }

    if (contactPhone) {
      let isValidPhone = true;
      if (DOMAIN === "fr") {
        isValidPhone = contactPhone.toString().match(REGEX["PHONEFR"]) !== null;
      } else if (DOMAIN === "it") {
        isValidPhone = contactPhone.toString().match(REGEX["PHONEIT"]) !== null;
      } else {
        isValidPhone = contactPhone.toString().match(REGEX["PHONE"]) !== null;
      }
      if (!isValidPhone) {
        _error["contactPhone"] = translate(
          "dashboard.multiproduct.validate.wrongPhone"
        );
      }
    }

    setFormError(_error);
    return _error;
  };

  const fetchInfoEmployee = async (brokerUlid: string) => {
    try {
      const infoEmployee = await employeeRepository.getInfoEmoployee(
        brokerUlid
      );
      setValues(infoEmployee);
    } catch (error) {
      console.error(error);
    }
  };

  const getMessage = (message: string, type: AlertColor) => {
    setShowNotification({
      message: message,
      status: true,
      type: type,
      anchorOrigin: { vertical: "top", horizontal: "center" },
      autoHideDuration: type === "success" ? 3000 : 10000,
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchEmployeesSearch = async () => {
      fetchEmployees({ newPage: 1, signal: controller.signal });
    };

    fetchEmployeesSearch();

    return () => {
      controller.abort({ reason: 409 });
    };
  }, [omniValue]);

  const handlePagination = (page: string) => {
    let newPage = 1;
    if (page === "next") {
      newPage = indexPage === totalPages ? totalPages : indexPage + 1;
      setIndexPage(newPage);
    }
    if (page === "prev") {
      newPage = indexPage !== 1 ? indexPage - 1 : 1;
      setIndexPage(newPage);
    }

    if (page === "first") {
      newPage = 1;
      setIndexPage(newPage);
    }

    if (page === "last") {
      newPage = totalPages;
      setIndexPage(newPage);
    }
    fetchEmployees({ newPage: newPage });
  };

  const handlePageSize = (value: number) => {
    setPageSize(value);
    fetchEmployees({ newPage: 1, newPageSize: value });
  };

  useEffect(() => {
    fetchEmployees({});
  }, []);

  const showEditEmployee = async (brokerUlid: string) => {
    await fetchInfoEmployee(brokerUlid);
    setBrokerUlidActive(brokerUlid);
    setShowEditModal(true);
  };

  const saveUpdateEmployee = async (brokerUlid: string) => {
    const errors = validateForm();
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) return;

    try {
      let data: EmployeeRequest = {
        name: values?.name || "",
        lastname: values?.lastname || "",
        email: values?.email || "",
        phone: values?.phone || "",
        roles: values?.rol,
        contactEmail: values?.contactEmail || "",
        contactPhone: values?.contactPhone || "",
        ulid: brokerUlid,
      };
      await employeeRepository.editEmployee(data);
      fetchEmployees({});
      getMessage(translate(`employees.edit.success`), "success");
      setShowEditModal(false);
      setBrokerUlidActive(null);
    } catch (e: any) {
      const error = JSON.stringify(e);
      const _errors = { ...formError };

      if (error.includes("EMAIL")) {
        _errors.email = translate("errors.employees.employeeBrokerEmailAlreadyExist");
      } else {
        _errors.email = undefined;
      }

      if (error.includes("PHONE")) {
        _errors.phone = translate("errors.employees.employeeBrokerPhoneAlreadyExist");
      } else {
        _errors.phone = undefined;
      }

      setFormError(_errors);
    }
  };

  return {
    employees,
    omniValue,
    setOmniValue,
    fetchEmployees,
    totalItems,
    totalPages,
    indexPage,
    pageSize,
    handlePageSize,
    handlePagination,
    brokerUlidActive,
    showEditEmployee,
    showEditModal,
    setShowEditModal,
    saveUpdateEmployee,
    values,
    setValues,
    showDeleteModal,
    setShowDeleteModal,
    formError,
  };
};

export default EmployeesController;
