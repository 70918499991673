import React from "react";
import Button from "../../../atoms/Buttons/ButtonBase/Button.component";
import Typography from "../../../atoms/Typography/Typography.component";
import Modal from "../Modal.component";

const CouponConditions = ({
  translate,
  showModal,
  setShowModal,
  couponInfo,
}: any) => {
  return (
    <Modal
      setShowModal={setShowModal}
      showModal={showModal}
      closeIcon
      ModalTitle={
        <Typography variant="h1" text={translate("coupon.title.conditions")} />
      }
      ModalBody={
        <>
          <div>
            <Typography variant="h3" text={translate("coupon.conditions")} />
            <Typography variant="paragraph" text={couponInfo?.conditions} />
          </div>
          <div>
            <Typography variant="h3" text={translate("coupon.exceptions")} />
            <Typography variant="paragraph" text={couponInfo?.exceptions} />
          </div>
        </>
      }
      ModalActions={
        <div className="beneficiaries--modal-buttons">
          <Button onClick={() => setShowModal(false)}>
            {translate(`coupon.buttons.close`)}
          </Button>
        </div>
      }
    />
  );
};

export default CouponConditions;
