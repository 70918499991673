import {
  ButtonDS,
  CheckboxButton,
  GlobalIcon,
  TooltipHover,
} from "get-life-storybook-ts";
import { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../../../contexts/i18n.context";
import { ContextGetlife } from "../../../../contexts/ContextGetlife";
import HttpBrokerRepository from "../../../../api/request/Broker/broker.service";

interface OwnerFilterPopupI {
  label: string;
  filterByOwners: (owners: string[]) => void;
  filterOwners: string[];
}

const OwnerFilterPopup = ({
  label,
  filterByOwners,
  filterOwners,
}: OwnerFilterPopupI) => {
  const [owners, setOwners] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { brokerId, token } = useContext(ContextGetlife);

  const brokerRepository = new HttpBrokerRepository(brokerId, token);

  const popupRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const togglePopup = () => setOpen(!open);

  const toggleOwners = (owner: string) => {
    const _owners = [...selectedOwners];
    if (_owners.includes(owner)) {
      setSelectedOwners(_owners.filter((item) => item !== owner));
    } else {
      _owners.push(owner);
      setSelectedOwners(_owners);
    }
  };

  const removeOwners = () => {
    setSelectedOwners([]);
    filterByOwners([]);
  };

  useEffect(() => {
    const getOwners = async () => {
      const response = await brokerRepository.getOwners(brokerId);
      setOwners(response);
    };
    getOwners();
  }, []);

  useEffect(() => {
    const onClickOutsideRangeClosePopup = (e: MouseEvent) => {
      if (open === true) {
        if (!popupRef.current?.contains(e.target as Node)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", onClickOutsideRangeClosePopup);
    return () =>
      document.removeEventListener("mousedown", onClickOutsideRangeClosePopup);
  }, [open]);

  return (
    <div className="relative cursor-pointer" ref={popupRef}>
      <button
        className="tableHeader flex items-center BodyS font-bold uppercase whitespace-nowrap w-full"
        onClick={togglePopup}
      >
        {label}
        {open ? (
          <GlobalIcon
            iconName="ChevronDown"
            size="XS"
            color="#555555"
            className="ml-auto bg-[#ECECEC] rounded-[4px]"
          />
        ) : filterOwners.length > 0 ? (
          <button
            className="normal-case ml-auto"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeOwners();
            }}
          >
            <TooltipHover
              content={translate("projects.filter.popup.removeFilter")}
              direction="bottom center"
            >
              <GlobalIcon
                iconName="FilterIcon"
                size="XS"
                color="#555555"
                className="bg-[rgb(236,236,236)] rounded-[4px] inline-block"
              />
            </TooltipHover>
          </button>
        ) : (
          <GlobalIcon
            iconName="ChevronDown"
            size="XS"
            color="#FFFFFF"
            className="ml-auto"
          />
        )}
      </button>
      <div
        className="absolute bottom-[8px] right-[0px] bg-[#FFF] border border-[#D9D9D9] rounded-[12px] text-[#555555] py-[4px] normal-case z-10 w-[268px] max-w-[268px]"
        style={{
          transform: "translateY(100%)",
          boxShadow:
            "0px 11px 15px 0px rgba(0, 0, 0, 0.10), 0px 9px 46px 0px rgba(0, 0, 0, 0.06), 0px 24px 38px 0px rgba(0, 0, 0, 0.07)",
          display: open ? undefined : "none",
        }}
      >
        <div className="max-h-[240px] overflow-auto">
          {owners.map((owner) => {
            const { fullName, ulid } = owner;
            return (
              <button
                className="p-[12px_12px] flex flex-row items-center gap-[8px] w-full text-[#555555]"
                key={`${ulid}`}
                onClick={() => toggleOwners(ulid)}
              >
                <span className="BodyM font-medium flex-1 text-left">
                  {fullName}
                </span>
                <CheckboxButton
                  variant="SimpleCheck"
                  checked={selectedOwners.includes(ulid)}
                  value={""}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  // @ts-ignore comment
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  label={""}
                />
              </button>
            );
          })}
        </div>
        <div className="p-3 flex flex-row items-center gap-[16px] border-t border-[#D9D9D9]">
          <ButtonDS
            label={translate("projects.filter.popup.cleanFilter")}
            buttonType="tertiary"
            className="flex-1"
            size="32"
            disabled={selectedOwners.length === 0}
            onClick={removeOwners}
          />
          <ButtonDS
            label={
              selectedOwners.length === 0
                ? translate("projects.filter.popup.showAll")
                : translate("projects.filter.popup.filter")
            }
            className="flex-1"
            size="32"
            onClick={() => {
              if (selectedOwners.length === 0) {
                filterByOwners([]);
              } else {
                filterByOwners(selectedOwners);
              }
              setOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnerFilterPopup;
